.form-floating {
  position: relative;

  > label {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: $input-padding-x - $form-floating-label-padding-x;
    transform: translate3d(0, $input-padding-y + px-to-rem($input-border-width), 0);
    display: block;
    margin-bottom: 0;
    border-radius: $input-border-radius;
    max-width: calc(100% - #{($input-padding-x - $form-floating-label-padding-x) * 2});
    background-color: $input-bg;
    padding: 0 $form-floating-label-padding-x;
    line-height: $input-line-height;
    font-size: $input-font-size;
    color: $input-placeholder-color;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    cursor: text;
    transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out, color 0.15s ease-in-out;
  }

  > .form-control:not(.show-placeholder):not(.select2-hidden-accessible)::placeholder,
  > .select2-hidden-accessible:not(.show-placeholder)
    ~ .select2-container:not(.select2-container--selected)
    .select2-selection--single
    .select2-selection__rendered {
    opacity: 0;
    color: transparent;
  }

  > .form-control:focus,
  > .form-control:not(:disabled):not([readonly]):not(:placeholder-shown):not(.select2-hidden-accessible),
  > .select2-container--focus,
  > .select2-container--open,
  > .select2-container--selected {
    .select2-selection {
      border-color: $input-active-border-color;
    }

    ~ label {
      color: $input-active-color;
    }
  }

  > .show-placeholder,
  > .form-control:focus,
  > .form-control:not(:placeholder-shown):not(.select2-hidden-accessible),
  > .select2-container--focus,
  > .select2-container--open,
  > .select2-container--selected {
    ~ label {
      transform: translate3d(0, -0.5em * $input-line-height, 0);
      font-size: $label-font-size;
    }
  }

  > .select2-container ~ label {
    max-width: calc(
      100% - #{($input-padding-x - $form-floating-label-padding-x) * 2 + $input-icon-font-size + $input-icon-font-size}
    );
  }

  > label:has(~ .form-control),
  > label:has(~ .input-group),
  > label:has(~ .select2-container),
  > label:has(~ .select2-selection) {
    z-index: 4;
  }

  > label:has(~ .form-control:focus),
  > label:has(~ .form-control:not(:disabled):not([readonly]):not(:placeholder-shown):not(.select2-hidden-accessible)),
  > label:has(~ .select2-container--focus),
  > label:has(~ .select2-container--open),
  > label:has(~ .select2-container--selected),
  > label:has(~ .select2-selection) {
    color: $input-active-color;
  }

  > label:has(~ .show-placeholder),
  > label:has(~ .form-control:focus),
  > label:has(~ .form-control:not(:placeholder-shown):not(.select2-hidden-accessible)),
  > label:has(~ .select2-container--focus),
  > label:has(~ .select2-container--open),
  > label:has(~ .select2-container--selected) {
    transform: translate3d(0, -0.5em * $input-line-height, 0);
    font-size: $label-font-size;
  }

  > label:has(~ .select2-container) {
    max-width: calc(
      100% - #{($input-padding-x - $form-floating-label-padding-x) * 2 + $input-icon-font-size + $input-icon-font-size}
    );
  }
}

// Fallback for Edge
@supports (-ms-ime-align: auto) {
  .form-floating {
    > label {
      display: none;
    }

    > input::-ms-input-placeholder {
      color: $input-placeholder-color;
    }
  }
}

// Fallback for IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-floating {
    > label {
      display: none;
    }

    > input:-ms-input-placeholder {
      opacity: 1;
      color: $input-placeholder-color;
    }
  }
}

.floating-label {
  font-size: $label-font-size;
}
