@charset "UTF-8";
@import '~swiper/swiper-bundle.min.css';
:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "IQOS", "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "IQOS", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #34303d;
  text-align: left;
  background-color: #fffdfb;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: inherit;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #99979e;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.125rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 1.75rem;
}

h2, .h2 {
  font-size: 1.625rem;
}

h3, .h3 {
  font-size: 1.375rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 400;
}

.display-1 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.display-2 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.2;
}

.display-3 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

.display-4 {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875rem;
  color: #7b7881;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fffdfb;
  border: 1px solid #e1e0e2;
  border-radius: 3px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #7b7881;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #27242D;
  border-radius: 3px;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #27242D;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 1374px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #34303d;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #b8b7bb;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #b8b7bb;
}
.table tbody + tbody {
  border-top: 2px solid #b8b7bb;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #b8b7bb;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #b8b7bb;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #34303d;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #34303d;
  border-color: #464152;
}
.table .thead-light th {
  color: #524f5a;
  background-color: #f6f4f0;
  border-color: #b8b7bb;
}

.table-dark {
  color: #fff;
  background-color: #34303d;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #464152;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.6875rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #34303d;
  background-color: #fffdfb;
  background-clip: padding-box;
  border: 1px solid #99979e;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #34303d;
  background-color: #fffdfb;
  border-color: #34303d;
  outline: 0;
  box-shadow: inset 0 0 0 1px #34303d;
}
.form-control::placeholder {
  color: #7b7881;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #fffdfb;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #34303d;
}
select.form-control:focus::-ms-value {
  color: #34303d;
  background-color: #fffdfb;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.6875rem + 1px);
  padding-bottom: calc(0.6875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6875rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #34303d;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: 2.125rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
}

.form-control-lg {
  height: 2.625rem;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 6px;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #99979e;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .ratings-and-reviews-btn, .bv_modal_outer_content .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn, .gm-style .gm-style-iw > button, #buorgig, #buorgul,
#buorgpermanent {
  display: inline-block;
  font-family: "IQOS", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  color: #34303d;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 1.375rem;
  padding: 0.625rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.2s, opacity 0.3s;
}
.btn.btn-circular, .btn-circular.ratings-and-reviews-btn, .bv_modal_outer_content .btn-circular.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.btn-circular.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-circular.bv-content-btn, .gm-style .gm-style-iw > button.btn-circular, .btn-circular#buorgig, .btn-circular#buorgul,
.btn-circular#buorgpermanent {
  width: 2.75rem;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .ratings-and-reviews-btn, .bv_modal_outer_content .bv_button_buttonFull,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn, .gm-style .gm-style-iw > button, #buorgig, #buorgul,
  #buorgpermanent {
    transition: none;
  }
}
.btn:hover, .ratings-and-reviews-btn:hover, .bv_modal_outer_content .bv_button_buttonFull:hover,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:hover, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:hover, .gm-style .gm-style-iw > button:hover, #buorgig:hover, #buorgul:hover,
#buorgpermanent:hover {
  color: #34303d;
  text-decoration: none;
}
.btn:focus, .ratings-and-reviews-btn:focus, .bv_modal_outer_content .bv_button_buttonFull:focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:focus, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:focus, .gm-style .gm-style-iw > button:focus, #buorgig:focus, #buorgul:focus,
#buorgpermanent:focus, .btn.focus, .focus.ratings-and-reviews-btn, .bv_modal_outer_content .focus.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn, .gm-style .gm-style-iw > button.focus, .focus#buorgig, .focus#buorgul,
.focus#buorgpermanent {
  outline: 0;
  box-shadow: 0;
}
.btn.disabled, .disabled.ratings-and-reviews-btn, .bv_modal_outer_content .disabled.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.disabled.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .disabled.bv-content-btn, .gm-style .gm-style-iw > button.disabled, .disabled#buorgig, .disabled#buorgul,
.disabled#buorgpermanent, .btn:disabled, .ratings-and-reviews-btn:disabled, .bv_modal_outer_content .bv_button_buttonFull:disabled,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:disabled, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:disabled, .gm-style .gm-style-iw > button:disabled, #buorgig:disabled, #buorgul:disabled,
#buorgpermanent:disabled {
  opacity: 1;
}
.btn:not(:disabled):not(.disabled), .ratings-and-reviews-btn:not(:disabled):not(.disabled), .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled),
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled), .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled), .gm-style .gm-style-iw > button:not(:disabled):not(.disabled), #buorgig:not(:disabled):not(.disabled), #buorgul:not(:disabled):not(.disabled),
#buorgpermanent:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled, a.disabled.ratings-and-reviews-btn, .bv_modal_outer_content a.disabled.bv_button_buttonFull, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item a.disabled.bv-content-btn, a.disabled#buorgig, a.disabled#buorgul,
a.disabled#buorgpermanent,
fieldset:disabled a.btn,
fieldset:disabled a.ratings-and-reviews-btn,
fieldset:disabled .bv_modal_outer_content a.bv_button_buttonFull,
.bv_modal_outer_content fieldset:disabled a.bv_button_buttonFull,
fieldset:disabled .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item a.bv-content-btn,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item fieldset:disabled a.bv-content-btn,
fieldset:disabled a#buorgig,
fieldset:disabled a#buorgul,
fieldset:disabled a#buorgpermanent {
  pointer-events: none;
}

.btn-link {
  font-weight: 400;
  color: inherit;
  text-decoration: none;
}
.btn-link:hover {
  color: inherit;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #99979e;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .ratings-and-reviews-btn, .bv_modal_outer_content .btn-group-lg > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-lg > button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-lg > .bv-content-btn, .gm-style .gm-style-iw.btn-group-lg > button, .btn-group-lg > #buorgig, .btn-group-lg > #buorgul,
.btn-group-lg > #buorgpermanent {
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.btn-lg.btn-circular, .btn-group-lg > .btn-circular.btn, .btn-group-lg > .btn-circular.ratings-and-reviews-btn, .bv_modal_outer_content .btn-group-lg > .btn-circular.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-lg > button.btn-circular.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-lg > .btn-circular.bv-content-btn, .gm-style .gm-style-iw.btn-group-lg > button.btn-circular, .btn-group-lg > .btn-circular#buorgig, .btn-group-lg > .btn-circular#buorgul,
.btn-group-lg > .btn-circular#buorgpermanent {
  width: 2.875rem;
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .ratings-and-reviews-btn, .bv_modal_outer_content .btn-group-sm > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-sm > button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-sm > .bv-content-btn, .gm-style .gm-style-iw.btn-group-sm > button, .btn-group-sm > #buorgig, .btn-group-sm > #buorgul,
.btn-group-sm > #buorgpermanent {
  border-radius: 3px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.btn-sm.btn-circular, .btn-group-sm > .btn-circular.btn, .btn-group-sm > .btn-circular.ratings-and-reviews-btn, .bv_modal_outer_content .btn-group-sm > .btn-circular.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-sm > button.btn-circular.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-sm > .btn-circular.bv-content-btn, .gm-style .gm-style-iw.btn-group-sm > button.btn-circular, .btn-group-sm > .btn-circular#buorgig, .btn-group-sm > .btn-circular#buorgul,
.btn-group-sm > .btn-circular#buorgpermanent {
  width: 1.8125rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100%;
  padding: 0.5rem 0;
  margin: 0.5rem 0 0;
  font-size: 1rem;
  color: #34303d;
  text-align: left;
  list-style: none;
  background-color: #fffdfb;
  background-clip: padding-box;
  border: 0 solid #fffdfb;
  border-radius: 3px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.5rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.5rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f6f4f0;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #34303d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #34303d;
  text-decoration: none;
  background-color: #fffdfb;
}
.dropdown-item.active, .dropdown-item:active {
  color: #34303d;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #7b7881;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #7b7881;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #34303d;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .btn-group > .ratings-and-reviews-btn, .bv_modal_outer_content .btn-group > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .bv-content-btn, .gm-style .gm-style-iw.btn-group > button, .btn-group > #buorgig, .btn-group > #buorgul,
.btn-group > #buorgpermanent,
.btn-group-vertical > .btn,
.btn-group-vertical > .ratings-and-reviews-btn,
.bv_modal_outer_content .btn-group-vertical > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > button.bv-submission-button-submit,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .bv-content-btn,
.gm-style .gm-style-iw.btn-group-vertical > button,
.btn-group-vertical > #buorgig,
.btn-group-vertical > #buorgul,
.btn-group-vertical > #buorgpermanent {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover, .btn-group > .ratings-and-reviews-btn:hover, .bv_modal_outer_content .btn-group > .bv_button_buttonFull:hover,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > button.bv-submission-button-submit:hover, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .bv-content-btn:hover, .gm-style .gm-style-iw.btn-group > button:hover, .btn-group > #buorgig:hover, .btn-group > #buorgul:hover,
.btn-group > #buorgpermanent:hover,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .ratings-and-reviews-btn:hover,
.bv_modal_outer_content .btn-group-vertical > .bv_button_buttonFull:hover,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > button.bv-submission-button-submit:hover,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .bv-content-btn:hover,
.gm-style .gm-style-iw.btn-group-vertical > button:hover,
.btn-group-vertical > #buorgig:hover,
.btn-group-vertical > #buorgul:hover,
.btn-group-vertical > #buorgpermanent:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .ratings-and-reviews-btn:focus, .bv_modal_outer_content .btn-group > .bv_button_buttonFull:focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > button.bv-submission-button-submit:focus, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .bv-content-btn:focus, .gm-style .gm-style-iw.btn-group > button:focus, .btn-group > #buorgig:focus, .btn-group > #buorgul:focus,
.btn-group > #buorgpermanent:focus, .btn-group > .btn:active, .btn-group > .ratings-and-reviews-btn:active, .bv_modal_outer_content .btn-group > .bv_button_buttonFull:active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > button.bv-submission-button-submit:active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .bv-content-btn:active, .gm-style .gm-style-iw.btn-group > button:active, .btn-group > #buorgig:active, .btn-group > #buorgul:active,
.btn-group > #buorgpermanent:active, .btn-group > .btn.active, .btn-group > .active.ratings-and-reviews-btn, .bv_modal_outer_content .btn-group > .active.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > button.active.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .active.bv-content-btn, .gm-style .gm-style-iw.btn-group > button.active, .btn-group > .active#buorgig, .btn-group > .active#buorgul,
.btn-group > .active#buorgpermanent,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .ratings-and-reviews-btn:focus,
.bv_modal_outer_content .btn-group-vertical > .bv_button_buttonFull:focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > button.bv-submission-button-submit:focus,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .bv-content-btn:focus,
.gm-style .gm-style-iw.btn-group-vertical > button:focus,
.btn-group-vertical > #buorgig:focus,
.btn-group-vertical > #buorgul:focus,
.btn-group-vertical > #buorgpermanent:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .ratings-and-reviews-btn:active,
.bv_modal_outer_content .btn-group-vertical > .bv_button_buttonFull:active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > button.bv-submission-button-submit:active,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .bv-content-btn:active,
.gm-style .gm-style-iw.btn-group-vertical > button:active,
.btn-group-vertical > #buorgig:active,
.btn-group-vertical > #buorgul:active,
.btn-group-vertical > #buorgpermanent:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.ratings-and-reviews-btn,
.bv_modal_outer_content .btn-group-vertical > .active.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > button.active.bv-submission-button-submit,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .active.bv-content-btn,
.gm-style .gm-style-iw.btn-group-vertical > button.active,
.btn-group-vertical > .active#buorgig,
.btn-group-vertical > .active#buorgul,
.btn-group-vertical > .active#buorgpermanent {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .ratings-and-reviews-btn:not(:first-child), .bv_modal_outer_content .btn-group > .bv_button_buttonFull:not(:first-child),
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > button.bv-submission-button-submit:not(:first-child), .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .bv-content-btn:not(:first-child), .gm-style .gm-style-iw.btn-group > button:not(:first-child), .btn-group > #buorgig:not(:first-child), .btn-group > #buorgul:not(:first-child),
.btn-group > #buorgpermanent:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .ratings-and-reviews-btn:not(:last-child):not(.dropdown-toggle), .bv_modal_outer_content .btn-group > .bv_button_buttonFull:not(:last-child):not(.dropdown-toggle),
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > button.bv-submission-button-submit:not(:last-child):not(.dropdown-toggle), .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .bv-content-btn:not(:last-child):not(.dropdown-toggle), .gm-style .gm-style-iw.btn-group > button:not(:last-child):not(.dropdown-toggle), .btn-group > #buorgig:not(:last-child):not(.dropdown-toggle), .btn-group > #buorgul:not(:last-child):not(.dropdown-toggle),
.btn-group > #buorgpermanent:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .ratings-and-reviews-btn,
.bv_modal_outer_content .btn-group > .btn-group:not(:last-child) > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > .btn-group:not(:last-child) > button.bv-submission-button-submit,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .btn-group:not(:last-child) > .bv-content-btn,
.gm-style .btn-group > .gm-style-iw.btn-group:not(:last-child) > button,
.btn-group > .btn-group:not(:last-child) > #buorgig,
.btn-group > .btn-group:not(:last-child) > #buorgul,
.btn-group > .btn-group:not(:last-child) > #buorgpermanent {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child), .btn-group > .ratings-and-reviews-btn:not(:first-child), .bv_modal_outer_content .btn-group > .bv_button_buttonFull:not(:first-child),
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > button.bv-submission-button-submit:not(:first-child), .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .bv-content-btn:not(:first-child), .gm-style .gm-style-iw.btn-group > button:not(:first-child), .btn-group > #buorgig:not(:first-child), .btn-group > #buorgul:not(:first-child),
.btn-group > #buorgpermanent:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .ratings-and-reviews-btn,
.bv_modal_outer_content .btn-group > .btn-group:not(:first-child) > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group > .btn-group:not(:first-child) > button.bv-submission-button-submit,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group > .btn-group:not(:first-child) > .bv-content-btn,
.gm-style .btn-group > .gm-style-iw.btn-group:not(:first-child) > button,
.btn-group > .btn-group:not(:first-child) > #buorgig,
.btn-group > .btn-group:not(:first-child) > #buorgul,
.btn-group > .btn-group:not(:first-child) > #buorgpermanent {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .ratings-and-reviews-btn + .dropdown-toggle-split, .bv_modal_outer_content .btn-group-sm > .bv_button_buttonFull + .dropdown-toggle-split,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-sm > button.bv-submission-button-submit + .dropdown-toggle-split, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-sm > .bv-content-btn + .dropdown-toggle-split, .gm-style .gm-style-iw.btn-group-sm > button + .dropdown-toggle-split, .btn-group-sm > #buorgig + .dropdown-toggle-split, .btn-group-sm > #buorgul + .dropdown-toggle-split,
.btn-group-sm > #buorgpermanent + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .ratings-and-reviews-btn + .dropdown-toggle-split, .bv_modal_outer_content .btn-group-lg > .bv_button_buttonFull + .dropdown-toggle-split,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-lg > button.bv-submission-button-submit + .dropdown-toggle-split, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-lg > .bv-content-btn + .dropdown-toggle-split, .gm-style .gm-style-iw.btn-group-lg > button + .dropdown-toggle-split, .btn-group-lg > #buorgig + .dropdown-toggle-split, .btn-group-lg > #buorgul + .dropdown-toggle-split,
.btn-group-lg > #buorgpermanent + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .btn-group-vertical > .ratings-and-reviews-btn, .bv_modal_outer_content .btn-group-vertical > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .bv-content-btn, .gm-style .gm-style-iw.btn-group-vertical > button, .btn-group-vertical > #buorgig, .btn-group-vertical > #buorgul,
.btn-group-vertical > #buorgpermanent,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .ratings-and-reviews-btn:not(:first-child), .bv_modal_outer_content .btn-group-vertical > .bv_button_buttonFull:not(:first-child),
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > button.bv-submission-button-submit:not(:first-child), .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .bv-content-btn:not(:first-child), .gm-style .gm-style-iw.btn-group-vertical > button:not(:first-child), .btn-group-vertical > #buorgig:not(:first-child), .btn-group-vertical > #buorgul:not(:first-child),
.btn-group-vertical > #buorgpermanent:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .ratings-and-reviews-btn:not(:last-child):not(.dropdown-toggle), .bv_modal_outer_content .btn-group-vertical > .bv_button_buttonFull:not(:last-child):not(.dropdown-toggle),
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > button.bv-submission-button-submit:not(:last-child):not(.dropdown-toggle), .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .bv-content-btn:not(:last-child):not(.dropdown-toggle), .gm-style .gm-style-iw.btn-group-vertical > button:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > #buorgig:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > #buorgul:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > #buorgpermanent:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .ratings-and-reviews-btn,
.bv_modal_outer_content .btn-group-vertical > .btn-group:not(:last-child) > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > .btn-group:not(:last-child) > button.bv-submission-button-submit,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .btn-group:not(:last-child) > .bv-content-btn,
.gm-style .btn-group-vertical > .gm-style-iw.btn-group:not(:last-child) > button,
.btn-group-vertical > .btn-group:not(:last-child) > #buorgig,
.btn-group-vertical > .btn-group:not(:last-child) > #buorgul,
.btn-group-vertical > .btn-group:not(:last-child) > #buorgpermanent {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .ratings-and-reviews-btn:not(:first-child), .bv_modal_outer_content .btn-group-vertical > .bv_button_buttonFull:not(:first-child),
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > button.bv-submission-button-submit:not(:first-child), .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .bv-content-btn:not(:first-child), .gm-style .gm-style-iw.btn-group-vertical > button:not(:first-child), .btn-group-vertical > #buorgig:not(:first-child), .btn-group-vertical > #buorgul:not(:first-child),
.btn-group-vertical > #buorgpermanent:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .ratings-and-reviews-btn,
.bv_modal_outer_content .btn-group-vertical > .btn-group:not(:first-child) > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-vertical > .btn-group:not(:first-child) > button.bv-submission-button-submit,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-vertical > .btn-group:not(:first-child) > .bv-content-btn,
.gm-style .btn-group-vertical > .gm-style-iw.btn-group:not(:first-child) > button,
.btn-group-vertical > .btn-group:not(:first-child) > #buorgig,
.btn-group-vertical > .btn-group:not(:first-child) > #buorgul,
.btn-group-vertical > .btn-group:not(:first-child) > #buorgpermanent {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .ratings-and-reviews-btn, .bv_modal_outer_content .btn-group-toggle > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-toggle > button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-toggle > .bv-content-btn, .gm-style .gm-style-iw.btn-group-toggle > button, .btn-group-toggle > #buorgig, .btn-group-toggle > #buorgul,
.btn-group-toggle > #buorgpermanent,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .ratings-and-reviews-btn,
.bv_modal_outer_content .btn-group-toggle > .btn-group > .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-toggle > .btn-group > button.bv-submission-button-submit,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-toggle > .btn-group > .bv-content-btn,
.gm-style .btn-group-toggle > .gm-style-iw.btn-group > button,
.btn-group-toggle > .btn-group > #buorgig,
.btn-group-toggle > .btn-group > #buorgul,
.btn-group-toggle > .btn-group > #buorgpermanent {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .ratings-and-reviews-btn input[type=radio], .bv_modal_outer_content .btn-group-toggle > .bv_button_buttonFull input[type=radio],
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-toggle > button.bv-submission-button-submit input[type=radio], .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-toggle > .bv-content-btn input[type=radio], .gm-style .gm-style-iw.btn-group-toggle > button input[type=radio], .btn-group-toggle > #buorgig input[type=radio], .btn-group-toggle > #buorgul input[type=radio],
.btn-group-toggle > #buorgpermanent input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .ratings-and-reviews-btn input[type=checkbox],
.bv_modal_outer_content .btn-group-toggle > .bv_button_buttonFull input[type=checkbox],
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-toggle > button.bv-submission-button-submit input[type=checkbox],
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-toggle > .bv-content-btn input[type=checkbox],
.gm-style .gm-style-iw.btn-group-toggle > button input[type=checkbox],
.btn-group-toggle > #buorgig input[type=checkbox],
.btn-group-toggle > #buorgul input[type=checkbox],
.btn-group-toggle > #buorgpermanent input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .ratings-and-reviews-btn input[type=radio],
.bv_modal_outer_content .btn-group-toggle > .btn-group > .bv_button_buttonFull input[type=radio],
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-toggle > .btn-group > button.bv-submission-button-submit input[type=radio],
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-toggle > .btn-group > .bv-content-btn input[type=radio],
.gm-style .btn-group-toggle > .gm-style-iw.btn-group > button input[type=radio],
.btn-group-toggle > .btn-group > #buorgig input[type=radio],
.btn-group-toggle > .btn-group > #buorgul input[type=radio],
.btn-group-toggle > .btn-group > #buorgpermanent input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .ratings-and-reviews-btn input[type=checkbox],
.bv_modal_outer_content .btn-group-toggle > .btn-group > .bv_button_buttonFull input[type=checkbox],
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn-group-toggle > .btn-group > button.bv-submission-button-submit input[type=checkbox],
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn-group-toggle > .btn-group > .bv-content-btn input[type=checkbox],
.gm-style .btn-group-toggle > .gm-style-iw.btn-group > button input[type=checkbox],
.btn-group-toggle > .btn-group > #buorgig input[type=checkbox],
.btn-group-toggle > .btn-group > #buorgul input[type=checkbox],
.btn-group-toggle > .btn-group > #buorgpermanent input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.25rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #34303d;
  border-color: #34303d;
  background-color: #fffdfb;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #34303d;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #34303d;
  background-color: #fffdfb;
  border-color: #34303d;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #b8b7bb;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #fffdfb;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0rem;
  left: -2.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  content: "";
  background-color: #fffdfb;
  border: 2px solid #34303d;
}
.custom-control-label::after {
  position: absolute;
  top: 0rem;
  left: -2.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='17.55' height='13.225' viewBox='0 0 17.55 13.225'%3e%3cpath fill='%23fff' d='m5.225 13.225c-.2 0-.4-.1-.5-.2l-4.5-4.7c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4 4.2 10.9-11.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-11.5 11.7c-.2.2-.4.2-.6.2z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #00d1d2;
  background-color: #00d1d2;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%2334303d' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fffdfb;
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #fffdfb;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%2334303d'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fffdfb;
}

.custom-switch {
  padding-left: 3.375rem;
}
.custom-switch .custom-control-label::before {
  left: -3.375rem;
  width: 2.625rem;
  pointer-events: all;
  border-radius: 0.75rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0rem + 4px);
  left: calc(-3.375rem + 4px);
  width: calc(1.5rem - 8px);
  height: calc(1.5rem - 8px);
  background-color: #34303d;
  border-radius: 0.75rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fffdfb;
  transform: translateX(1.125rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fffdfb;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3rem;
  padding: 0.6875rem 1.5rem 0.6875rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #34303d;
  vertical-align: middle;
  background: #fffdfb url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath d='m7.914 10.929-4.721-4.721c-.257-.257-.257-.686 0-.944.258-.257.687-.257.944 0l3.863 3.863 3.863-3.863c.257-.257.686-.257.944 0 .257.258.257.687 0 .944z' fill='%2334303d'/%3e%3c/svg%3e") no-repeat right 1rem center/1.25rem;
  border: 1px solid #99979e;
  border-radius: 3px;
  appearance: none;
}
.custom-select:focus {
  border-color: #34303d;
  outline: 0;
  box-shadow: inset 0 0 0 1px #34303d;
}
.custom-select:focus::-ms-value {
  color: #34303d;
  background-color: #fffdfb;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #b8b7bb;
  background-color: #fffdfb;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #34303d;
}

.custom-select-sm {
  height: 2.125rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: 2.625rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 3rem;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #34303d;
  box-shadow: inset 0 0 0 1px #34303d;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #fffdfb;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 3rem;
  padding: 0.6875rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #34303d;
  background-color: #fffdfb;
  border: 1px solid #99979e;
  border-radius: 3px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.875rem;
  padding: 0.6875rem 1rem;
  line-height: 1.5;
  color: #34303d;
  content: "Browse";
  background-color: #99979e;
  border-left: inherit;
  border-radius: 0 3px 3px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fffdfb, inset 0 0 0 1px #34303d;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fffdfb, inset 0 0 0 1px #34303d;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fffdfb, inset 0 0 0 1px #34303d;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #00d1d2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #86feff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e1e0e2;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #00d1d2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #86feff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e1e0e2;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #00d1d2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #86feff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #e1e0e2;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e1e0e2;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #99979e;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #99979e;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #99979e;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.75rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #7b7881;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid transparent;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: transparent;
}
.nav-tabs .nav-link.disabled {
  color: #7b7881;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #00d1d2;
  background-color: #fffdfb;
  border-color: transparent;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 3px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00d1d2;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 2px 2px 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 2px 2px;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 2px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.card-deck .card {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 1rem;
    margin-bottom: 0;
    margin-left: 1rem;
  }
}

.card-group > .card {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.125rem 0.5rem;
  margin-left: 0;
  line-height: 1.25;
  color: #34303d;
  background-color: #fff;
  border: 0 solid transparent;
}
.page-link:hover {
  z-index: 2;
  color: #34303d;
  text-decoration: none;
  background-color: #e1e0e2;
  border-color: transparent;
}
.page-link:focus {
  z-index: 3;
  outline: -webkit-focus-ring-color auto 1px;
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 210, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #34303d;
  background-color: #00d1d2;
  border-color: #00d1d2;
}
.page-item.disabled .page-link {
  color: #7b7881;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e1e0e2;
}

.pagination-lg .page-link {
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.badge {
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125rem;
  transition: color 0.2s, opacity 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge, .ratings-and-reviews-btn .badge, .bv_modal_outer_content .bv_button_buttonFull .badge,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit .badge, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn .badge, .gm-style .gm-style-iw > button .badge, #buorgig .badge, #buorgul .badge,
#buorgpermanent .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.8em;
  padding-left: 0.8em;
  border-radius: 10rem;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 2rem;
  border: 1px solid transparent;
  border-radius: 3px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #34303d;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #34303d;
  text-decoration: none;
  background-color: #fffdfb;
}
.list-group-item-action:active {
  color: #34303d;
  background-color: #f6f4f0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  color: #34303d;
  background-color: #fffdfb;
  border: 1px solid #f6f4f0;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #7b7881;
  pointer-events: none;
  background-color: #fffdfb;
}
.list-group-item.active {
  z-index: 2;
  color: #34303d;
  background-color: #f6f4f0;
  border-color: #f6f4f0;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 40rem;
  max-width: 40rem;
  font-size: 0.875rem;
  color: #34303d;
  background-color: #fffdfb;
  background-clip: padding-box;
  border: 1px solid #f6f4f0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  opacity: 0;
  border-radius: 0;
}
.toast:not(:last-child) {
  margin-bottom: 2rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 1.7rem 2rem;
  color: #7b7881;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.toast-body {
  padding: 2rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1052;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 2rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 4rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 4rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 4rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 4rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fffdfb;
  background-clip: padding-box;
  border: 0 solid #b8b7bb;
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1051;
  width: 100vw;
  height: 100vh;
  background-color: #524f5a;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.85;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #b8b7bb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 2.25rem;
  border-top: 0 solid #b8b7bb;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 496px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 640px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 840px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 2px;
  font-family: "IQOS", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.5rem;
  height: 0.25rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.25rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.25rem 0.25rem 0;
  border-top-color: #34303d;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.25rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.25rem;
  height: 0.5rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.25rem 0.25rem 0.25rem 0;
  border-right-color: #34303d;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.25rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.25rem 0.25rem;
  border-bottom-color: #34303d;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.25rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.25rem;
  height: 0.5rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.25rem 0 0.25rem 0.25rem;
  border-left-color: #34303d;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.375rem 0.625rem;
  color: #fff;
  text-align: center;
  background-color: #34303d;
  border-radius: 4px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "IQOS", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fffdfb;
  background-clip: padding-box;
  border: 0 solid #b8b7bb;
  border-radius: 3px;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1.5rem;
  height: 0.75rem;
  margin: 0 3px;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.75rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: -0.75rem;
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.75rem 0.75rem 0;
  border-top-color: #b8b7bb;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 0;
  border-width: 0.75rem 0.75rem 0;
  border-top-color: #fffdfb;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.75rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: -0.75rem;
  width: 0.75rem;
  height: 1.5rem;
  margin: 3px 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.75rem 0.75rem 0.75rem 0;
  border-right-color: #b8b7bb;
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 0;
  border-width: 0.75rem 0.75rem 0.75rem 0;
  border-right-color: #fffdfb;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.75rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: -0.75rem;
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.75rem 0.75rem 0.75rem;
  border-bottom-color: #b8b7bb;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 0;
  border-width: 0 0.75rem 0.75rem 0.75rem;
  border-bottom-color: #fffdfb;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1.5rem;
  margin-left: -0.75rem;
  content: "";
  border-bottom: 0 solid #fffdfb;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.75rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: -0.75rem;
  width: 0.75rem;
  height: 1.5rem;
  margin: 3px 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.75rem 0 0.75rem 0.75rem;
  border-left-color: #b8b7bb;
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 0;
  border-width: 0.75rem 0 0.75rem 0.75rem;
  border-left-color: #fffdfb;
}

.popover-header {
  padding: 0.5rem 1.125rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #fffdfb;
  border-bottom: 0 solid #fff0e2;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 1.125rem;
  color: #34303d;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #b8b7bb !important;
}

.border-top {
  border-top: 1px solid #b8b7bb !important;
}

.border-right {
  border-right: 1px solid #b8b7bb !important;
}

.border-bottom {
  border-bottom: 1px solid #b8b7bb !important;
}

.border-left {
  border-left: 1px solid #b8b7bb !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 3px !important;
}

.rounded {
  border-radius: 3px !important;
}

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-lg {
  border-radius: 6px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1040;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1030;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2,
#buorgig,
#buorgul,
#buorgpermanent {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2,
#buorgig,
#buorgul,
#buorgpermanent {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .buorg-buttons,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}

.m-8 {
  margin: 6rem !important;
}

.mt-8,
.my-8 {
  margin-top: 6rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 6rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 6rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 6rem !important;
}

.m-9 {
  margin: 7rem !important;
}

.mt-9,
.my-9 {
  margin-top: 7rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 7rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 7rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 7rem !important;
}

.m-10 {
  margin: 8rem !important;
}

.mt-10,
.my-10 {
  margin-top: 8rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 8rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 8rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 8rem !important;
}

.m-11 {
  margin: 9rem !important;
}

.mt-11,
.my-11 {
  margin-top: 9rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 9rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 9rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 9rem !important;
}

.m-12 {
  margin: 10rem !important;
}

.mt-12,
.my-12 {
  margin-top: 10rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 10rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 10rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3,
.buorg-pad {
  padding-top: 1rem !important;
}

.pr-3,
.px-3,
.buorg-pad {
  padding-right: 1rem !important;
}

.pb-3,
.py-3,
.buorg-pad {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3,
.buorg-pad {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}

.p-8 {
  padding: 6rem !important;
}

.pt-8,
.py-8 {
  padding-top: 6rem !important;
}

.pr-8,
.px-8 {
  padding-right: 6rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 6rem !important;
}

.pl-8,
.px-8 {
  padding-left: 6rem !important;
}

.p-9 {
  padding: 7rem !important;
}

.pt-9,
.py-9 {
  padding-top: 7rem !important;
}

.pr-9,
.px-9 {
  padding-right: 7rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 7rem !important;
}

.pl-9,
.px-9 {
  padding-left: 7rem !important;
}

.p-10 {
  padding: 8rem !important;
}

.pt-10,
.py-10 {
  padding-top: 8rem !important;
}

.pr-10,
.px-10 {
  padding-right: 8rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 8rem !important;
}

.pl-10,
.px-10 {
  padding-left: 8rem !important;
}

.p-11 {
  padding: 9rem !important;
}

.pt-11,
.py-11 {
  padding-top: 9rem !important;
}

.pr-11,
.px-11 {
  padding-right: 9rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 9rem !important;
}

.pl-11,
.px-11 {
  padding-left: 9rem !important;
}

.p-12 {
  padding: 10rem !important;
}

.pt-12,
.py-12 {
  padding-top: 10rem !important;
}

.pr-12,
.px-12 {
  padding-right: 10rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 10rem !important;
}

.pl-12,
.px-12 {
  padding-left: 10rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important;
}

.m-n7 {
  margin: -5rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -5rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -5rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -5rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -5rem !important;
}

.m-n8 {
  margin: -6rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -6rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -6rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -6rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -6rem !important;
}

.m-n9 {
  margin: -7rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -7rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -7rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -7rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -7rem !important;
}

.m-n10 {
  margin: -8rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -8rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -8rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -8rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -8rem !important;
}

.m-n11 {
  margin: -9rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -9rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -9rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -9rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -9rem !important;
}

.m-n12 {
  margin: -10rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -10rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -10rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -10rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important;
  }
  .m-sm-8 {
    margin: 6rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 6rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 6rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 6rem !important;
  }
  .m-sm-9 {
    margin: 7rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 7rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 7rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 7rem !important;
  }
  .m-sm-10 {
    margin: 8rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 8rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 8rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 8rem !important;
  }
  .m-sm-11 {
    margin: 9rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 9rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 9rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 9rem !important;
  }
  .m-sm-12 {
    margin: 10rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 10rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 10rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 10rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important;
  }
  .p-sm-8 {
    padding: 6rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 6rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 6rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 6rem !important;
  }
  .p-sm-9 {
    padding: 7rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 7rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 7rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 7rem !important;
  }
  .p-sm-10 {
    padding: 8rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 8rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 8rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 8rem !important;
  }
  .p-sm-11 {
    padding: 9rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 9rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 9rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 9rem !important;
  }
  .p-sm-12 {
    padding: 10rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 10rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 10rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 10rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem !important;
  }
  .m-sm-n7 {
    margin: -5rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -5rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -5rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -5rem !important;
  }
  .m-sm-n8 {
    margin: -6rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -6rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -6rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -6rem !important;
  }
  .m-sm-n9 {
    margin: -7rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -7rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -7rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -7rem !important;
  }
  .m-sm-n10 {
    margin: -8rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -8rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -8rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -8rem !important;
  }
  .m-sm-n11 {
    margin: -9rem !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -9rem !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -9rem !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -9rem !important;
  }
  .m-sm-n12 {
    margin: -10rem !important;
  }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -10rem !important;
  }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -10rem !important;
  }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important;
  }
  .m-md-8 {
    margin: 6rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 6rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 6rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 6rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 6rem !important;
  }
  .m-md-9 {
    margin: 7rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 7rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 7rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 7rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 7rem !important;
  }
  .m-md-10 {
    margin: 8rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 8rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 8rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 8rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 8rem !important;
  }
  .m-md-11 {
    margin: 9rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 9rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 9rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 9rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 9rem !important;
  }
  .m-md-12 {
    margin: 10rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 10rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 10rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 10rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 10rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important;
  }
  .p-md-8 {
    padding: 6rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 6rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 6rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 6rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 6rem !important;
  }
  .p-md-9 {
    padding: 7rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 7rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 7rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 7rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 7rem !important;
  }
  .p-md-10 {
    padding: 8rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 8rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 8rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 8rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 8rem !important;
  }
  .p-md-11 {
    padding: 9rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 9rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 9rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 9rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 9rem !important;
  }
  .p-md-12 {
    padding: 10rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 10rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 10rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 10rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 10rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -4rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem !important;
  }
  .m-md-n7 {
    margin: -5rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -5rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -5rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -5rem !important;
  }
  .m-md-n8 {
    margin: -6rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -6rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -6rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -6rem !important;
  }
  .m-md-n9 {
    margin: -7rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -7rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -7rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -7rem !important;
  }
  .m-md-n10 {
    margin: -8rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -8rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -8rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -8rem !important;
  }
  .m-md-n11 {
    margin: -9rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -9rem !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -9rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -9rem !important;
  }
  .m-md-n12 {
    margin: -10rem !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -10rem !important;
  }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -10rem !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important;
  }
  .m-lg-8 {
    margin: 6rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 6rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 6rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 6rem !important;
  }
  .m-lg-9 {
    margin: 7rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 7rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 7rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 7rem !important;
  }
  .m-lg-10 {
    margin: 8rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 8rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 8rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 8rem !important;
  }
  .m-lg-11 {
    margin: 9rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 9rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 9rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 9rem !important;
  }
  .m-lg-12 {
    margin: 10rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 10rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 10rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 10rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important;
  }
  .p-lg-8 {
    padding: 6rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 6rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 6rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 6rem !important;
  }
  .p-lg-9 {
    padding: 7rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 7rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 7rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 7rem !important;
  }
  .p-lg-10 {
    padding: 8rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 8rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 8rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 8rem !important;
  }
  .p-lg-11 {
    padding: 9rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 9rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 9rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 9rem !important;
  }
  .p-lg-12 {
    padding: 10rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 10rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 10rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 10rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem !important;
  }
  .m-lg-n7 {
    margin: -5rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -5rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -5rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -5rem !important;
  }
  .m-lg-n8 {
    margin: -6rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -6rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -6rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -6rem !important;
  }
  .m-lg-n9 {
    margin: -7rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -7rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -7rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -7rem !important;
  }
  .m-lg-n10 {
    margin: -8rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -8rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -8rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -8rem !important;
  }
  .m-lg-n11 {
    margin: -9rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -9rem !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -9rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -9rem !important;
  }
  .m-lg-n12 {
    margin: -10rem !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -10rem !important;
  }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -10rem !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important;
  }
  .m-xl-8 {
    margin: 6rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 6rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 6rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 6rem !important;
  }
  .m-xl-9 {
    margin: 7rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 7rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 7rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 7rem !important;
  }
  .m-xl-10 {
    margin: 8rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 8rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 8rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 8rem !important;
  }
  .m-xl-11 {
    margin: 9rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 9rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 9rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 9rem !important;
  }
  .m-xl-12 {
    margin: 10rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 10rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 10rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 10rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important;
  }
  .p-xl-8 {
    padding: 6rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 6rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 6rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 6rem !important;
  }
  .p-xl-9 {
    padding: 7rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 7rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 7rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 7rem !important;
  }
  .p-xl-10 {
    padding: 8rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 8rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 8rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 8rem !important;
  }
  .p-xl-11 {
    padding: 9rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 9rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 9rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 9rem !important;
  }
  .p-xl-12 {
    padding: 10rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 10rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 10rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 10rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem !important;
  }
  .m-xl-n7 {
    margin: -5rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -5rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -5rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -5rem !important;
  }
  .m-xl-n8 {
    margin: -6rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -6rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -6rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -6rem !important;
  }
  .m-xl-n9 {
    margin: -7rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -7rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -7rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -7rem !important;
  }
  .m-xl-n10 {
    margin: -8rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -8rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -8rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -8rem !important;
  }
  .m-xl-n11 {
    margin: -9rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -9rem !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -9rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -9rem !important;
  }
  .m-xl-n12 {
    margin: -10rem !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -10rem !important;
  }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -10rem !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #34303d !important;
}

.text-muted {
  color: #99979e !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn):not(.ratings-and-reviews-btn):not(#buorgig):not(#buorgul):not(#buorgpermanent) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #99979e;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e1e0e2 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #b8b7bb;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #b8b7bb;
  }
}
@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.modal-video-close {
  opacity: 0;
}
.modal-video-close .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table;
}

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}
.modal-video-close-btn:before {
  transform: rotate(45deg);
}
.modal-video-close-btn:after {
  transform: rotate(-45deg);
}
.modal-video-close-btn:before, .modal-video-close-btn:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #b8b7bb;
  border-radius: 3px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #b8b7bb;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #b8b7bb;
  border-radius: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #b8b7bb;
  border-radius: 3px;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #b8b7bb;
  border-radius: 3px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #b8b7bb;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

#spr-live-chat-app {
  position: relative;
  z-index: 1029;
}

.toast-title {
  font-weight: bold;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}
body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0s;
}
body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}
body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: 100ms;
}
body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0s;
}
body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: 100ms;
}
body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: 150ms;
}
body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0s;
}
body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: 150ms;
}
body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: 200ms;
}
body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0s;
}
body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: 200ms;
}
body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: 250ms;
}
body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0s;
}
body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: 250ms;
}
body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: 300ms;
}
body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0s;
}
body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: 300ms;
}
body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: 350ms;
}
body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0s;
}
body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: 350ms;
}
body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: 400ms;
}
body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0s;
}
body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: 400ms;
}
body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: 450ms;
}
body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0s;
}
body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: 450ms;
}
body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: 500ms;
}
body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0s;
}
body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: 500ms;
}
body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: 550ms;
}
body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0s;
}
body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: 550ms;
}
body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: 600ms;
}
body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0s;
}
body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: 600ms;
}
body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: 650ms;
}
body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0s;
}
body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: 650ms;
}
body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: 700ms;
}
body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0s;
}
body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: 700ms;
}
body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: 750ms;
}
body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0s;
}
body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: 750ms;
}
body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: 800ms;
}
body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0s;
}
body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: 800ms;
}
body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: 850ms;
}
body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0s;
}
body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: 850ms;
}
body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: 900ms;
}
body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0s;
}
body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: 900ms;
}
body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: 950ms;
}
body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0s;
}
body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: 950ms;
}
body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1000ms;
}
body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0s;
}
body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1000ms;
}
body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1050ms;
}
body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0s;
}
body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1050ms;
}
body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1100ms;
}
body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0s;
}
body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1100ms;
}
body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1150ms;
}
body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0s;
}
body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1150ms;
}
body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1200ms;
}
body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0s;
}
body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1200ms;
}
body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1250ms;
}
body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0s;
}
body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1250ms;
}
body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1300ms;
}
body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0s;
}
body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1300ms;
}
body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1350ms;
}
body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0s;
}
body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1350ms;
}
body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1400ms;
}
body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0s;
}
body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1400ms;
}
body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1450ms;
}
body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0s;
}
body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1450ms;
}
body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1500ms;
}
body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0s;
}
body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1500ms;
}
body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1550ms;
}
body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0s;
}
body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1550ms;
}
body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1600ms;
}
body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0s;
}
body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1600ms;
}
body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1650ms;
}
body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0s;
}
body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1650ms;
}
body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1700ms;
}
body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0s;
}
body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1700ms;
}
body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1750ms;
}
body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0s;
}
body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1750ms;
}
body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1800ms;
}
body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0s;
}
body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1800ms;
}
body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1850ms;
}
body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0s;
}
body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1850ms;
}
body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1900ms;
}
body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0s;
}
body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1900ms;
}
body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1950ms;
}
body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0s;
}
body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1950ms;
}
body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2000ms;
}
body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0s;
}
body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2000ms;
}
body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2050ms;
}
body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0s;
}
body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2050ms;
}
body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2100ms;
}
body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0s;
}
body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2100ms;
}
body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2150ms;
}
body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0s;
}
body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2150ms;
}
body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2200ms;
}
body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0s;
}
body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2200ms;
}
body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2250ms;
}
body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0s;
}
body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2250ms;
}
body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2300ms;
}
body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0s;
}
body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2300ms;
}
body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2350ms;
}
body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0s;
}
body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2350ms;
}
body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2400ms;
}
body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0s;
}
body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2400ms;
}
body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2450ms;
}
body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0s;
}
body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2450ms;
}
body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2500ms;
}
body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0s;
}
body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2500ms;
}
body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2550ms;
}
body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0s;
}
body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2550ms;
}
body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2600ms;
}
body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0s;
}
body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2600ms;
}
body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2650ms;
}
body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0s;
}
body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2650ms;
}
body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2700ms;
}
body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0s;
}
body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2700ms;
}
body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2750ms;
}
body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0s;
}
body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2750ms;
}
body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2800ms;
}
body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0s;
}
body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2800ms;
}
body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2850ms;
}
body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0s;
}
body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2850ms;
}
body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2900ms;
}
body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0s;
}
body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2900ms;
}
body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2950ms;
}
body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0s;
}
body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2950ms;
}
body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3000ms;
}
body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0s;
}
body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3000ms;
}

[data-aos] {
  pointer-events: none;
}
[data-aos].aos-animate {
  pointer-events: auto;
}

body[data-aos-easing=linear] [data-aos], [data-aos][data-aos][data-aos-easing=linear] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
body[data-aos-easing=ease] [data-aos], [data-aos][data-aos][data-aos-easing=ease] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
body[data-aos-easing=ease-in] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}
body[data-aos-easing=ease-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
body[data-aos-easing=ease-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
body[data-aos-easing=ease-in-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
body[data-aos-easing=ease-in-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
body[data-aos-easing=ease-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
body[data-aos-easing=ease-in-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
body[data-aos-easing=ease-in-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */
    /**
    * Slide animations
    */
    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: none;
  }
  html:not(.no-js) [data-aos=fade-up] {
    transform: translate3d(0, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down] {
    transform: translate3d(0, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-right] {
    transform: translate3d(-100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-left] {
    transform: translate3d(100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-up-right] {
    transform: translate3d(-100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-up-left] {
    transform: translate3d(100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-right] {
    transform: translate3d(-100px, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-left] {
    transform: translate3d(100px, -100px, 0);
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
  html:not(.no-js) [data-aos=zoom-in] {
    transform: scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-up] {
    transform: translate3d(0, 100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-down] {
    transform: translate3d(0, -100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-right] {
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-left] {
    transform: translate3d(100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-out] {
    transform: scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-up] {
    transform: translate3d(0, 100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-down] {
    transform: translate3d(0, -100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-right] {
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-left] {
    transform: translate3d(100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
    transition-property: transform;
    visibility: hidden;
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-up] {
    transform: translate3d(0, 100%, 0);
  }
  html:not(.no-js) [data-aos=slide-down] {
    transform: translate3d(0, -100%, 0);
  }
  html:not(.no-js) [data-aos=slide-right] {
    transform: translate3d(-100%, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-left] {
    transform: translate3d(100%, 0, 0);
  }
  html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
    backface-visibility: hidden;
    transition-property: transform;
  }
  html:not(.no-js) [data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg);
  }
  html:not(.no-js) [data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-right] {
    transform: perspective(2500px) rotateY(100deg);
  }
  html:not(.no-js) [data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-up] {
    transform: perspective(2500px) rotateX(-100deg);
  }
  html:not(.no-js) [data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
  html:not(.no-js) [data-aos=flip-down] {
    transform: perspective(2500px) rotateX(100deg);
  }
  html:not(.no-js) [data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
}
.buorg {
  z-index: 1060;
  color: #fff;
  background-color: #34303d;
  border: 0;
  font-family: "IQOS", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
}

.buorg-pad {
  line-height: 1.5;
}

#buorgul,
#buorgpermanent {
  background-color: #fffdfb;
  color: #34303d;
}
#buorgul::before,
#buorgpermanent::before {
  background-color: #00d1d2;
}
#buorgul:hover, #buorgul:focus, #buorgul.focus,
#buorgpermanent:hover,
#buorgpermanent:focus,
#buorgpermanent.focus {
  background-color: transparent;
  color: #34303d;
}
#buorgul:focus, #buorgul.focus,
#buorgpermanent:focus,
#buorgpermanent.focus {
  box-shadow: 0 0 0 0 rgba(0, 209, 210, 0.5);
}
#buorgul.disabled, #buorgul:disabled,
#buorgpermanent.disabled,
#buorgpermanent:disabled {
  background-color: #7b7881;
  color: #e1e0e2;
}
#buorgul:not(:disabled):not(.disabled):active, #buorgul:not(:disabled):not(.disabled).active, .show > #buorgul.dropdown-toggle,
#buorgpermanent:not(:disabled):not(.disabled):active,
#buorgpermanent:not(:disabled):not(.disabled).active,
.show > #buorgpermanent.dropdown-toggle {
  background-color: transparent;
  color: #34303d;
}
#buorgul:not(:disabled):not(.disabled):active:focus, #buorgul:not(:disabled):not(.disabled).active:focus, .show > #buorgul.dropdown-toggle:focus,
#buorgpermanent:not(:disabled):not(.disabled):active:focus,
#buorgpermanent:not(:disabled):not(.disabled).active:focus,
.show > #buorgpermanent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 209, 210, 0.5);
}

#buorgig {
  box-shadow: inset 0 0 0 2px #fffdfb;
  background-color: transparent;
  color: #fffdfb;
}
#buorgig::before {
  background-color: #00d1d2;
}
#buorgig:hover {
  box-shadow: inset 0 0 0 2px transparent;
  color: #34303d;
}
#buorgig:focus, #buorgig.focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(0, 209, 210, 0.5);
  color: #34303d;
}
#buorgig.disabled, #buorgig:disabled {
  box-shadow: inset 0 0 0 2px transparent;
  background-color: #7b7881;
  color: #e1e0e2;
}
#buorgig:not(:disabled):not(.disabled):active, #buorgig:not(:disabled):not(.disabled).active, .show > #buorgig.dropdown-toggle {
  box-shadow: inset 0 0 0 2px transparent;
  color: #34303d;
}
#buorgig:not(:disabled):not(.disabled):active:focus, #buorgig:not(:disabled):not(.disabled).active:focus, .show > #buorgig.dropdown-toggle:focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(0, 209, 210, 0.5);
}

.grecaptcha-badge {
  z-index: 1070;
}

.trade-in-device-select-count.selected:before, .col-with-arrow:not(:last-child)::after, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn-pages-prev,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn-pages-next, .pointer::before, .swiper-button-prev::after,
.swiper-button-next::after, .dropdown-toggle::after, .dropup .dropdown-toggle::after, .toast-title:before, .list-group-item-arrowed-right::before, .link-arrowed-left:not(.loading)::before,
.link-arrowed-right:not(.loading)::after, .nav-toggle::after, .gm-style .gm-style-iw > button::after, .select2-container--nbw .select2-selection--single .select2-selection__arrow b, .btn-arrowed-left:not(.loading)::after,
.btn-arrowed-right:not(.loading)::after, .nbw-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "nbw-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sub-navbar-backdrop, .navbar-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.15s linear;
}
.show.sub-navbar-backdrop, .show.navbar-backdrop {
  display: block;
}
.enter-to.sub-navbar-backdrop, .enter-to.navbar-backdrop {
  opacity: 0.7;
}

.text-light {
  color: #fffdfb !important;
}

.text-gray-extra-light {
  color: #f6f4f0 !important;
}

.text-gray-light {
  color: #e1e0e2 !important;
}

.text-gray-light-lighter {
  color: #EAE8E5 !important;
}

.text-gray {
  color: #b8b7bb !important;
}

.text-gray-dark {
  color: #7b7881 !important;
}

.text-gray-extra-dark {
  color: #524f5a !important;
}

.text-dark {
  color: #34303d !important;
}

.text-extra-dark {
  color: #27242D !important;
}

.text-accent {
  color: #00d1d2 !important;
}

.text-light-accent {
  color: #65D6C5 !important;
}

.text-amber {
  color: #e27d34 !important;
}

.text-red {
  color: #db3826 !important;
}

.text-green {
  color: #57760c !important;
}

.text-blue {
  color: #2980b5 !important;
}

.text-blue-dark {
  color: #27529C !important;
}

.text-deep-red {
  color: #a22f26 !important;
}

.text-error-red {
  color: #c73636 !important;
}

.text-yellow {
  color: #ffd040 !important;
}

.text-iluma-turqoise {
  color: #1accbb !important;
}

.text-iluma-turqoise-alt {
  color: #23DAC5 !important;
}

.text-iluma-yellow {
  color: #f9af1b !important;
}

.text-iluma-yellow-alt {
  color: #F8B811 !important;
}

.text-iluma-light-blue {
  color: #00C0FF !important;
}

.text-iluma-we-blue {
  color: #102645 !important;
}

.text-lil-solid-ez-blue {
  color: #1255A2 !important;
}

.text-veev-blue-dark {
  color: #017398 !important;
}

.text-veev-purple-blue {
  color: #16115B !important;
}

.text-iluma-neon {
  color: #C400A1 !important;
}

.text-aoki-black {
  color: #010715 !important;
}

.text-light {
  color: #fffdfb !important;
}

.text-gray-extra-light {
  color: #f6f4f0 !important;
}

.text-gray-light {
  color: #e1e0e2 !important;
}

.text-gray-light-lighter {
  color: #EAE8E5 !important;
}

.text-gray {
  color: #b8b7bb !important;
}

.text-gray-dark {
  color: #7b7881 !important;
}

.text-gray-extra-dark {
  color: #524f5a !important;
}

.text-dark {
  color: #34303d !important;
}

.text-extra-dark {
  color: #27242D !important;
}

.text-accent {
  color: #00d1d2 !important;
}

.text-light-accent {
  color: #65D6C5 !important;
}

.text-amber {
  color: #e27d34 !important;
}

.text-red {
  color: #db3826 !important;
}

.text-green {
  color: #57760c !important;
}

.text-blue {
  color: #2980b5 !important;
}

.text-blue-dark {
  color: #27529C !important;
}

.text-deep-red {
  color: #a22f26 !important;
}

.text-error-red {
  color: #c73636 !important;
}

.text-yellow {
  color: #ffd040 !important;
}

.text-iluma-turqoise {
  color: #1accbb !important;
}

.text-iluma-turqoise-alt {
  color: #23DAC5 !important;
}

.text-iluma-yellow {
  color: #f9af1b !important;
}

.text-iluma-yellow-alt {
  color: #F8B811 !important;
}

.text-iluma-light-blue {
  color: #00C0FF !important;
}

.text-iluma-we-blue {
  color: #102645 !important;
}

.text-lil-solid-ez-blue {
  color: #1255A2 !important;
}

.text-veev-blue-dark {
  color: #017398 !important;
}

.text-veev-purple-blue {
  color: #16115B !important;
}

.text-iluma-neon {
  color: #C400A1 !important;
}

.text-aoki-black {
  color: #010715 !important;
}

@media (min-width: 576px) {
  .text-sm-light {
    color: #fffdfb !important;
  }
}
@media (min-width: 576px) {
  .text-sm-gray-extra-light {
    color: #f6f4f0 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-gray-light {
    color: #e1e0e2 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-gray-light-lighter {
    color: #EAE8E5 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-gray {
    color: #b8b7bb !important;
  }
}
@media (min-width: 576px) {
  .text-sm-gray-dark {
    color: #7b7881 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-gray-extra-dark {
    color: #524f5a !important;
  }
}
@media (min-width: 576px) {
  .text-sm-dark {
    color: #34303d !important;
  }
}
@media (min-width: 576px) {
  .text-sm-extra-dark {
    color: #27242D !important;
  }
}
@media (min-width: 576px) {
  .text-sm-accent {
    color: #00d1d2 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-light-accent {
    color: #65D6C5 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-amber {
    color: #e27d34 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-red {
    color: #db3826 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-green {
    color: #57760c !important;
  }
}
@media (min-width: 576px) {
  .text-sm-blue {
    color: #2980b5 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-blue-dark {
    color: #27529C !important;
  }
}
@media (min-width: 576px) {
  .text-sm-deep-red {
    color: #a22f26 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-error-red {
    color: #c73636 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-yellow {
    color: #ffd040 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-iluma-turqoise {
    color: #1accbb !important;
  }
}
@media (min-width: 576px) {
  .text-sm-iluma-turqoise-alt {
    color: #23DAC5 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-iluma-yellow {
    color: #f9af1b !important;
  }
}
@media (min-width: 576px) {
  .text-sm-iluma-yellow-alt {
    color: #F8B811 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-iluma-light-blue {
    color: #00C0FF !important;
  }
}
@media (min-width: 576px) {
  .text-sm-iluma-we-blue {
    color: #102645 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-lil-solid-ez-blue {
    color: #1255A2 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-veev-blue-dark {
    color: #017398 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-veev-purple-blue {
    color: #16115B !important;
  }
}
@media (min-width: 576px) {
  .text-sm-iluma-neon {
    color: #C400A1 !important;
  }
}
@media (min-width: 576px) {
  .text-sm-aoki-black {
    color: #010715 !important;
  }
}
@media (min-width: 768px) {
  .text-md-light {
    color: #fffdfb !important;
  }
}
@media (min-width: 768px) {
  .text-md-gray-extra-light {
    color: #f6f4f0 !important;
  }
}
@media (min-width: 768px) {
  .text-md-gray-light {
    color: #e1e0e2 !important;
  }
}
@media (min-width: 768px) {
  .text-md-gray-light-lighter {
    color: #EAE8E5 !important;
  }
}
@media (min-width: 768px) {
  .text-md-gray {
    color: #b8b7bb !important;
  }
}
@media (min-width: 768px) {
  .text-md-gray-dark {
    color: #7b7881 !important;
  }
}
@media (min-width: 768px) {
  .text-md-gray-extra-dark {
    color: #524f5a !important;
  }
}
@media (min-width: 768px) {
  .text-md-dark {
    color: #34303d !important;
  }
}
@media (min-width: 768px) {
  .text-md-extra-dark {
    color: #27242D !important;
  }
}
@media (min-width: 768px) {
  .text-md-accent {
    color: #00d1d2 !important;
  }
}
@media (min-width: 768px) {
  .text-md-light-accent {
    color: #65D6C5 !important;
  }
}
@media (min-width: 768px) {
  .text-md-amber {
    color: #e27d34 !important;
  }
}
@media (min-width: 768px) {
  .text-md-red {
    color: #db3826 !important;
  }
}
@media (min-width: 768px) {
  .text-md-green {
    color: #57760c !important;
  }
}
@media (min-width: 768px) {
  .text-md-blue {
    color: #2980b5 !important;
  }
}
@media (min-width: 768px) {
  .text-md-blue-dark {
    color: #27529C !important;
  }
}
@media (min-width: 768px) {
  .text-md-deep-red {
    color: #a22f26 !important;
  }
}
@media (min-width: 768px) {
  .text-md-error-red {
    color: #c73636 !important;
  }
}
@media (min-width: 768px) {
  .text-md-yellow {
    color: #ffd040 !important;
  }
}
@media (min-width: 768px) {
  .text-md-iluma-turqoise {
    color: #1accbb !important;
  }
}
@media (min-width: 768px) {
  .text-md-iluma-turqoise-alt {
    color: #23DAC5 !important;
  }
}
@media (min-width: 768px) {
  .text-md-iluma-yellow {
    color: #f9af1b !important;
  }
}
@media (min-width: 768px) {
  .text-md-iluma-yellow-alt {
    color: #F8B811 !important;
  }
}
@media (min-width: 768px) {
  .text-md-iluma-light-blue {
    color: #00C0FF !important;
  }
}
@media (min-width: 768px) {
  .text-md-iluma-we-blue {
    color: #102645 !important;
  }
}
@media (min-width: 768px) {
  .text-md-lil-solid-ez-blue {
    color: #1255A2 !important;
  }
}
@media (min-width: 768px) {
  .text-md-veev-blue-dark {
    color: #017398 !important;
  }
}
@media (min-width: 768px) {
  .text-md-veev-purple-blue {
    color: #16115B !important;
  }
}
@media (min-width: 768px) {
  .text-md-iluma-neon {
    color: #C400A1 !important;
  }
}
@media (min-width: 768px) {
  .text-md-aoki-black {
    color: #010715 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-light {
    color: #fffdfb !important;
  }
}
@media (min-width: 992px) {
  .text-lg-gray-extra-light {
    color: #f6f4f0 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-gray-light {
    color: #e1e0e2 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-gray-light-lighter {
    color: #EAE8E5 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-gray {
    color: #b8b7bb !important;
  }
}
@media (min-width: 992px) {
  .text-lg-gray-dark {
    color: #7b7881 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-gray-extra-dark {
    color: #524f5a !important;
  }
}
@media (min-width: 992px) {
  .text-lg-dark {
    color: #34303d !important;
  }
}
@media (min-width: 992px) {
  .text-lg-extra-dark {
    color: #27242D !important;
  }
}
@media (min-width: 992px) {
  .text-lg-accent {
    color: #00d1d2 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-light-accent {
    color: #65D6C5 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-amber {
    color: #e27d34 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-red {
    color: #db3826 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-green {
    color: #57760c !important;
  }
}
@media (min-width: 992px) {
  .text-lg-blue {
    color: #2980b5 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-blue-dark {
    color: #27529C !important;
  }
}
@media (min-width: 992px) {
  .text-lg-deep-red {
    color: #a22f26 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-error-red {
    color: #c73636 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-yellow {
    color: #ffd040 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-iluma-turqoise {
    color: #1accbb !important;
  }
}
@media (min-width: 992px) {
  .text-lg-iluma-turqoise-alt {
    color: #23DAC5 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-iluma-yellow {
    color: #f9af1b !important;
  }
}
@media (min-width: 992px) {
  .text-lg-iluma-yellow-alt {
    color: #F8B811 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-iluma-light-blue {
    color: #00C0FF !important;
  }
}
@media (min-width: 992px) {
  .text-lg-iluma-we-blue {
    color: #102645 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-lil-solid-ez-blue {
    color: #1255A2 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-veev-blue-dark {
    color: #017398 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-veev-purple-blue {
    color: #16115B !important;
  }
}
@media (min-width: 992px) {
  .text-lg-iluma-neon {
    color: #C400A1 !important;
  }
}
@media (min-width: 992px) {
  .text-lg-aoki-black {
    color: #010715 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-light {
    color: #fffdfb !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-gray-extra-light {
    color: #f6f4f0 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-gray-light {
    color: #e1e0e2 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-gray-light-lighter {
    color: #EAE8E5 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-gray {
    color: #b8b7bb !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-gray-dark {
    color: #7b7881 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-gray-extra-dark {
    color: #524f5a !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-dark {
    color: #34303d !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-extra-dark {
    color: #27242D !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-accent {
    color: #00d1d2 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-light-accent {
    color: #65D6C5 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-amber {
    color: #e27d34 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-red {
    color: #db3826 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-green {
    color: #57760c !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-blue {
    color: #2980b5 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-blue-dark {
    color: #27529C !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-deep-red {
    color: #a22f26 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-error-red {
    color: #c73636 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-yellow {
    color: #ffd040 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-iluma-turqoise {
    color: #1accbb !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-iluma-turqoise-alt {
    color: #23DAC5 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-iluma-yellow {
    color: #f9af1b !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-iluma-yellow-alt {
    color: #F8B811 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-iluma-light-blue {
    color: #00C0FF !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-iluma-we-blue {
    color: #102645 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-lil-solid-ez-blue {
    color: #1255A2 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-veev-blue-dark {
    color: #017398 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-veev-purple-blue {
    color: #16115B !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-iluma-neon {
    color: #C400A1 !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-aoki-black {
    color: #010715 !important;
  }
}
a.text-hover-light:hover, a.text-hover-light:focus {
  color: #fffdfb !important;
}

a.text-hover-gray-extra-light:hover, a.text-hover-gray-extra-light:focus {
  color: #f6f4f0 !important;
}

a.text-hover-gray-light:hover, a.text-hover-gray-light:focus {
  color: #e1e0e2 !important;
}

a.text-hover-gray-light-lighter:hover, a.text-hover-gray-light-lighter:focus {
  color: #EAE8E5 !important;
}

a.text-hover-gray:hover, a.text-hover-gray:focus {
  color: #b8b7bb !important;
}

a.text-hover-gray-dark:hover, a.text-hover-gray-dark:focus {
  color: #7b7881 !important;
}

a.text-hover-gray-extra-dark:hover, a.text-hover-gray-extra-dark:focus {
  color: #524f5a !important;
}

a.text-hover-dark:hover, a.text-hover-dark:focus {
  color: #34303d !important;
}

a.text-hover-extra-dark:hover, a.text-hover-extra-dark:focus {
  color: #27242D !important;
}

a.text-hover-accent:hover, a.text-hover-accent:focus {
  color: #00d1d2 !important;
}

a.text-hover-light-accent:hover, a.text-hover-light-accent:focus {
  color: #65D6C5 !important;
}

a.text-hover-amber:hover, a.text-hover-amber:focus {
  color: #e27d34 !important;
}

a.text-hover-red:hover, a.text-hover-red:focus {
  color: #db3826 !important;
}

a.text-hover-green:hover, a.text-hover-green:focus {
  color: #57760c !important;
}

a.text-hover-blue:hover, a.text-hover-blue:focus {
  color: #2980b5 !important;
}

a.text-hover-blue-dark:hover, a.text-hover-blue-dark:focus {
  color: #27529C !important;
}

a.text-hover-deep-red:hover, a.text-hover-deep-red:focus {
  color: #a22f26 !important;
}

a.text-hover-error-red:hover, a.text-hover-error-red:focus {
  color: #c73636 !important;
}

a.text-hover-yellow:hover, a.text-hover-yellow:focus {
  color: #ffd040 !important;
}

a.text-hover-iluma-turqoise:hover, a.text-hover-iluma-turqoise:focus {
  color: #1accbb !important;
}

a.text-hover-iluma-turqoise-alt:hover, a.text-hover-iluma-turqoise-alt:focus {
  color: #23DAC5 !important;
}

a.text-hover-iluma-yellow:hover, a.text-hover-iluma-yellow:focus {
  color: #f9af1b !important;
}

a.text-hover-iluma-yellow-alt:hover, a.text-hover-iluma-yellow-alt:focus {
  color: #F8B811 !important;
}

a.text-hover-iluma-light-blue:hover, a.text-hover-iluma-light-blue:focus {
  color: #00C0FF !important;
}

a.text-hover-iluma-we-blue:hover, a.text-hover-iluma-we-blue:focus {
  color: #102645 !important;
}

a.text-hover-lil-solid-ez-blue:hover, a.text-hover-lil-solid-ez-blue:focus {
  color: #1255A2 !important;
}

a.text-hover-veev-blue-dark:hover, a.text-hover-veev-blue-dark:focus {
  color: #017398 !important;
}

a.text-hover-veev-purple-blue:hover, a.text-hover-veev-purple-blue:focus {
  color: #16115B !important;
}

a.text-hover-iluma-neon:hover, a.text-hover-iluma-neon:focus {
  color: #C400A1 !important;
}

a.text-hover-aoki-black:hover, a.text-hover-aoki-black:focus {
  color: #010715 !important;
}

.text-transparent {
  color: transparent !important;
}

.font-size-extra-extra-large {
  font-size: 1.375rem !important;
}

.font-size-extra-large {
  font-size: 1.25rem !important;
}

.font-size-large {
  font-size: 1.125rem !important;
}

.font-size-body {
  font-size: 1rem !important;
}

.font-size-small {
  font-size: 0.875rem !important;
}

.font-size-extra-small {
  font-size: 0.75rem !important;
}

.font-size-extra-extra-small {
  font-size: 0.625rem !important;
}

@media (min-width: 576px) {
  .font-size-sm-extra-extra-large {
    font-size: 1.375rem !important;
  }
  .font-size-sm-extra-large {
    font-size: 1.25rem !important;
  }
  .font-size-sm-large {
    font-size: 1.125rem !important;
  }
  .font-size-sm-body {
    font-size: 1rem !important;
  }
  .font-size-sm-small {
    font-size: 0.875rem !important;
  }
  .font-size-sm-extra-small {
    font-size: 0.75rem !important;
  }
  .font-size-sm-extra-extra-small {
    font-size: 0.625rem !important;
  }
}
@media (min-width: 768px) {
  .font-size-md-extra-extra-large {
    font-size: 1.375rem !important;
  }
  .font-size-md-extra-large {
    font-size: 1.25rem !important;
  }
  .font-size-md-large {
    font-size: 1.125rem !important;
  }
  .font-size-md-body {
    font-size: 1rem !important;
  }
  .font-size-md-small {
    font-size: 0.875rem !important;
  }
  .font-size-md-extra-small {
    font-size: 0.75rem !important;
  }
  .font-size-md-extra-extra-small {
    font-size: 0.625rem !important;
  }
}
@media (min-width: 992px) {
  .font-size-lg-extra-extra-large {
    font-size: 1.375rem !important;
  }
  .font-size-lg-extra-large {
    font-size: 1.25rem !important;
  }
  .font-size-lg-large {
    font-size: 1.125rem !important;
  }
  .font-size-lg-body {
    font-size: 1rem !important;
  }
  .font-size-lg-small {
    font-size: 0.875rem !important;
  }
  .font-size-lg-extra-small {
    font-size: 0.75rem !important;
  }
  .font-size-lg-extra-extra-small {
    font-size: 0.625rem !important;
  }
}
@media (min-width: 1200px) {
  .font-size-xl-extra-extra-large {
    font-size: 1.375rem !important;
  }
  .font-size-xl-extra-large {
    font-size: 1.25rem !important;
  }
  .font-size-xl-large {
    font-size: 1.125rem !important;
  }
  .font-size-xl-body {
    font-size: 1rem !important;
  }
  .font-size-xl-small {
    font-size: 0.875rem !important;
  }
  .font-size-xl-extra-small {
    font-size: 0.75rem !important;
  }
  .font-size-xl-extra-extra-small {
    font-size: 0.625rem !important;
  }
}
.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

@media (min-width: 576px) {
  .font-weight-sm-bold {
    font-weight: 700 !important;
  }
  .font-weight-sm-normal {
    font-weight: 400 !important;
  }
  .font-weight-sm-light {
    font-weight: 300 !important;
  }
}
@media (min-width: 768px) {
  .font-weight-md-bold {
    font-weight: 700 !important;
  }
  .font-weight-md-normal {
    font-weight: 400 !important;
  }
  .font-weight-md-light {
    font-weight: 300 !important;
  }
}
@media (min-width: 992px) {
  .font-weight-lg-bold {
    font-weight: 700 !important;
  }
  .font-weight-lg-normal {
    font-weight: 400 !important;
  }
  .font-weight-lg-light {
    font-weight: 300 !important;
  }
}
@media (min-width: 1200px) {
  .font-weight-xl-bold {
    font-weight: 700 !important;
  }
  .font-weight-xl-normal {
    font-weight: 400 !important;
  }
  .font-weight-xl-light {
    font-weight: 300 !important;
  }
}
.text-strikethrough {
  text-decoration: line-through !important;
}

.line-height-none {
  line-height: 1 !important;
}

.line-height-small {
  line-height: 1.2 !important;
}

.line-height-base {
  line-height: 1.5 !important;
}

.line-height-large {
  line-height: 2 !important;
}

.letter-spacing-small {
  letter-spacing: 0.03125em !important;
}

.letter-spacing-nsmall {
  letter-spacing: -0.03125em !important;
}

.letter-spacing-large {
  letter-spacing: 0.2em !important;
}

.text-btn-height {
  display: inline-flex;
  align-items: center;
  min-height: 2.75rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.icon-fw {
  display: inline-block;
  width: 1em;
  min-width: 1em;
  text-align: center;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-top-right {
  top: 0;
  right: 0;
}

.absolute-top-left {
  top: 0;
  left: 0;
}

.absolute-bottom-right {
  bottom: 0;
  right: 0;
}

@media (min-width: 1200px) {
  .position-xl-absolute {
    position: absolute;
  }
}

@media (min-width: 1200px) {
  .absolute-xl-center-x {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-n1 {
  bottom: -1px !important;
}

.right-0 {
  right: 0 !important;
}

.z-index-popover {
  z-index: 1060;
}

.bring-to-top {
  position: relative;
  z-index: 1;
}

.bg-light {
  background-color: #fffdfb !important;
}

.bg-gray-extra-light {
  background-color: #f6f4f0 !important;
}

.bg-gray-light {
  background-color: #e1e0e2 !important;
}

.bg-gray-light-lighter {
  background-color: #EAE8E5 !important;
}

.bg-gray {
  background-color: #b8b7bb !important;
}

.bg-gray-dark {
  background-color: #7b7881 !important;
}

.bg-gray-extra-dark {
  background-color: #524f5a !important;
}

.bg-dark {
  background-color: #34303d !important;
}

.bg-extra-dark {
  background-color: #27242D !important;
}

.bg-accent {
  background-color: #00d1d2 !important;
}

.bg-light-accent {
  background-color: #65D6C5 !important;
}

.bg-amber {
  background-color: #e27d34 !important;
}

.bg-red {
  background-color: #db3826 !important;
}

.bg-green {
  background-color: #57760c !important;
}

.bg-blue {
  background-color: #2980b5 !important;
}

.bg-blue-dark {
  background-color: #27529C !important;
}

.bg-deep-red {
  background-color: #a22f26 !important;
}

.bg-error-red {
  background-color: #c73636 !important;
}

.bg-yellow {
  background-color: #ffd040 !important;
}

.bg-iluma-turqoise {
  background-color: #1accbb !important;
}

.bg-iluma-turqoise-alt {
  background-color: #23DAC5 !important;
}

.bg-iluma-yellow {
  background-color: #f9af1b !important;
}

.bg-iluma-yellow-alt {
  background-color: #F8B811 !important;
}

.bg-iluma-light-blue {
  background-color: #00C0FF !important;
}

.bg-iluma-we-blue {
  background-color: #102645 !important;
}

.bg-lil-solid-ez-blue {
  background-color: #1255A2 !important;
}

.bg-veev-blue-dark {
  background-color: #017398 !important;
}

.bg-veev-purple-blue {
  background-color: #16115B !important;
}

.bg-iluma-neon {
  background-color: #C400A1 !important;
}

.bg-aoki-black {
  background-color: #010715 !important;
}

@media (min-width: 992px) {
  .bg-lg-transparent {
    background-color: transparent !important;
  }
}

.border {
  border: 1px solid #b8b7bb !important;
}

.border-top {
  border-top: 1px solid #b8b7bb !important;
}

.border-right {
  border-right: 1px solid #b8b7bb !important;
}

.border-bottom {
  border-bottom: 1px solid #b8b7bb !important;
}

.border-left {
  border-left: 1px solid #b8b7bb !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

@media (min-width: 576px) {
  .border-sm {
    border: 1px solid #b8b7bb !important;
  }
  .border-sm-top {
    border-top: 1px solid #b8b7bb !important;
  }
  .border-sm-right {
    border-right: 1px solid #b8b7bb !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #b8b7bb !important;
  }
  .border-sm-left {
    border-left: 1px solid #b8b7bb !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-sm-top-0 {
    border-top: 0 !important;
  }
  .border-sm-right-0 {
    border-right: 0 !important;
  }
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-sm-left-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .border-md {
    border: 1px solid #b8b7bb !important;
  }
  .border-md-top {
    border-top: 1px solid #b8b7bb !important;
  }
  .border-md-right {
    border-right: 1px solid #b8b7bb !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #b8b7bb !important;
  }
  .border-md-left {
    border-left: 1px solid #b8b7bb !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-md-top-0 {
    border-top: 0 !important;
  }
  .border-md-right-0 {
    border-right: 0 !important;
  }
  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-md-left-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 992px) {
  .border-lg {
    border: 1px solid #b8b7bb !important;
  }
  .border-lg-top {
    border-top: 1px solid #b8b7bb !important;
  }
  .border-lg-right {
    border-right: 1px solid #b8b7bb !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #b8b7bb !important;
  }
  .border-lg-left {
    border-left: 1px solid #b8b7bb !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-lg-top-0 {
    border-top: 0 !important;
  }
  .border-lg-right-0 {
    border-right: 0 !important;
  }
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-lg-left-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .border-xl {
    border: 1px solid #b8b7bb !important;
  }
  .border-xl-top {
    border-top: 1px solid #b8b7bb !important;
  }
  .border-xl-right {
    border-right: 1px solid #b8b7bb !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #b8b7bb !important;
  }
  .border-xl-left {
    border-left: 1px solid #b8b7bb !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-xl-top-0 {
    border-top: 0 !important;
  }
  .border-xl-right-0 {
    border-right: 0 !important;
  }
  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xl-left-0 {
    border-left: 0 !important;
  }
}
.border-light {
  border-color: #fffdfb !important;
}

.border-gray-extra-light {
  border-color: #f6f4f0 !important;
}

.border-gray-light {
  border-color: #e1e0e2 !important;
}

.border-gray-light-lighter {
  border-color: #EAE8E5 !important;
}

.border-gray {
  border-color: #b8b7bb !important;
}

.border-gray-dark {
  border-color: #7b7881 !important;
}

.border-gray-extra-dark {
  border-color: #524f5a !important;
}

.border-dark {
  border-color: #34303d !important;
}

.border-extra-dark {
  border-color: #27242D !important;
}

.border-accent {
  border-color: #00d1d2 !important;
}

.border-light-accent {
  border-color: #65D6C5 !important;
}

.border-amber {
  border-color: #e27d34 !important;
}

.border-red {
  border-color: #db3826 !important;
}

.border-green {
  border-color: #57760c !important;
}

.border-blue {
  border-color: #2980b5 !important;
}

.border-blue-dark {
  border-color: #27529C !important;
}

.border-deep-red {
  border-color: #a22f26 !important;
}

.border-error-red {
  border-color: #c73636 !important;
}

.border-yellow {
  border-color: #ffd040 !important;
}

.border-iluma-turqoise {
  border-color: #1accbb !important;
}

.border-iluma-turqoise-alt {
  border-color: #23DAC5 !important;
}

.border-iluma-yellow {
  border-color: #f9af1b !important;
}

.border-iluma-yellow-alt {
  border-color: #F8B811 !important;
}

.border-iluma-light-blue {
  border-color: #00C0FF !important;
}

.border-iluma-we-blue {
  border-color: #102645 !important;
}

.border-lil-solid-ez-blue {
  border-color: #1255A2 !important;
}

.border-veev-blue-dark {
  border-color: #017398 !important;
}

.border-veev-purple-blue {
  border-color: #16115B !important;
}

.border-iluma-neon {
  border-color: #C400A1 !important;
}

.border-aoki-black {
  border-color: #010715 !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-width-3 {
  border-width: 3px !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-inline {
  display: inline-block;
  width: auto;
}

@media (min-width: 576px) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
  .btn-sm-inline {
    display: inline-block;
    width: auto;
  }
}
@media (min-width: 768px) {
  .btn-md-block {
    display: block;
    width: 100%;
  }
  .btn-md-inline {
    display: inline-block;
    width: auto;
  }
}
@media (min-width: 992px) {
  .btn-lg-block {
    display: block;
    width: 100%;
  }
  .btn-lg-inline {
    display: inline-block;
    width: auto;
  }
}
@media (min-width: 1200px) {
  .btn-xl-block {
    display: block;
    width: 100%;
  }
  .btn-xl-inline {
    display: inline-block;
    width: auto;
  }
}
.min-w-0 {
  min-width: 0 !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.max-w-450 {
  max-width: 450px !important;
}

.flex-basis-0 {
  flex-basis: 0;
}

.min-height-0 {
  min-height: 0px !important;
}

.min-height-100 {
  min-height: 100px !important;
}

.min-height-200 {
  min-height: 200px !important;
}

.min-height-300 {
  min-height: 300px !important;
}

.min-height-400 {
  min-height: 400px !important;
}

.min-height-500 {
  min-height: 500px !important;
}

.min-height-600 {
  min-height: 600px !important;
}

.min-height-700 {
  min-height: 700px !important;
}

.min-height-800 {
  min-height: 800px !important;
}

.min-height-900 {
  min-height: 900px !important;
}

.min-height-1000 {
  min-height: 1000px !important;
}

@media (min-width: 992px) {
  .min-height-lg-0 {
    min-height: 0px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-100 {
    min-height: 100px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-200 {
    min-height: 200px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-300 {
    min-height: 300px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-400 {
    min-height: 400px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-500 {
    min-height: 500px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-600 {
    min-height: 600px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-700 {
    min-height: 700px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-800 {
    min-height: 800px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-900 {
    min-height: 900px !important;
  }
}

@media (min-width: 992px) {
  .min-height-lg-1000 {
    min-height: 1000px !important;
  }
}

.background-size-cover {
  background-size: cover;
}

.background-position-center {
  background-position: center;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-fluid {
  flex-shrink: 0;
}

.lazy {
  opacity: 0;
  transition: opacity 0.3s;
}
.lazy.loaded {
  opacity: 1;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-visible {
  overflow: visible;
}

@media (min-width: 992px) {
  .overflow-lg-hidden {
    overflow: hidden;
  }
}

.flex-none {
  flex: none !important;
}

.flex-1 {
  flex: 1;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.outline-on-focus:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}
.outline-on-focus:focus-visible .inline-video,
.outline-on-focus:focus-visible img {
  outline: -webkit-focus-ring-color auto 1px;
}
.outline-on-focus:focus-visible.stretched-link:after {
  outline: -webkit-focus-ring-color auto 1px;
}
.outline-on-focus.outline-inside-on-focus:focus-visible {
  outline-offset: -1px;
}
.outline-on-focus.outline-inside-on-focus:focus-visible .inline-video,
.outline-on-focus.outline-inside-on-focus:focus-visible img {
  outline-offset: -1px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}

body {
  font-size: 1rem;
}

.display-1 {
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .display-1 {
    font-size: 3.75rem;
  }
}
@media (min-width: 992px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: 2.25rem;
}
@media (min-width: 768px) {
  .display-2 {
    font-size: 3.125rem;
  }
}
@media (min-width: 992px) {
  .display-2 {
    font-size: 4.0625rem;
  }
}

.display-3 {
  font-size: 2rem;
}
@media (min-width: 768px) {
  .display-3 {
    font-size: 2.875rem;
  }
}
@media (min-width: 992px) {
  .display-3 {
    font-size: 3.5rem;
  }
}

.display-4 {
  font-size: 1.75rem;
}
@media (min-width: 768px) {
  .display-4 {
    font-size: 2.5rem;
  }
}
@media (min-width: 992px) {
  .display-4 {
    font-size: 3rem;
  }
}

h1,
.h1 {
  font-size: 1.75rem;
}
@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: 1.625rem;
}
@media (min-width: 768px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: 1.375rem;
}
@media (min-width: 768px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: 1.25rem;
}
@media (min-width: 768px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.125rem;
}
@media (min-width: 768px) {
  h5,
  .h5 {
    font-size: 1.375rem;
  }
}

hr {
  border-top-color: #b8b7bb;
}

.nbw-icon-user:before {
  content: "\e900";
}

.nbw-icon-cart:before {
  content: "\e901";
}
.nbw-icon-cart[data-count] {
  position: relative;
}
.nbw-icon-cart[data-count]::after {
  display: block;
  position: absolute;
  bottom: -0.5em;
  right: -0.3em;
  border-radius: 1.4em;
  background-color: #00d1d2;
  min-width: 1.4em;
  padding: 0.2em 0.3em;
  font-family: "IQOS", "Helvetica Neue", Arial, sans-serif;
  font-size: 0.5em;
  font-weight: 700;
  text-align: center;
  color: #34303d;
  content: attr(data-count);
}

.nbw-icon-exit:before {
  content: "\e914";
}

.nbw-icon-marker:before {
  content: "\e90c";
}

.nbw-icon-pencil-u:before {
  content: "\e918";
}

.nbw-icon-exchange:before {
  content: "\e91e";
}

.nbw-icon-expand:before {
  content: "\e91f";
}

.nbw-icon-exclamation-mark:before {
  content: "\e91a";
}

.nbw-icon-filter:before {
  content: "\e90d";
}

.nbw-icon-eye:before {
  content: "\e910";
}

.nbw-icon-eye-slash:before {
  content: "\e911";
}

.nbw-icon-discount-coupon:before {
  content: "\e912";
}

.nbw-icon-box:before {
  content: "\e919";
}

.nbw-icon-loudspeaker:before {
  content: "\e920";
}

.nbw-icon-location-arrow:before {
  content: "\e91b";
}

.nbw-icon-play:before {
  content: "\e90b";
}

.nbw-icon-envelope:before {
  content: "\e915";
}

.nbw-icon-commenting:before {
  content: "\e916";
}

.nbw-icon-phone-bubble:before {
  content: "\e917";
}

.nbw-icon-check:before {
  content: "\e913";
}

.nbw-icon-close:before {
  content: "\e902";
}

.nbw-icon-plus:before {
  content: "\e90e";
}

.nbw-icon-minus:before {
  content: "\e90f";
}

.nbw-icon-arrow-left:before {
  content: "\e903";
}

.nbw-icon-arrow-right:before {
  content: "\e904";
}

.nbw-icon-arrow-up:before {
  content: "\e91c";
}

.nbw-icon-arrow-down:before {
  content: "\e91d";
}

.nbw-icon-arrow-left-fat:before {
  content: "\e909";
}

.nbw-icon-arrow-right-fat:before {
  content: "\e90a";
}

.nbw-icon-chevron-left:before {
  content: "\e905";
}

.nbw-icon-chevron-right:before {
  content: "\e906";
}

.nbw-icon-chevron-up:before {
  content: "\e907";
}

.nbw-icon-chevron-down:before {
  content: "\e908";
}

.nbw-icon-link:before {
  content: "\e921";
}

body {
  overflow-x: hidden;
}

.pushable {
  padding-top: 3.5rem;
}
@media (min-width: 1200px) {
  .pushable {
    padding-top: 4.75rem;
  }
}
.pushable.sub-navbar-active {
  padding-top: 7rem;
}
@media (min-width: 1200px) {
  .pushable.sub-navbar-active {
    padding-top: 9.25rem;
  }
}
.pushable.navbar-promo-active {
  padding-top: 6.625rem;
}
@media (min-width: 1200px) {
  .pushable.navbar-promo-active {
    padding-top: 7.875rem;
  }
}
.pushable.navbar-promo-active.sub-navbar-active {
  padding-top: 10.125rem;
}
@media (min-width: 1200px) {
  .pushable.navbar-promo-active.sub-navbar-active {
    padding-top: 12.375rem;
  }
}

.pusher {
  min-height: calc(100vh - 3.5rem);
  overflow-x: hidden;
}
@media (min-width: 1200px) {
  .pusher {
    min-height: calc(100vh - 4.75rem);
  }
}
.navbar-promo-active .pusher {
  min-height: calc(100vh - 6.625rem);
}
@media (min-width: 1200px) {
  .navbar-promo-active .pusher {
    min-height: calc(100vh - 7.875rem);
  }
}

.main-content {
  display: flex;
  flex-direction: column;
}

.reveal-when-visible {
  position: relative;
  overflow: hidden;
}
.reveal-when-visible::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #00d1d2;
  width: 150%;
  height: 100%;
  z-index: 2;
  transform: translate3d(0, 0, 0) skew(-21.5deg);
  transform-origin: top right;
  transition: opacity 0.3s 1.3s, transform 1.3s;
}
.reveal-when-visible.revealed::before {
  transform: translate3d(100%, 0, 0) skew(-21.5deg);
  opacity: 0;
}
.reveal-when-visible .lazy {
  transition: none;
}

.wysiwyg img {
  max-width: 100%;
  height: auto;
}
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .wysiwyg h2,
  .wysiwyg h3,
  .wysiwyg h4 {
    margin-top: 3.5rem;
    margin-bottom: 1.75rem;
  }
}
.wysiwyg ul,
.wysiwyg ol {
  padding-left: 1.25rem;
  margin-bottom: 1.75rem;
}
.wysiwyg ul p,
.wysiwyg ul li,
.wysiwyg ol p,
.wysiwyg ol li {
  margin-bottom: 0.5rem;
}
.wysiwyg table {
  width: 100% !important;
  border-collapse: collapse;
  border: 0;
  margin: 1.25rem 0;
}
.wysiwyg th {
  background-color: #f6f4f0;
  font-weight: bold !important;
  vertical-align: middle !important;
  border: 0 !important;
  padding: 1.25rem !important;
}
.wysiwyg td {
  border: 0 !important;
  margin: 0.5rem 0 !important;
}
@media (min-width: 992px) {
  .wysiwyg td {
    padding: 1.25rem !important;
  }
}
.wysiwyg thead tr {
  padding: 1.25rem !important;
}
@media (min-width: 992px) {
  .wysiwyg thead tr {
    padding: 0 !important;
  }
}
.wysiwyg tbody tr {
  border-bottom: 1px solid #c4c4c4 !important;
  padding: 1rem 0 !important;
}
@media (min-width: 992px) {
  .wysiwyg tbody tr {
    padding: 0 !important;
  }
}
@media (min-width: 992px) {
  .wysiwyg th + th,
  .wysiwyg td + td {
    border-left: 1px solid #c4c4c4 !important;
  }
}
@media (max-width: 991.98px) {
  .wysiwyg table,
  .wysiwyg tbody,
  .wysiwyg th,
  .wysiwyg td,
  .wysiwyg tr {
    width: auto !important;
    height: auto !important;
  }
  .wysiwyg th,
  .wysiwyg td,
  .wysiwyg tbody tr {
    display: block !important;
  }
  .wysiwyg td:first-child,
  .wysiwyg th:first-child {
    padding-bottom: 0 !important;
  }
  .wysiwyg td + td,
  .wysiwyg th + th {
    padding-top: 0 !important;
  }
}

.badge {
  padding-right: 0.96875em;
}
.badge.badge-bigger {
  border-radius: 0.25rem;
  font-size: 1rem;
  padding: 0.375em 0.5em;
}

.badge-pill {
  padding-right: 0.76875em;
}

.badge-dark {
  color: #fffdfb;
  background-color: #34303d;
  border: 1px solid #34303d;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fffdfb;
  background-color: #1c1a20;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 48, 61, 0.5);
}

.badge-dark.badge-outline {
  background-color: #fffdfb;
  color: #34303d;
}

.badge-accent {
  color: #34303d;
  background-color: #00d1d2;
  border: 1px solid #00d1d2;
}
a.badge-accent:hover, a.badge-accent:focus {
  color: #34303d;
  background-color: #009e9f;
}
a.badge-accent:focus, a.badge-accent.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 210, 0.5);
}

.badge-accent.badge-outline {
  background-color: #fffdfb;
  color: #00d1d2;
}

.badge-amber {
  color: #34303d;
  background-color: #e27d34;
  border: 1px solid #e27d34;
}
a.badge-amber:hover, a.badge-amber:focus {
  color: #34303d;
  background-color: #c7641c;
}
a.badge-amber:focus, a.badge-amber.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(226, 125, 52, 0.5);
}

.badge-amber.badge-outline {
  background-color: #fffdfb;
  color: #e27d34;
}

.badge-blue {
  color: #fffdfb;
  background-color: #2980b5;
  border: 1px solid #2980b5;
}
a.badge-blue:hover, a.badge-blue:focus {
  color: #fffdfb;
  background-color: #20638b;
}
a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(41, 128, 181, 0.5);
}

.badge-blue.badge-outline {
  background-color: #fffdfb;
  color: #2980b5;
}

.badge-deep-red {
  color: #fffdfb;
  background-color: #a22f26;
  border: 1px solid #a22f26;
}
a.badge-deep-red:hover, a.badge-deep-red:focus {
  color: #fffdfb;
  background-color: #79231c;
}
a.badge-deep-red:focus, a.badge-deep-red.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(162, 47, 38, 0.5);
}

.badge-deep-red.badge-outline {
  background-color: #fffdfb;
  color: #a22f26;
}

.badge-error-red {
  color: #fffdfb;
  background-color: #c73636;
  border: 1px solid #c73636;
}
a.badge-error-red:hover, a.badge-error-red:focus {
  color: #fffdfb;
  background-color: #9f2b2b;
}
a.badge-error-red:focus, a.badge-error-red.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(199, 54, 54, 0.5);
}

.badge-error-red.badge-outline {
  background-color: #fffdfb;
  color: #c73636;
}

.badge-yellow {
  color: #34303d;
  background-color: #ffd040;
  border: 1px solid #ffd040;
}
a.badge-yellow:hover, a.badge-yellow:focus {
  color: #34303d;
  background-color: #ffc30d;
}
a.badge-yellow:focus, a.badge-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 208, 64, 0.5);
}

.badge-yellow.badge-outline {
  background-color: #fffdfb;
  color: #ffd040;
}

.btn, .ratings-and-reviews-btn, .bv_modal_outer_content .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn, .gm-style .gm-style-iw > button, #buorgul,
#buorgpermanent, #buorgig {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
  overflow: hidden;
  position: relative;
  vertical-align: initial;
  border: 0;
  transition: color 0.2s, opacity 0.3s;
}
.btn::before, .ratings-and-reviews-btn::before, .bv_modal_outer_content .bv_button_buttonFull::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn::before, .gm-style .gm-style-iw > button::before, #buorgul::before,
#buorgpermanent::before, #buorgig::before {
  z-index: -1;
  position: absolute;
  top: 0;
  left: -1rem;
  transform: translate3d(-100%, 0, 0) skew(-30deg);
  width: calc(100% + 2rem);
  height: 100%;
  content: "";
  transition: transform 0.25s ease-out;
}
.btn:hover, .ratings-and-reviews-btn:hover, .bv_modal_outer_content .bv_button_buttonFull:hover,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:hover, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:hover, .gm-style .gm-style-iw > button:hover, #buorgul:hover,
#buorgpermanent:hover, #buorgig:hover, .btn:focus, .ratings-and-reviews-btn:focus, .bv_modal_outer_content .bv_button_buttonFull:focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:focus, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:focus, .gm-style .gm-style-iw > button:focus, #buorgul:focus,
#buorgpermanent:focus, #buorgig:focus, .btn.focus .btn:not(:disabled):not(.disabled):active, .focus.ratings-and-reviews-btn .btn:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.bv_button_buttonFull .btn:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .btn:not(:disabled):not(.disabled):active, .btn.focus .ratings-and-reviews-btn:not(:disabled):not(.disabled):active, .btn.focus .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .btn.focus .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.btn.focus .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn.focus button.bv-submission-button-submit:not(:disabled):not(.disabled):active, .focus.ratings-and-reviews-btn .ratings-and-reviews-btn:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.bv_button_buttonFull .ratings-and-reviews-btn:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .ratings-and-reviews-btn:not(:disabled):not(.disabled):active, .focus.ratings-and-reviews-btn .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.ratings-and-reviews-btn .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.bv_button_buttonFull .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.focus.ratings-and-reviews-btn .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus.ratings-and-reviews-btn button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .focus.bv_button_buttonFull .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus.bv_button_buttonFull button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit button.bv-submission-button-submit:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .btn:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .ratings-and-reviews-btn:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn button.bv-submission-button-submit:not(:disabled):not(.disabled):active, .btn.focus .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active, .focus.ratings-and-reviews-btn .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.bv_button_buttonFull .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn.focus .bv-content-btn:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.ratings-and-reviews-btn .bv-content-btn:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv_modal_outer_content .focus.bv_button_buttonFull .bv-content-btn:not(:disabled):not(.disabled):active, .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv_button_buttonFull .bv-content-btn:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .bv-content-btn:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item button.focus.bv-submission-button-submit .bv-content-btn:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .bv-content-btn:not(:disabled):not(.disabled):active, .gm-style .gm-style-iw > button.focus .btn:not(:disabled):not(.disabled):active, .gm-style .gm-style-iw > button.focus .ratings-and-reviews-btn:not(:disabled):not(.disabled):active, .gm-style .gm-style-iw > button.focus .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .gm-style .gm-style-iw > button.focus .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.gm-style .gm-style-iw > button.focus .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style .gm-style-iw > button.focus button.bv-submission-button-submit:not(:disabled):not(.disabled):active, .gm-style .gm-style-iw > button.focus .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style .gm-style-iw > button.focus .bv-content-btn:not(:disabled):not(.disabled):active, .btn.focus .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active, .focus.ratings-and-reviews-btn .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.bv_button_buttonFull .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active, .gm-style .btn.focus .gm-style-iw > button:not(:disabled):not(.disabled):active, .gm-style .focus.ratings-and-reviews-btn .gm-style-iw > button:not(:disabled):not(.disabled):active, .gm-style .bv_modal_outer_content .focus.bv_button_buttonFull .gm-style-iw > button:not(:disabled):not(.disabled):active, .bv_modal_outer_content .gm-style .focus.bv_button_buttonFull .gm-style-iw > button:not(:disabled):not(.disabled):active,
.gm-style .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .gm-style-iw > button:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style button.focus.bv-submission-button-submit .gm-style-iw > button:not(:disabled):not(.disabled):active, .gm-style .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .gm-style-iw > button:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style .focus.bv-content-btn .gm-style-iw > button:not(:disabled):not(.disabled):active, .gm-style .gm-style-iw > button.focus .gm-style-iw > button:not(:disabled):not(.disabled):active, .focus#buorgul .btn:not(:disabled):not(.disabled):active, .focus#buorgul .ratings-and-reviews-btn:not(:disabled):not(.disabled):active, .focus#buorgul .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus#buorgul .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.focus#buorgul .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus#buorgul button.bv-submission-button-submit:not(:disabled):not(.disabled):active, .focus#buorgul .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus#buorgul .bv-content-btn:not(:disabled):not(.disabled):active, .focus#buorgul .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active, .gm-style .focus#buorgul .gm-style-iw > button:not(:disabled):not(.disabled):active,
.focus#buorgpermanent .btn:not(:disabled):not(.disabled):active,
.focus#buorgpermanent .ratings-and-reviews-btn:not(:disabled):not(.disabled):active,
.focus#buorgpermanent .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .focus#buorgpermanent .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.focus#buorgpermanent .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus#buorgpermanent button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.focus#buorgpermanent .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus#buorgpermanent .bv-content-btn:not(:disabled):not(.disabled):active,
.focus#buorgpermanent .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active,
.gm-style .focus#buorgpermanent .gm-style-iw > button:not(:disabled):not(.disabled):active, .focus#buorgig .btn:not(:disabled):not(.disabled):active, .focus#buorgig .ratings-and-reviews-btn:not(:disabled):not(.disabled):active, .focus#buorgig .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus#buorgig .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.focus#buorgig .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus#buorgig button.bv-submission-button-submit:not(:disabled):not(.disabled):active, .focus#buorgig .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus#buorgig .bv-content-btn:not(:disabled):not(.disabled):active, .focus#buorgig .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active, .gm-style .focus#buorgig .gm-style-iw > button:not(:disabled):not(.disabled):active, .btn.focus #buorgul:not(:disabled):not(.disabled):active, .focus.ratings-and-reviews-btn #buorgul:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.bv_button_buttonFull #buorgul:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit #buorgul:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn #buorgul:not(:disabled):not(.disabled):active, .gm-style .gm-style-iw > button.focus #buorgul:not(:disabled):not(.disabled):active, .focus#buorgul #buorgul:not(:disabled):not(.disabled):active,
.focus#buorgpermanent #buorgul:not(:disabled):not(.disabled):active, .focus#buorgig #buorgul:not(:disabled):not(.disabled):active,
.btn.focus #buorgpermanent:not(:disabled):not(.disabled):active,
.focus.ratings-and-reviews-btn #buorgpermanent:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .focus.bv_button_buttonFull #buorgpermanent:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit #buorgpermanent:not(:disabled):not(.disabled):active,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn #buorgpermanent:not(:disabled):not(.disabled):active,
.gm-style .gm-style-iw > button.focus #buorgpermanent:not(:disabled):not(.disabled):active,
.focus#buorgul #buorgpermanent:not(:disabled):not(.disabled):active,
.focus#buorgpermanent #buorgpermanent:not(:disabled):not(.disabled):active,
.focus#buorgig #buorgpermanent:not(:disabled):not(.disabled):active, .btn.focus #buorgig:not(:disabled):not(.disabled):active, .focus.ratings-and-reviews-btn #buorgig:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.bv_button_buttonFull #buorgig:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit #buorgig:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn #buorgig:not(:disabled):not(.disabled):active, .gm-style .gm-style-iw > button.focus #buorgig:not(:disabled):not(.disabled):active, .focus#buorgul #buorgig:not(:disabled):not(.disabled):active,
.focus#buorgpermanent #buorgig:not(:disabled):not(.disabled):active, .focus#buorgig #buorgig:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .ratings-and-reviews-btn:not(:disabled):not(.disabled).active, .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled).active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled).active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled).active, .gm-style .gm-style-iw > button:not(:disabled):not(.disabled).active, #buorgul:not(:disabled):not(.disabled).active,
#buorgpermanent:not(:disabled):not(.disabled).active, #buorgig:not(:disabled):not(.disabled).active, .show > .btn.dropdown-toggle, .show > .dropdown-toggle.ratings-and-reviews-btn, .bv_modal_outer_content .show > .dropdown-toggle.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .show > button.dropdown-toggle.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .show > .dropdown-toggle.bv-content-btn, .gm-style .gm-style-iw.show > button.dropdown-toggle, .show > .dropdown-toggle#buorgul,
.show > .dropdown-toggle#buorgpermanent, .show > .dropdown-toggle#buorgig {
  transition: background 0.2s 0.25s, box-shadow 0.2s 0.25s ease-out, opacity 0.3s;
}
.btn:hover::before, .ratings-and-reviews-btn:hover::before, .bv_modal_outer_content .bv_button_buttonFull:hover::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:hover::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:hover::before, .gm-style .gm-style-iw > button:hover::before, #buorgul:hover::before,
#buorgpermanent:hover::before, #buorgig:hover::before, .btn:focus::before, .ratings-and-reviews-btn:focus::before, .bv_modal_outer_content .bv_button_buttonFull:focus::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:focus::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:focus::before, .gm-style .gm-style-iw > button:focus::before, #buorgul:focus::before,
#buorgpermanent:focus::before, #buorgig:focus::before, .btn.focus .btn:not(:disabled):not(.disabled):active::before, .focus.ratings-and-reviews-btn .btn:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus.bv_button_buttonFull .btn:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .btn:not(:disabled):not(.disabled):active::before, .btn.focus .ratings-and-reviews-btn:not(:disabled):not(.disabled):active::before, .btn.focus .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .btn.focus .bv_button_buttonFull:not(:disabled):not(.disabled):active::before,
.btn.focus .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn.focus button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before, .focus.ratings-and-reviews-btn .ratings-and-reviews-btn:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus.bv_button_buttonFull .ratings-and-reviews-btn:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .ratings-and-reviews-btn:not(:disabled):not(.disabled):active::before, .focus.ratings-and-reviews-btn .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus.ratings-and-reviews-btn .bv_button_buttonFull:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus.bv_button_buttonFull .bv_button_buttonFull:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .bv_button_buttonFull:not(:disabled):not(.disabled):active::before,
.focus.ratings-and-reviews-btn .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus.ratings-and-reviews-btn button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .focus.bv_button_buttonFull .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus.bv_button_buttonFull button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .btn:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .ratings-and-reviews-btn:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .bv_button_buttonFull:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before, .btn.focus .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active::before, .focus.ratings-and-reviews-btn .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus.bv_button_buttonFull .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn.focus .bv-content-btn:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.ratings-and-reviews-btn .bv-content-btn:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv_modal_outer_content .focus.bv_button_buttonFull .bv-content-btn:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv_button_buttonFull .bv-content-btn:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .bv-content-btn:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item button.focus.bv-submission-button-submit .bv-content-btn:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .bv-content-btn:not(:disabled):not(.disabled):active::before, .gm-style .gm-style-iw > button.focus .btn:not(:disabled):not(.disabled):active::before, .gm-style .gm-style-iw > button.focus .ratings-and-reviews-btn:not(:disabled):not(.disabled):active::before, .gm-style .gm-style-iw > button.focus .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .gm-style .gm-style-iw > button.focus .bv_button_buttonFull:not(:disabled):not(.disabled):active::before,
.gm-style .gm-style-iw > button.focus .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style .gm-style-iw > button.focus button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before, .gm-style .gm-style-iw > button.focus .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style .gm-style-iw > button.focus .bv-content-btn:not(:disabled):not(.disabled):active::before, .btn.focus .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .focus.ratings-and-reviews-btn .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus.bv_button_buttonFull .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .gm-style .btn.focus .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .gm-style .focus.ratings-and-reviews-btn .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .gm-style .bv_modal_outer_content .focus.bv_button_buttonFull .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .gm-style .focus.bv_button_buttonFull .gm-style-iw > button:not(:disabled):not(.disabled):active::before,
.gm-style .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .gm-style-iw > button:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style button.focus.bv-submission-button-submit .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .gm-style .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style .focus.bv-content-btn .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .gm-style .gm-style-iw > button.focus .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .focus#buorgul .btn:not(:disabled):not(.disabled):active::before, .focus#buorgul .ratings-and-reviews-btn:not(:disabled):not(.disabled):active::before, .focus#buorgul .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus#buorgul .bv_button_buttonFull:not(:disabled):not(.disabled):active::before,
.focus#buorgul .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus#buorgul button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before, .focus#buorgul .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus#buorgul .bv-content-btn:not(:disabled):not(.disabled):active::before, .focus#buorgul .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .gm-style .focus#buorgul .gm-style-iw > button:not(:disabled):not(.disabled):active::before,
.focus#buorgpermanent .btn:not(:disabled):not(.disabled):active::before,
.focus#buorgpermanent .ratings-and-reviews-btn:not(:disabled):not(.disabled):active::before,
.focus#buorgpermanent .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .focus#buorgpermanent .bv_button_buttonFull:not(:disabled):not(.disabled):active::before,
.focus#buorgpermanent .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus#buorgpermanent button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.focus#buorgpermanent .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active::before,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus#buorgpermanent .bv-content-btn:not(:disabled):not(.disabled):active::before,
.focus#buorgpermanent .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active::before,
.gm-style .focus#buorgpermanent .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .focus#buorgig .btn:not(:disabled):not(.disabled):active::before, .focus#buorgig .ratings-and-reviews-btn:not(:disabled):not(.disabled):active::before, .focus#buorgig .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus#buorgig .bv_button_buttonFull:not(:disabled):not(.disabled):active::before,
.focus#buorgig .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus#buorgig button.bv-submission-button-submit:not(:disabled):not(.disabled):active::before, .focus#buorgig .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus#buorgig .bv-content-btn:not(:disabled):not(.disabled):active::before, .focus#buorgig .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .gm-style .focus#buorgig .gm-style-iw > button:not(:disabled):not(.disabled):active::before, .btn.focus #buorgul:not(:disabled):not(.disabled):active::before, .focus.ratings-and-reviews-btn #buorgul:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus.bv_button_buttonFull #buorgul:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit #buorgul:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn #buorgul:not(:disabled):not(.disabled):active::before, .gm-style .gm-style-iw > button.focus #buorgul:not(:disabled):not(.disabled):active::before, .focus#buorgul #buorgul:not(:disabled):not(.disabled):active::before,
.focus#buorgpermanent #buorgul:not(:disabled):not(.disabled):active::before, .focus#buorgig #buorgul:not(:disabled):not(.disabled):active::before,
.btn.focus #buorgpermanent:not(:disabled):not(.disabled):active::before,
.focus.ratings-and-reviews-btn #buorgpermanent:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .focus.bv_button_buttonFull #buorgpermanent:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit #buorgpermanent:not(:disabled):not(.disabled):active::before,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn #buorgpermanent:not(:disabled):not(.disabled):active::before,
.gm-style .gm-style-iw > button.focus #buorgpermanent:not(:disabled):not(.disabled):active::before,
.focus#buorgul #buorgpermanent:not(:disabled):not(.disabled):active::before,
.focus#buorgpermanent #buorgpermanent:not(:disabled):not(.disabled):active::before,
.focus#buorgig #buorgpermanent:not(:disabled):not(.disabled):active::before, .btn.focus #buorgig:not(:disabled):not(.disabled):active::before, .focus.ratings-and-reviews-btn #buorgig:not(:disabled):not(.disabled):active::before, .bv_modal_outer_content .focus.bv_button_buttonFull #buorgig:not(:disabled):not(.disabled):active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit #buorgig:not(:disabled):not(.disabled):active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .focus.bv-content-btn #buorgig:not(:disabled):not(.disabled):active::before, .gm-style .gm-style-iw > button.focus #buorgig:not(:disabled):not(.disabled):active::before, .focus#buorgul #buorgig:not(:disabled):not(.disabled):active::before,
.focus#buorgpermanent #buorgig:not(:disabled):not(.disabled):active::before, .focus#buorgig #buorgig:not(:disabled):not(.disabled):active::before, .btn:not(:disabled):not(.disabled).active::before, .ratings-and-reviews-btn:not(:disabled):not(.disabled).active::before, .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled).active::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled).active::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:not(:disabled):not(.disabled).active::before, .gm-style .gm-style-iw > button:not(:disabled):not(.disabled).active::before, #buorgul:not(:disabled):not(.disabled).active::before,
#buorgpermanent:not(:disabled):not(.disabled).active::before, #buorgig:not(:disabled):not(.disabled).active::before, .show > .btn.dropdown-toggle::before, .show > .dropdown-toggle.ratings-and-reviews-btn::before, .bv_modal_outer_content .show > .dropdown-toggle.bv_button_buttonFull::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .show > button.dropdown-toggle.bv-submission-button-submit::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .show > .dropdown-toggle.bv-content-btn::before, .gm-style .gm-style-iw.show > button.dropdown-toggle::before, .show > .dropdown-toggle#buorgul::before,
.show > .dropdown-toggle#buorgpermanent::before, .show > .dropdown-toggle#buorgig::before {
  transform: translate3d(0, 0, 0) skew(-30deg);
}
.btn.disabled, .disabled.ratings-and-reviews-btn, .bv_modal_outer_content .disabled.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.disabled.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .disabled.bv-content-btn, .gm-style .gm-style-iw > button.disabled, .disabled#buorgul,
.disabled#buorgpermanent, .disabled#buorgig, .btn:disabled, .ratings-and-reviews-btn:disabled, .bv_modal_outer_content .bv_button_buttonFull:disabled,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:disabled, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:disabled, .gm-style .gm-style-iw > button:disabled, #buorgul:disabled,
#buorgpermanent:disabled, #buorgig:disabled {
  cursor: not-allowed;
  transition: background 0.2s, box-shadow 0.2s ease-out, color 0.2s, opacity 0.3s;
}
.btn.disabled::before, .disabled.ratings-and-reviews-btn::before, .bv_modal_outer_content .disabled.bv_button_buttonFull::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.disabled.bv-submission-button-submit::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .disabled.bv-content-btn::before, .gm-style .gm-style-iw > button.disabled::before, .disabled#buorgul::before,
.disabled#buorgpermanent::before, .disabled#buorgig::before, .btn:disabled::before, .ratings-and-reviews-btn:disabled::before, .bv_modal_outer_content .bv_button_buttonFull:disabled::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:disabled::before, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:disabled::before, .gm-style .gm-style-iw > button:disabled::before, #buorgul:disabled::before,
#buorgpermanent:disabled::before, #buorgig:disabled::before {
  transform: translate3d(-100%, 0, 0) skew(-30deg);
}
.btn .nbw-icon, .ratings-and-reviews-btn .nbw-icon, .bv_modal_outer_content .bv_button_buttonFull .nbw-icon,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit .nbw-icon, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn .nbw-icon, .gm-style .gm-style-iw > button .nbw-icon, #buorgul .nbw-icon,
#buorgpermanent .nbw-icon, #buorgig .nbw-icon {
  vertical-align: bottom;
  font-size: inherit;
  line-height: inherit;
}

.btn-light, .gm-style .gm-style-iw > button {
  background-color: #fffdfb;
  color: #34303d;
}
.btn-light::before, .gm-style .gm-style-iw > button::before {
  background-color: #00d1d2;
}
.btn-light:hover, .gm-style .gm-style-iw > button:hover, .btn-light:focus, .gm-style .gm-style-iw > button:focus, .btn-light.focus, .gm-style .gm-style-iw > button.focus {
  background-color: transparent;
  color: #34303d;
}
.btn-light:focus, .gm-style .gm-style-iw > button:focus, .btn-light.focus, .gm-style .gm-style-iw > button.focus {
  box-shadow: 0 0 0 0 rgba(0, 209, 210, 0.5);
}
.btn-light.disabled, .gm-style .gm-style-iw > button.disabled, .btn-light:disabled, .gm-style .gm-style-iw > button:disabled {
  background-color: #7b7881;
  color: #e1e0e2;
}
.btn-light:not(:disabled):not(.disabled):active, .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .gm-style .gm-style-iw > button:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle, .gm-style .gm-style-iw.show > button.dropdown-toggle {
  background-color: transparent;
  color: #34303d;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .gm-style .gm-style-iw > button:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .gm-style .gm-style-iw > button:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus, .gm-style .gm-style-iw.show > button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 209, 210, 0.5);
}

.btn-outline-light {
  box-shadow: inset 0 0 0 2px #fffdfb;
  background-color: transparent;
  color: #fffdfb;
}
.btn-outline-light::before {
  background-color: #00d1d2;
}
.btn-outline-light:hover {
  box-shadow: inset 0 0 0 2px transparent;
  color: #34303d;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(0, 209, 210, 0.5);
  color: #34303d;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  box-shadow: inset 0 0 0 2px transparent;
  background-color: #7b7881;
  color: #e1e0e2;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  box-shadow: inset 0 0 0 2px transparent;
  color: #34303d;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(0, 209, 210, 0.5);
}

.btn-light-alt {
  background-color: #fffdfb;
  color: #34303d;
}
.btn-light-alt::before {
  background-color: #34303d;
}
.btn-light-alt:hover, .btn-light-alt:focus, .btn-light-alt.focus {
  background-color: transparent;
  color: #fffdfb;
}
.btn-light-alt:focus, .btn-light-alt.focus {
  box-shadow: 0 0 0 0 rgba(52, 48, 61, 0.5);
}
.btn-light-alt.disabled, .btn-light-alt:disabled {
  background-color: #7b7881;
  color: #e1e0e2;
}
.btn-light-alt:not(:disabled):not(.disabled):active, .btn-light-alt:not(:disabled):not(.disabled).active, .show > .btn-light-alt.dropdown-toggle {
  background-color: transparent;
  color: #fffdfb;
}
.btn-light-alt:not(:disabled):not(.disabled):active:focus, .btn-light-alt:not(:disabled):not(.disabled).active:focus, .show > .btn-light-alt.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(52, 48, 61, 0.5);
}

.btn-outline-light-alt {
  box-shadow: inset 0 0 0 2px #fffdfb;
  background-color: transparent;
  color: #fffdfb;
}
.btn-outline-light-alt::before {
  background-color: #34303d;
}
.btn-outline-light-alt:hover {
  box-shadow: inset 0 0 0 2px transparent;
  color: #fffdfb;
}
.btn-outline-light-alt:focus, .btn-outline-light-alt.focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(52, 48, 61, 0.5);
  color: #fffdfb;
}
.btn-outline-light-alt.disabled, .btn-outline-light-alt:disabled {
  box-shadow: inset 0 0 0 2px transparent;
  background-color: #7b7881;
  color: #e1e0e2;
}
.btn-outline-light-alt:not(:disabled):not(.disabled):active, .btn-outline-light-alt:not(:disabled):not(.disabled).active, .show > .btn-outline-light-alt.dropdown-toggle {
  box-shadow: inset 0 0 0 2px transparent;
  color: #fffdfb;
}
.btn-outline-light-alt:not(:disabled):not(.disabled):active:focus, .btn-outline-light-alt:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light-alt.dropdown-toggle:focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(52, 48, 61, 0.5);
}

.btn-dark {
  background-color: #34303d;
  color: #fffdfb;
}
.btn-dark::before {
  background-color: #00d1d2;
}
.btn-dark:hover, .btn-dark:focus, .btn-dark.focus {
  background-color: transparent;
  color: #34303d;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0 rgba(0, 209, 210, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  background-color: #b8b7bb;
  color: #7b7881;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  background-color: transparent;
  color: #34303d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 209, 210, 0.5);
}

.btn-outline-dark {
  box-shadow: inset 0 0 0 2px #34303d;
  background-color: transparent;
  color: #34303d;
}
.btn-outline-dark::before {
  background-color: #00d1d2;
}
.btn-outline-dark:hover {
  box-shadow: inset 0 0 0 2px transparent;
  color: #34303d;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(0, 209, 210, 0.5);
  color: #34303d;
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  box-shadow: inset 0 0 0 2px transparent;
  background-color: #b8b7bb;
  color: #7b7881;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  box-shadow: inset 0 0 0 2px transparent;
  color: #34303d;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(0, 209, 210, 0.5);
}

.btn-dark-alt {
  background-color: #34303d;
  color: #fffdfb;
}
.btn-dark-alt::before {
  background-color: #fffdfb;
}
.btn-dark-alt:hover, .btn-dark-alt:focus, .btn-dark-alt.focus {
  background-color: transparent;
  color: #34303d;
}
.btn-dark-alt:focus, .btn-dark-alt.focus {
  box-shadow: 0 0 0 0 rgba(255, 253, 251, 0.5);
}
.btn-dark-alt.disabled, .btn-dark-alt:disabled {
  background-color: #b8b7bb;
  color: #7b7881;
}
.btn-dark-alt:not(:disabled):not(.disabled):active, .btn-dark-alt:not(:disabled):not(.disabled).active, .show > .btn-dark-alt.dropdown-toggle {
  background-color: transparent;
  color: #34303d;
}
.btn-dark-alt:not(:disabled):not(.disabled):active:focus, .btn-dark-alt:not(:disabled):not(.disabled).active:focus, .show > .btn-dark-alt.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 253, 251, 0.5);
}

.btn-outline-dark-alt {
  box-shadow: inset 0 0 0 2px #34303d;
  background-color: transparent;
  color: #34303d;
}
.btn-outline-dark-alt::before {
  background-color: #fffdfb;
}
.btn-outline-dark-alt:hover {
  box-shadow: inset 0 0 0 2px transparent;
  color: #34303d;
}
.btn-outline-dark-alt:focus, .btn-outline-dark-alt.focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(255, 253, 251, 0.5);
  color: #34303d;
}
.btn-outline-dark-alt.disabled, .btn-outline-dark-alt:disabled {
  box-shadow: inset 0 0 0 2px transparent;
  background-color: #b8b7bb;
  color: #7b7881;
}
.btn-outline-dark-alt:not(:disabled):not(.disabled):active, .btn-outline-dark-alt:not(:disabled):not(.disabled).active, .show > .btn-outline-dark-alt.dropdown-toggle {
  box-shadow: inset 0 0 0 2px transparent;
  color: #34303d;
}
.btn-outline-dark-alt:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-alt:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark-alt.dropdown-toggle:focus {
  box-shadow: inset 0 0 0 2px transparent, 0 0 0 0 rgba(255, 253, 251, 0.5);
}

.btn-arrowed-left:not(.loading)::after,
.btn-arrowed-right:not(.loading)::after {
  display: inline-block;
  line-height: inherit;
  transition: transform 0.25s ease-in-out;
}

.btn-arrowed-left:not(.loading):not(:disabled):not(.disabled):hover::after, .btn-arrowed-left:not(.loading):not(:disabled):not(.disabled):focus::after {
  transform: translateX(-0.5em);
}
.btn-arrowed-left:not(.loading)::after {
  float: left;
  padding-right: 0.5em;
  content: "\e909";
}

.btn-arrowed-right:not(.loading):not(:disabled):not(.disabled):hover::after, .btn-arrowed-right:not(.loading):not(:disabled):not(.disabled):focus::after {
  transform: translateX(0.5em);
}
.btn-arrowed-right:not(.loading)::after {
  float: right;
  padding-left: 0.5em;
  content: "\e90a";
}

.btn-extra-small {
  border-radius: 0.75rem;
  padding: 0.1875rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
}
.btn-extra-small.btn-circular {
  width: 1.5rem;
}

.btn-small {
  border-radius: 1rem;
  padding: 0.25rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
}
.btn-small.btn-circular {
  width: 2rem;
}

.btn-default {
  border-radius: 1.375rem;
  padding: 0.625rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
}
.btn-default.btn-circular {
  width: 2.75rem;
}

.btn-large {
  border-radius: 1.6875rem;
  padding: 0.9375rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
}
.btn-large.btn-circular {
  width: 3.375rem;
}

@media (min-width: 576px) {
  .btn-sm-extra-small {
    border-radius: 0.75rem;
    padding: 0.1875rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  .btn-sm-extra-small.btn-circular {
    width: 1.5rem;
  }
  .btn-sm-small {
    border-radius: 1rem;
    padding: 0.25rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-sm-small.btn-circular {
    width: 2rem;
  }
  .btn-sm-default {
    border-radius: 1.375rem;
    padding: 0.625rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-sm-default.btn-circular {
    width: 2.75rem;
  }
  .btn-sm-large {
    border-radius: 1.6875rem;
    padding: 0.9375rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-sm-large.btn-circular {
    width: 3.375rem;
  }
}
@media (min-width: 768px) {
  .btn-md-extra-small {
    border-radius: 0.75rem;
    padding: 0.1875rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  .btn-md-extra-small.btn-circular {
    width: 1.5rem;
  }
  .btn-md-small {
    border-radius: 1rem;
    padding: 0.25rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-md-small.btn-circular {
    width: 2rem;
  }
  .btn-md-default {
    border-radius: 1.375rem;
    padding: 0.625rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-md-default.btn-circular {
    width: 2.75rem;
  }
  .btn-md-large {
    border-radius: 1.6875rem;
    padding: 0.9375rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-md-large.btn-circular {
    width: 3.375rem;
  }
}
@media (min-width: 992px) {
  .btn-lg-extra-small {
    border-radius: 0.75rem;
    padding: 0.1875rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  .btn-lg-extra-small.btn-circular {
    width: 1.5rem;
  }
  .btn-lg-small {
    border-radius: 1rem;
    padding: 0.25rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-lg-small.btn-circular {
    width: 2rem;
  }
  .btn-lg-default {
    border-radius: 1.375rem;
    padding: 0.625rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-lg-default.btn-circular {
    width: 2.75rem;
  }
  .btn-lg-large {
    border-radius: 1.6875rem;
    padding: 0.9375rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-lg-large.btn-circular {
    width: 3.375rem;
  }
}
@media (min-width: 1200px) {
  .btn-xl-extra-small {
    border-radius: 0.75rem;
    padding: 0.1875rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  .btn-xl-extra-small.btn-circular {
    width: 1.5rem;
  }
  .btn-xl-small {
    border-radius: 1rem;
    padding: 0.25rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-xl-small.btn-circular {
    width: 2rem;
  }
  .btn-xl-default {
    border-radius: 1.375rem;
    padding: 0.625rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-xl-default.btn-circular {
    width: 2.75rem;
  }
  .btn-xl-large {
    border-radius: 1.6875rem;
    padding: 0.9375rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .btn-xl-large.btn-circular {
    width: 3.375rem;
  }
}
.btn-circular {
  padding-left: 0;
  padding-right: 0;
}

.btn-collapse-plus {
  height: 2.125rem;
  width: 2.125rem;
  border: 2px solid #34303d;
  color: #34303d;
  border-radius: 50%;
  position: relative;
  background: none;
  cursor: pointer;
}
.btn-collapse-plus::before, .btn-collapse-plus::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(0);
  width: 0.7rem;
  border-top: 2px solid;
}
.btn-collapse-plus::before {
  transition: transform 0.3s ease-in-out;
}
.btn-collapse-plus.active::before {
  transform: translate3d(-50%, -50%, 0) rotate(180deg);
}
.btn-collapse-plus::after {
  transform: translate3d(-50%, -50%, 0) rotate(90deg);
  transition: transform 0.5s ease-in-out;
}
.btn-collapse-plus.active::after {
  transform: translate3d(-50%, -50%, 0) rotate(360deg);
}
.btn-collapse-plus:focus-visible {
  outline-offset: 5px;
}

.btn-with-min-width {
  min-width: 13rem;
}

.circle-icon {
  display: inline-block;
  border-radius: 50%;
  background-color: #f6f4f0;
  text-align: center;
  line-height: 1;
  color: #34303d;
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
}
.circle-icon.font-size-body {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.circle-icon-small {
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 0.875rem;
}

.circle-icon-extra-small {
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
}

.collapsible-footer {
  transition: height 0.35s ease-out;
}
@media (min-width: 768px) {
  .collapsible-footer {
    display: block !important;
  }
}

@media (max-width: 767.98px) {
  .collapsible-footer-content {
    z-index: 1;
    overflow-y: auto;
    position: fixed;
    right: 0;
    bottom: 2.5625rem;
    left: 0;
    max-height: calc(100% - 6.0625rem);
    background-color: #fffdfb;
    font-size: 1rem;
  }
  .navbar-promo-active .collapsible-footer-content {
    max-height: calc(100% - 9.1875rem);
  }
}

.collapsible-footer-toggle {
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid #524f5a;
  background-color: #34303d;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: #fffdfb;
  cursor: pointer;
}
@media (min-width: 768px) {
  .collapsible-footer-toggle {
    display: none;
  }
}

.color-select {
  min-height: 1.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.color-select a {
  line-height: 1.125rem;
}
.color-select a:hover {
  text-decoration: none;
}
.color-select .color-select-item {
  position: relative;
  margin-right: 0.25rem;
  width: 1.125rem;
  height: 1.125rem;
}
@media (min-width: 992px) {
  .color-select .color-select-item {
    margin-right: 0.625rem;
  }
}
.color-select .color-select-item:last-child {
  margin-right: 0;
}
.color-select .color-select-item > label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.color-select .color-select-item > label::before {
  position: absolute;
  top: 50%;
  left: 0;
  border: 1px solid transparent;
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
  transform: translate3d(-50%, -50%, 0);
  transition: border 0.3s;
  content: "";
}
.color-select .color-select-item > label::after {
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 1;
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
  background: inherit;
  transform: translate(-50%, -50%);
  content: "";
}
.color-select .color-select-item > input {
  opacity: 0;
}
.color-select .color-select-item > input:focus-visible {
  outline-style: auto;
  outline-offset: 5px;
  outline-color: -webkit-focus-ring-color;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  width: 0.75rem;
  height: 0.75rem;
  transform: translate(-50%, -50%);
}
.color-select .color-select-item > input:checked ~ label::before {
  border-color: #34303d;
}
.color-select .color-select-item > input:not(:checked):hover ~ label::before {
  border-color: #b8b7bb;
}

.color-select-item-bordered > label::after {
  border: 1px solid #b8b7bb;
}

.color-select-large .color-select-item {
  width: 1.625rem;
  height: 1.625rem;
}
.color-select-large .color-select-item > input {
  opacity: 0;
}
.color-select-large .color-select-item > input:focus-visible {
  outline-offset: 6px;
  width: 1.15rem;
  height: 1.15rem;
}
.color-select-large .color-select-item > label::before {
  width: 1.625rem;
  height: 1.625rem;
}
.color-select-large .color-select-item > label::after {
  width: 1.25rem;
  height: 1.25rem;
}

.color-select-large-and-large-active .color-select-item {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}
.color-select-large-and-large-active .color-select-item > input {
  opacity: 0;
}
.color-select-large-and-large-active .color-select-item > input:focus-visible {
  outline-offset: 10px;
  width: 1.15rem;
  height: 1.15rem;
}
.color-select-large-and-large-active .color-select-item > label::before {
  width: 2rem;
  height: 2rem;
}
.color-select-large-and-large-active .color-select-item > label::after {
  width: 1.25rem;
  height: 1.25rem;
}

.color-select-extra-large .color-select-item {
  width: 2.375rem;
  height: 2.375rem;
}
.color-select-extra-large .color-select-item > label::before {
  width: 2.375rem;
  height: 2.375rem;
}
.color-select-extra-large .color-select-item > label::after {
  width: 1.875rem;
  height: 1.875rem;
}

.color-list {
  min-height: 0.875rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}
@media (min-width: 992px) {
  .color-list {
    min-height: 1.25rem;
  }
}
.color-list .color-list-item {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
}
.color-list .color-list-item + .color-list-item {
  margin-right: -0.25rem;
}
@media (min-width: 992px) {
  .color-list .color-list-item {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.color-list .color-list-item-bordered {
  border: 1px solid #b8b7bb;
}

.image-select {
  min-height: 1.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.image-select a {
  line-height: 4.5rem;
}
.image-select a:hover {
  text-decoration: none;
}
.image-select .image-select-item {
  position: relative;
  margin-right: 0.25rem;
  width: 2rem;
  height: 3.125rem;
  overflow: hidden;
}
@media (min-width: 992px) {
  .image-select .image-select-item {
    margin-right: 0.25rem;
    width: 3.25rem;
    height: 4.5rem;
  }
}
@media (min-width: 992px) {
  .image-select .image-select-item.image-select-item-small {
    margin-right: 0.25rem;
    width: 2rem;
    height: 3.125rem;
  }
  .image-select .image-select-item.image-select-item-small > label::before {
    width: 2rem;
    height: 3.125rem;
  }
  .image-select .image-select-item.image-select-item-small img {
    height: 2.25rem;
  }
}
.image-select .image-select-item:last-child {
  margin-right: 0;
}
.image-select .image-select-item > label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.image-select .image-select-item > label::before {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 2px solid transparent;
  border-radius: 4px;
  width: 2rem;
  height: 3.125rem;
  transform: translate3d(-50%, -50%, 0);
  transition: border 0.3s;
  content: "";
}
.image-select .image-select-item > label::after {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  border-radius: 4px;
  width: 0.75rem;
  height: 0.75rem;
  background: inherit;
  transform: translate(-50%, -50%);
  content: "";
}
@media (min-width: 992px) {
  .image-select .image-select-item > label::before {
    width: 3.25rem;
    height: 4.5rem;
  }
}
.image-select .image-select-item > input {
  opacity: 0;
}
.image-select .image-select-item > input:focus-visible {
  outline-style: auto;
  outline-offset: 10px;
  outline-color: -webkit-focus-ring-color;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  width: 0.3rem;
  height: 1.4rem;
  transform: translate(-50%, -50%);
}
@media (min-width: 992px) {
  .image-select .image-select-item > input:focus-visible {
    outline-offset: 15px;
    width: 0.9rem;
    height: 2.1rem;
  }
}
.image-select .image-select-item > input:checked ~ label::before {
  border-color: #34303d;
}
.image-select .image-select-item > input:not(:checked):hover ~ label::before {
  border-color: #b8b7bb;
}
.image-select .image-select-item img {
  height: 2.25rem;
  width: auto;
}
@media (min-width: 992px) {
  .image-select .image-select-item img {
    height: 3.25rem;
  }
}

.chip-group {
  overflow-x: auto;
  white-space: nowrap;
}
.chip-group .btn + .btn, .chip-group .ratings-and-reviews-btn + .btn, .chip-group .bv_modal_outer_content .bv_button_buttonFull + .btn, .bv_modal_outer_content .chip-group .bv_button_buttonFull + .btn,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .btn,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + .btn, .chip-group .btn + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content .btn + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .btn + .bv_button_buttonFull,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .btn + button.bv-submission-button-submit, .chip-group .ratings-and-reviews-btn + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content .bv_button_buttonFull + .ratings-and-reviews-btn, .bv_modal_outer_content .chip-group .bv_button_buttonFull + .ratings-and-reviews-btn,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .ratings-and-reviews-btn,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content .ratings-and-reviews-btn + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .ratings-and-reviews-btn + .bv_button_buttonFull, .chip-group .bv_modal_outer_content .bv_button_buttonFull + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .bv_button_buttonFull + .bv_button_buttonFull,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + .bv_button_buttonFull,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .ratings-and-reviews-btn + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .ratings-and-reviews-btn + button.bv-submission-button-submit,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv_button_buttonFull + button.bv-submission-button-submit,
.bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv_button_buttonFull + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .bv_button_buttonFull + button.bv-submission-button-submit,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + button.bv-submission-button-submit, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + .btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + .ratings-and-reviews-btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv_modal_outer_content .bv-content-btn + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + .bv_button_buttonFull,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-btn + button.bv-submission-button-submit,
.bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-btn + button.bv-submission-button-submit,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + button.bv-submission-button-submit,
.bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + .btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + .ratings-and-reviews-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv_modal_outer_content .bv-content-btn + .bv_button_buttonFull, .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-btn + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + button.bv-submission-button-submit, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn + .bv-content-btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .ratings-and-reviews-btn + .bv-content-btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv_modal_outer_content .bv_button_buttonFull + .bv-content-btn, .bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv_button_buttonFull + .bv-content-btn,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .bv-content-btn,
.bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .bv-content-btn,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item button.bv-submission-button-submit + .bv-content-btn,
.bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item button.bv-submission-button-submit + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .btn + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .ratings-and-reviews-btn + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv_modal_outer_content .bv_button_buttonFull + .bv-content-btn, .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv_button_buttonFull + .bv-content-btn,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .bv-content-btn,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group button.bv-submission-button-submit + .bv-content-btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + .bv-content-btn, .chip-group .gm-style .gm-style-iw > button + .btn, .chip-group .gm-style .gm-style-iw > button + .ratings-and-reviews-btn, .chip-group .gm-style .bv_modal_outer_content .gm-style-iw > button + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .gm-style .gm-style-iw > button + .bv_button_buttonFull,
.chip-group .gm-style .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style-iw > button + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .gm-style .gm-style-iw > button + button.bv-submission-button-submit, .chip-group .gm-style .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style-iw > button + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .gm-style .gm-style-iw > button + .bv-content-btn, .gm-style .chip-group .gm-style-iw > button + .btn, .gm-style .chip-group .gm-style-iw > button + .ratings-and-reviews-btn, .gm-style .chip-group .bv_modal_outer_content .gm-style-iw > button + .bv_button_buttonFull, .bv_modal_outer_content .gm-style .chip-group .gm-style-iw > button + .bv_button_buttonFull,
.gm-style .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style-iw > button + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style .chip-group .gm-style-iw > button + button.bv-submission-button-submit, .gm-style .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style-iw > button + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style .chip-group .gm-style-iw > button + .bv-content-btn, .chip-group .gm-style .gm-style-iw > .btn + button, .chip-group .gm-style .gm-style-iw > .ratings-and-reviews-btn + button, .chip-group .gm-style .bv_modal_outer_content .gm-style-iw > .bv_button_buttonFull + button, .bv_modal_outer_content .chip-group .gm-style .gm-style-iw > .bv_button_buttonFull + button,
.chip-group .gm-style .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style-iw > button.bv-submission-button-submit + button,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .gm-style .gm-style-iw > button.bv-submission-button-submit + button, .chip-group .gm-style .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style-iw > .bv-content-btn + button, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .gm-style .gm-style-iw > .bv-content-btn + button, .gm-style .chip-group .gm-style-iw > .btn + button, .gm-style .chip-group .gm-style-iw > .ratings-and-reviews-btn + button, .gm-style .chip-group .bv_modal_outer_content .gm-style-iw > .bv_button_buttonFull + button, .bv_modal_outer_content .gm-style .chip-group .gm-style-iw > .bv_button_buttonFull + button,
.gm-style .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style-iw > button.bv-submission-button-submit + button,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style .chip-group .gm-style-iw > button.bv-submission-button-submit + button, .gm-style .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style-iw > .bv-content-btn + button, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style .chip-group .gm-style-iw > .bv-content-btn + button, .chip-group .gm-style .gm-style-iw > button + button, .gm-style .chip-group .gm-style-iw > button + button, .chip-group #buorgul + .btn, .chip-group #buorgul + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content #buorgul + .bv_button_buttonFull, .bv_modal_outer_content .chip-group #buorgul + .bv_button_buttonFull,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions #buorgul + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group #buorgul + button.bv-submission-button-submit, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item #buorgul + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group #buorgul + .bv-content-btn, .chip-group .gm-style .gm-style-iw > #buorgul + button, .gm-style .chip-group .gm-style-iw > #buorgul + button,
.chip-group #buorgpermanent + .btn,
.chip-group #buorgpermanent + .ratings-and-reviews-btn,
.chip-group .bv_modal_outer_content #buorgpermanent + .bv_button_buttonFull,
.bv_modal_outer_content .chip-group #buorgpermanent + .bv_button_buttonFull,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions #buorgpermanent + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group #buorgpermanent + button.bv-submission-button-submit,
.chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item #buorgpermanent + .bv-content-btn,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group #buorgpermanent + .bv-content-btn,
.chip-group .gm-style .gm-style-iw > #buorgpermanent + button,
.gm-style .chip-group .gm-style-iw > #buorgpermanent + button, .chip-group #buorgig + .btn, .chip-group #buorgig + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content #buorgig + .bv_button_buttonFull, .bv_modal_outer_content .chip-group #buorgig + .bv_button_buttonFull,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions #buorgig + button.bv-submission-button-submit,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group #buorgig + button.bv-submission-button-submit, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item #buorgig + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group #buorgig + .bv-content-btn, .chip-group .gm-style .gm-style-iw > #buorgig + button, .gm-style .chip-group .gm-style-iw > #buorgig + button, .chip-group .btn + #buorgul, .chip-group .ratings-and-reviews-btn + #buorgul, .chip-group .bv_modal_outer_content .bv_button_buttonFull + #buorgul, .bv_modal_outer_content .chip-group .bv_button_buttonFull + #buorgul,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + #buorgul,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + #buorgul, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + #buorgul, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + #buorgul, .chip-group .gm-style .gm-style-iw > button + #buorgul, .gm-style .chip-group .gm-style-iw > button + #buorgul, .chip-group #buorgul + #buorgul,
.chip-group #buorgpermanent + #buorgul, .chip-group #buorgig + #buorgul,
.chip-group .btn + #buorgpermanent,
.chip-group .ratings-and-reviews-btn + #buorgpermanent,
.chip-group .bv_modal_outer_content .bv_button_buttonFull + #buorgpermanent,
.bv_modal_outer_content .chip-group .bv_button_buttonFull + #buorgpermanent,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + #buorgpermanent,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + #buorgpermanent,
.chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + #buorgpermanent,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + #buorgpermanent,
.chip-group .gm-style .gm-style-iw > button + #buorgpermanent,
.gm-style .chip-group .gm-style-iw > button + #buorgpermanent,
.chip-group #buorgul + #buorgpermanent,
.chip-group #buorgpermanent + #buorgpermanent,
.chip-group #buorgig + #buorgpermanent, .chip-group .btn + #buorgig, .chip-group .ratings-and-reviews-btn + #buorgig, .chip-group .bv_modal_outer_content .bv_button_buttonFull + #buorgig, .bv_modal_outer_content .chip-group .bv_button_buttonFull + #buorgig,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + #buorgig,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + #buorgig, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + #buorgig, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + #buorgig, .chip-group .gm-style .gm-style-iw > button + #buorgig, .gm-style .chip-group .gm-style-iw > button + #buorgig, .chip-group #buorgul + #buorgig,
.chip-group #buorgpermanent + #buorgig, .chip-group #buorgig + #buorgig {
  margin-left: 1rem;
}
@media (min-width: 992px) {
  .chip-group .btn + .btn, .chip-group .ratings-and-reviews-btn + .btn, .chip-group .bv_modal_outer_content .bv_button_buttonFull + .btn, .bv_modal_outer_content .chip-group .bv_button_buttonFull + .btn,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .btn,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + .btn, .chip-group .btn + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content .btn + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .btn + .bv_button_buttonFull,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .btn + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .btn + button.bv-submission-button-submit, .chip-group .ratings-and-reviews-btn + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content .bv_button_buttonFull + .ratings-and-reviews-btn, .bv_modal_outer_content .chip-group .bv_button_buttonFull + .ratings-and-reviews-btn,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .ratings-and-reviews-btn,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content .ratings-and-reviews-btn + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .ratings-and-reviews-btn + .bv_button_buttonFull, .chip-group .bv_modal_outer_content .bv_button_buttonFull + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .bv_button_buttonFull + .bv_button_buttonFull,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .bv_button_buttonFull,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + .bv_button_buttonFull,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .ratings-and-reviews-btn + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .ratings-and-reviews-btn + button.bv-submission-button-submit,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv_button_buttonFull + button.bv-submission-button-submit,
  .bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv_button_buttonFull + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .bv_button_buttonFull + button.bv-submission-button-submit,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + button.bv-submission-button-submit, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + .btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + .ratings-and-reviews-btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv_modal_outer_content .bv-content-btn + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + .bv_button_buttonFull,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-btn + button.bv-submission-button-submit,
  .bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-btn + button.bv-submission-button-submit,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + button.bv-submission-button-submit,
  .bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + button.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + .btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + .ratings-and-reviews-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv_modal_outer_content .bv-content-btn + .bv_button_buttonFull, .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + .bv_button_buttonFull,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-btn + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + button.bv-submission-button-submit, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .btn + .bv-content-btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .ratings-and-reviews-btn + .bv-content-btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv_modal_outer_content .bv_button_buttonFull + .bv-content-btn, .bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv_button_buttonFull + .bv-content-btn,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .bv-content-btn,
  .bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .bv-content-btn,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item button.bv-submission-button-submit + .bv-content-btn,
  .bv_modal_outer_content .chip-group .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item button.bv-submission-button-submit + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .btn + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .ratings-and-reviews-btn + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv_modal_outer_content .bv_button_buttonFull + .bv-content-btn, .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv_button_buttonFull + .bv-content-btn,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + .bv-content-btn,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group button.bv-submission-button-submit + .bv-content-btn, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + .bv-content-btn, .chip-group .gm-style .gm-style-iw > button + .btn, .chip-group .gm-style .gm-style-iw > button + .ratings-and-reviews-btn, .chip-group .gm-style .bv_modal_outer_content .gm-style-iw > button + .bv_button_buttonFull, .bv_modal_outer_content .chip-group .gm-style .gm-style-iw > button + .bv_button_buttonFull,
  .chip-group .gm-style .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style-iw > button + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .gm-style .gm-style-iw > button + button.bv-submission-button-submit, .chip-group .gm-style .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style-iw > button + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .gm-style .gm-style-iw > button + .bv-content-btn, .gm-style .chip-group .gm-style-iw > button + .btn, .gm-style .chip-group .gm-style-iw > button + .ratings-and-reviews-btn, .gm-style .chip-group .bv_modal_outer_content .gm-style-iw > button + .bv_button_buttonFull, .bv_modal_outer_content .gm-style .chip-group .gm-style-iw > button + .bv_button_buttonFull,
  .gm-style .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style-iw > button + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style .chip-group .gm-style-iw > button + button.bv-submission-button-submit, .gm-style .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style-iw > button + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style .chip-group .gm-style-iw > button + .bv-content-btn, .chip-group .gm-style .gm-style-iw > .btn + button, .chip-group .gm-style .gm-style-iw > .ratings-and-reviews-btn + button, .chip-group .gm-style .bv_modal_outer_content .gm-style-iw > .bv_button_buttonFull + button, .bv_modal_outer_content .chip-group .gm-style .gm-style-iw > .bv_button_buttonFull + button,
  .chip-group .gm-style .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style-iw > button.bv-submission-button-submit + button,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group .gm-style .gm-style-iw > button.bv-submission-button-submit + button, .chip-group .gm-style .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style-iw > .bv-content-btn + button, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .gm-style .gm-style-iw > .bv-content-btn + button, .gm-style .chip-group .gm-style-iw > .btn + button, .gm-style .chip-group .gm-style-iw > .ratings-and-reviews-btn + button, .gm-style .chip-group .bv_modal_outer_content .gm-style-iw > .bv_button_buttonFull + button, .bv_modal_outer_content .gm-style .chip-group .gm-style-iw > .bv_button_buttonFull + button,
  .gm-style .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style-iw > button.bv-submission-button-submit + button,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .gm-style .chip-group .gm-style-iw > button.bv-submission-button-submit + button, .gm-style .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style-iw > .bv-content-btn + button, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .gm-style .chip-group .gm-style-iw > .bv-content-btn + button, .chip-group .gm-style .gm-style-iw > button + button, .gm-style .chip-group .gm-style-iw > button + button, .chip-group #buorgul + .btn, .chip-group #buorgul + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content #buorgul + .bv_button_buttonFull, .bv_modal_outer_content .chip-group #buorgul + .bv_button_buttonFull,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions #buorgul + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group #buorgul + button.bv-submission-button-submit, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item #buorgul + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group #buorgul + .bv-content-btn, .chip-group .gm-style .gm-style-iw > #buorgul + button, .gm-style .chip-group .gm-style-iw > #buorgul + button,
  .chip-group #buorgpermanent + .btn,
  .chip-group #buorgpermanent + .ratings-and-reviews-btn,
  .chip-group .bv_modal_outer_content #buorgpermanent + .bv_button_buttonFull,
  .bv_modal_outer_content .chip-group #buorgpermanent + .bv_button_buttonFull,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions #buorgpermanent + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group #buorgpermanent + button.bv-submission-button-submit,
  .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item #buorgpermanent + .bv-content-btn,
  .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group #buorgpermanent + .bv-content-btn,
  .chip-group .gm-style .gm-style-iw > #buorgpermanent + button,
  .gm-style .chip-group .gm-style-iw > #buorgpermanent + button, .chip-group #buorgig + .btn, .chip-group #buorgig + .ratings-and-reviews-btn, .chip-group .bv_modal_outer_content #buorgig + .bv_button_buttonFull, .bv_modal_outer_content .chip-group #buorgig + .bv_button_buttonFull,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions #buorgig + button.bv-submission-button-submit,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group #buorgig + button.bv-submission-button-submit, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item #buorgig + .bv-content-btn, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group #buorgig + .bv-content-btn, .chip-group .gm-style .gm-style-iw > #buorgig + button, .gm-style .chip-group .gm-style-iw > #buorgig + button, .chip-group .btn + #buorgul, .chip-group .ratings-and-reviews-btn + #buorgul, .chip-group .bv_modal_outer_content .bv_button_buttonFull + #buorgul, .bv_modal_outer_content .chip-group .bv_button_buttonFull + #buorgul,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + #buorgul,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + #buorgul, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + #buorgul, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + #buorgul, .chip-group .gm-style .gm-style-iw > button + #buorgul, .gm-style .chip-group .gm-style-iw > button + #buorgul, .chip-group #buorgul + #buorgul,
  .chip-group #buorgpermanent + #buorgul, .chip-group #buorgig + #buorgul,
  .chip-group .btn + #buorgpermanent,
  .chip-group .ratings-and-reviews-btn + #buorgpermanent,
  .chip-group .bv_modal_outer_content .bv_button_buttonFull + #buorgpermanent,
  .bv_modal_outer_content .chip-group .bv_button_buttonFull + #buorgpermanent,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + #buorgpermanent,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + #buorgpermanent,
  .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + #buorgpermanent,
  .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + #buorgpermanent,
  .chip-group .gm-style .gm-style-iw > button + #buorgpermanent,
  .gm-style .chip-group .gm-style-iw > button + #buorgpermanent,
  .chip-group #buorgul + #buorgpermanent,
  .chip-group #buorgpermanent + #buorgpermanent,
  .chip-group #buorgig + #buorgpermanent, .chip-group .btn + #buorgig, .chip-group .ratings-and-reviews-btn + #buorgig, .chip-group .bv_modal_outer_content .bv_button_buttonFull + #buorgig, .bv_modal_outer_content .chip-group .bv_button_buttonFull + #buorgig,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit + #buorgig,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit + #buorgig, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn + #buorgig, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn + #buorgig, .chip-group .gm-style .gm-style-iw > button + #buorgig, .gm-style .chip-group .gm-style-iw > button + #buorgig, .chip-group #buorgul + #buorgig,
  .chip-group #buorgpermanent + #buorgig, .chip-group #buorgig + #buorgig {
    margin-left: 1.5rem;
  }
}
.chip-group .btn:first-child, .chip-group .ratings-and-reviews-btn:first-child, .chip-group .bv_modal_outer_content .bv_button_buttonFull:first-child, .bv_modal_outer_content .chip-group .bv_button_buttonFull:first-child,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:first-child,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit:first-child, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:first-child, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn:first-child, .chip-group .gm-style .gm-style-iw > button:first-child, .gm-style .chip-group .gm-style-iw > button:first-child, .chip-group #buorgul:first-child,
.chip-group #buorgpermanent:first-child, .chip-group #buorgig:first-child {
  margin-left: 2rem;
}
@media (min-width: 992px) {
  .chip-group .btn:first-child, .chip-group .ratings-and-reviews-btn:first-child, .chip-group .bv_modal_outer_content .bv_button_buttonFull:first-child, .bv_modal_outer_content .chip-group .bv_button_buttonFull:first-child,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:first-child,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit:first-child, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:first-child, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn:first-child, .chip-group .gm-style .gm-style-iw > button:first-child, .gm-style .chip-group .gm-style-iw > button:first-child, .chip-group #buorgul:first-child,
  .chip-group #buorgpermanent:first-child, .chip-group #buorgig:first-child {
    margin-left: 0;
  }
}
.chip-group .btn:last-child, .chip-group .ratings-and-reviews-btn:last-child, .chip-group .bv_modal_outer_content .bv_button_buttonFull:last-child, .bv_modal_outer_content .chip-group .bv_button_buttonFull:last-child,
.chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:last-child,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit:last-child, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:last-child, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn:last-child, .chip-group .gm-style .gm-style-iw > button:last-child, .gm-style .chip-group .gm-style-iw > button:last-child, .chip-group #buorgul:last-child,
.chip-group #buorgpermanent:last-child, .chip-group #buorgig:last-child {
  margin-right: 2rem;
}
@media (min-width: 992px) {
  .chip-group .btn:last-child, .chip-group .ratings-and-reviews-btn:last-child, .chip-group .bv_modal_outer_content .bv_button_buttonFull:last-child, .bv_modal_outer_content .chip-group .bv_button_buttonFull:last-child,
  .chip-group .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:last-child,
  .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .chip-group button.bv-submission-button-submit:last-child, .chip-group .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn:last-child, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .chip-group .bv-content-btn:last-child, .chip-group .gm-style .gm-style-iw > button:last-child, .gm-style .chip-group .gm-style-iw > button:last-child, .chip-group #buorgul:last-child,
  .chip-group #buorgpermanent:last-child, .chip-group #buorgig:last-child {
    margin-right: 0;
  }
}

.checkout-message {
  width: 100%;
  background-color: #00d1d2;
  padding: 1rem 2rem;
}
@media (min-width: 992px) {
  .checkout-message {
    border-radius: 0.1875rem;
    padding: 1rem 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .checkout-message.collapsed .checkout-message-close {
    pointer-events: none;
  }
  .checkout-message.collapsed .checkout-message-close > .nbw-icon::before {
    content: "\e907";
  }
}

.checkout-message-floating {
  z-index: 1040;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  max-height: calc(100vh - 3.5rem);
  max-height: calc(var(--vh, 1vh) * 100 - 3.5rem);
}
@media (min-width: 1200px) {
  .checkout-message-floating {
    max-height: calc(100vh - 4.75rem);
    max-height: calc(var(--vh, 1vh) * 100 - 4.75rem);
  }
}
.navbar-promo-active .checkout-message-floating {
  max-height: calc(100vh - 6.625rem);
  max-height: calc(var(--vh, 1vh) * 100 - 6.625rem);
}
@media (min-width: 1200px) {
  .navbar-promo-active .checkout-message-floating {
    max-height: calc(100vh - 7.875rem);
    max-height: calc(var(--vh, 1vh) * 100 - 7.875rem);
  }
}
@media (min-width: 992px) {
  .checkout-message-floating {
    left: auto;
    right: 1.25rem;
    bottom: 1.25rem;
    box-shadow: 0 0.5rem 0.875rem rgba(0, 0, 0, 0.05);
    width: 22.5rem;
  }
}

.checkout-message-product-list {
  border-radius: 0.375rem;
  background-color: #fffdfb;
}

.checkout-message-product-image {
  width: 5.25rem;
  min-width: 5.25rem;
}

.checkout-message.leave-to {
  opacity: 0;
  transform: translate3d(0, 5rem, 0);
  transition: transform 0.2s ease-in, opacity 0.2s linear;
}
@media (min-width: 992px) {
  .checkout-message.leave-to {
    transform: scale3d(0.9, 0.9, 1);
    transform-origin: top right;
  }
}

.checkout-message-floating {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.3s ease-out, opacity 0.3s linear;
}
.checkout-message-floating.enter-to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 992px) {
  .checkout-message-floating {
    transform: scale3d(1, 1, 1) translate3d(0, 3rem, 0);
  }
}

.my-device-image {
  width: 5.5rem;
  height: 5.5rem;
}
@media (min-width: 992px) {
  .my-device-image {
    width: 6.875rem;
    height: 6.875rem;
  }
}

.form-bg-light .form-control,
.form-bg-light .select-clear,
.form-bg-light .form-floating > label,
.form-bg-light .select2-container--nbw .select2-selection,
.form-bg-light .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-light .custom-control-label::before {
  background-color: #fffdfb !important;
}

.form-bg-gray-extra-light .form-control,
.form-bg-gray-extra-light .select-clear,
.form-bg-gray-extra-light .form-floating > label,
.form-bg-gray-extra-light .select2-container--nbw .select2-selection,
.form-bg-gray-extra-light .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-gray-extra-light .custom-control-label::before {
  background-color: #f6f4f0 !important;
}

.form-bg-gray-light .form-control,
.form-bg-gray-light .select-clear,
.form-bg-gray-light .form-floating > label,
.form-bg-gray-light .select2-container--nbw .select2-selection,
.form-bg-gray-light .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-gray-light .custom-control-label::before {
  background-color: #e1e0e2 !important;
}

.form-bg-gray-light-lighter .form-control,
.form-bg-gray-light-lighter .select-clear,
.form-bg-gray-light-lighter .form-floating > label,
.form-bg-gray-light-lighter .select2-container--nbw .select2-selection,
.form-bg-gray-light-lighter .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-gray-light-lighter .custom-control-label::before {
  background-color: #EAE8E5 !important;
}

.form-bg-gray .form-control,
.form-bg-gray .select-clear,
.form-bg-gray .form-floating > label,
.form-bg-gray .select2-container--nbw .select2-selection,
.form-bg-gray .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-gray .custom-control-label::before {
  background-color: #b8b7bb !important;
}

.form-bg-gray-dark .form-control,
.form-bg-gray-dark .select-clear,
.form-bg-gray-dark .form-floating > label,
.form-bg-gray-dark .select2-container--nbw .select2-selection,
.form-bg-gray-dark .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-gray-dark .custom-control-label::before {
  background-color: #7b7881 !important;
}

.form-bg-gray-extra-dark .form-control,
.form-bg-gray-extra-dark .select-clear,
.form-bg-gray-extra-dark .form-floating > label,
.form-bg-gray-extra-dark .select2-container--nbw .select2-selection,
.form-bg-gray-extra-dark .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-gray-extra-dark .custom-control-label::before {
  background-color: #524f5a !important;
}

.form-bg-dark .form-control,
.form-bg-dark .select-clear,
.form-bg-dark .form-floating > label,
.form-bg-dark .select2-container--nbw .select2-selection,
.form-bg-dark .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-dark .custom-control-label::before {
  background-color: #34303d !important;
}

.form-bg-extra-dark .form-control,
.form-bg-extra-dark .select-clear,
.form-bg-extra-dark .form-floating > label,
.form-bg-extra-dark .select2-container--nbw .select2-selection,
.form-bg-extra-dark .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-extra-dark .custom-control-label::before {
  background-color: #27242D !important;
}

.form-bg-accent .form-control,
.form-bg-accent .select-clear,
.form-bg-accent .form-floating > label,
.form-bg-accent .select2-container--nbw .select2-selection,
.form-bg-accent .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-accent .custom-control-label::before {
  background-color: #00d1d2 !important;
}

.form-bg-light-accent .form-control,
.form-bg-light-accent .select-clear,
.form-bg-light-accent .form-floating > label,
.form-bg-light-accent .select2-container--nbw .select2-selection,
.form-bg-light-accent .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-light-accent .custom-control-label::before {
  background-color: #65D6C5 !important;
}

.form-bg-amber .form-control,
.form-bg-amber .select-clear,
.form-bg-amber .form-floating > label,
.form-bg-amber .select2-container--nbw .select2-selection,
.form-bg-amber .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-amber .custom-control-label::before {
  background-color: #e27d34 !important;
}

.form-bg-red .form-control,
.form-bg-red .select-clear,
.form-bg-red .form-floating > label,
.form-bg-red .select2-container--nbw .select2-selection,
.form-bg-red .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-red .custom-control-label::before {
  background-color: #db3826 !important;
}

.form-bg-green .form-control,
.form-bg-green .select-clear,
.form-bg-green .form-floating > label,
.form-bg-green .select2-container--nbw .select2-selection,
.form-bg-green .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-green .custom-control-label::before {
  background-color: #57760c !important;
}

.form-bg-blue .form-control,
.form-bg-blue .select-clear,
.form-bg-blue .form-floating > label,
.form-bg-blue .select2-container--nbw .select2-selection,
.form-bg-blue .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-blue .custom-control-label::before {
  background-color: #2980b5 !important;
}

.form-bg-blue-dark .form-control,
.form-bg-blue-dark .select-clear,
.form-bg-blue-dark .form-floating > label,
.form-bg-blue-dark .select2-container--nbw .select2-selection,
.form-bg-blue-dark .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-blue-dark .custom-control-label::before {
  background-color: #27529C !important;
}

.form-bg-deep-red .form-control,
.form-bg-deep-red .select-clear,
.form-bg-deep-red .form-floating > label,
.form-bg-deep-red .select2-container--nbw .select2-selection,
.form-bg-deep-red .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-deep-red .custom-control-label::before {
  background-color: #a22f26 !important;
}

.form-bg-error-red .form-control,
.form-bg-error-red .select-clear,
.form-bg-error-red .form-floating > label,
.form-bg-error-red .select2-container--nbw .select2-selection,
.form-bg-error-red .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-error-red .custom-control-label::before {
  background-color: #c73636 !important;
}

.form-bg-yellow .form-control,
.form-bg-yellow .select-clear,
.form-bg-yellow .form-floating > label,
.form-bg-yellow .select2-container--nbw .select2-selection,
.form-bg-yellow .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-yellow .custom-control-label::before {
  background-color: #ffd040 !important;
}

.form-bg-iluma-turqoise .form-control,
.form-bg-iluma-turqoise .select-clear,
.form-bg-iluma-turqoise .form-floating > label,
.form-bg-iluma-turqoise .select2-container--nbw .select2-selection,
.form-bg-iluma-turqoise .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-iluma-turqoise .custom-control-label::before {
  background-color: #1accbb !important;
}

.form-bg-iluma-turqoise-alt .form-control,
.form-bg-iluma-turqoise-alt .select-clear,
.form-bg-iluma-turqoise-alt .form-floating > label,
.form-bg-iluma-turqoise-alt .select2-container--nbw .select2-selection,
.form-bg-iluma-turqoise-alt .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-iluma-turqoise-alt .custom-control-label::before {
  background-color: #23DAC5 !important;
}

.form-bg-iluma-yellow .form-control,
.form-bg-iluma-yellow .select-clear,
.form-bg-iluma-yellow .form-floating > label,
.form-bg-iluma-yellow .select2-container--nbw .select2-selection,
.form-bg-iluma-yellow .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-iluma-yellow .custom-control-label::before {
  background-color: #f9af1b !important;
}

.form-bg-iluma-yellow-alt .form-control,
.form-bg-iluma-yellow-alt .select-clear,
.form-bg-iluma-yellow-alt .form-floating > label,
.form-bg-iluma-yellow-alt .select2-container--nbw .select2-selection,
.form-bg-iluma-yellow-alt .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-iluma-yellow-alt .custom-control-label::before {
  background-color: #F8B811 !important;
}

.form-bg-iluma-light-blue .form-control,
.form-bg-iluma-light-blue .select-clear,
.form-bg-iluma-light-blue .form-floating > label,
.form-bg-iluma-light-blue .select2-container--nbw .select2-selection,
.form-bg-iluma-light-blue .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-iluma-light-blue .custom-control-label::before {
  background-color: #00C0FF !important;
}

.form-bg-iluma-we-blue .form-control,
.form-bg-iluma-we-blue .select-clear,
.form-bg-iluma-we-blue .form-floating > label,
.form-bg-iluma-we-blue .select2-container--nbw .select2-selection,
.form-bg-iluma-we-blue .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-iluma-we-blue .custom-control-label::before {
  background-color: #102645 !important;
}

.form-bg-lil-solid-ez-blue .form-control,
.form-bg-lil-solid-ez-blue .select-clear,
.form-bg-lil-solid-ez-blue .form-floating > label,
.form-bg-lil-solid-ez-blue .select2-container--nbw .select2-selection,
.form-bg-lil-solid-ez-blue .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-lil-solid-ez-blue .custom-control-label::before {
  background-color: #1255A2 !important;
}

.form-bg-veev-blue-dark .form-control,
.form-bg-veev-blue-dark .select-clear,
.form-bg-veev-blue-dark .form-floating > label,
.form-bg-veev-blue-dark .select2-container--nbw .select2-selection,
.form-bg-veev-blue-dark .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-veev-blue-dark .custom-control-label::before {
  background-color: #017398 !important;
}

.form-bg-veev-purple-blue .form-control,
.form-bg-veev-purple-blue .select-clear,
.form-bg-veev-purple-blue .form-floating > label,
.form-bg-veev-purple-blue .select2-container--nbw .select2-selection,
.form-bg-veev-purple-blue .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-veev-purple-blue .custom-control-label::before {
  background-color: #16115B !important;
}

.form-bg-iluma-neon .form-control,
.form-bg-iluma-neon .select-clear,
.form-bg-iluma-neon .form-floating > label,
.form-bg-iluma-neon .select2-container--nbw .select2-selection,
.form-bg-iluma-neon .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-iluma-neon .custom-control-label::before {
  background-color: #C400A1 !important;
}

.form-bg-aoki-black .form-control,
.form-bg-aoki-black .select-clear,
.form-bg-aoki-black .form-floating > label,
.form-bg-aoki-black .select2-container--nbw .select2-selection,
.form-bg-aoki-black .select2-container--nbw .select2-dropdown .select2-search__field,
.form-bg-aoki-black .custom-control-label::before {
  background-color: #010715 !important;
}

.form-control {
  height: auto;
}
.form-control:not(:placeholder-shown) {
  color: #34303d;
  border-color: #34303d;
}
.form-control:disabled, .form-control[readonly] {
  border-color: #b8b7bb;
  color: #b8b7bb;
}
.form-control:disabled::placeholder, .form-control[readonly]::placeholder {
  color: #b8b7bb;
}
.form-control:disabled ~ label, .form-control[readonly] ~ label {
  color: #b8b7bb;
}

.is-empty {
  color: #7b7881 !important;
}
.is-empty:not(.is-invalid) {
  border-color: #99979e;
}
.is-empty:not(.is-invalid) ~ label {
  color: #7b7881 !important;
}

[class*=form-control-prepend],
.form-control-append {
  position: absolute;
  top: 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #34303d;
}
[class*=form-control-prepend].form-control-icon,
.form-control-append.form-control-icon {
  top: 0.625rem;
  font-size: 1.25rem;
}

.form-control-prepend {
  left: 1rem;
}
.form-control-prepend ~ .form-control {
  padding-left: 3.5rem;
}
.form-control-prepend.form-control-icon ~ .form-control {
  padding-left: 2.75rem;
}
.form-control-prepend.form-control-prepend-1 ~ .form-control {
  padding-left: 2rem;
}
.form-control-prepend.form-control-prepend-2 ~ .form-control {
  padding-left: 2.5rem;
}
.form-control-prepend.form-control-prepend-3 ~ .form-control {
  padding-left: 3rem;
}
.form-control-prepend.form-control-prepend-4 ~ .form-control {
  padding-left: 3.5rem;
}

.form-control-append {
  right: 1rem;
}
.form-control-append ~ .form-control {
  padding-right: 2rem;
}
.form-control-append.form-control-icon ~ .form-control {
  padding-right: 2.75rem;
}

label:not(.custom-control-label):not(.btn):not(.ratings-and-reviews-btn):not(#buorgul):not(#buorgpermanent):not(#buorgig).required::after {
  content: "*";
}
label:not(.custom-control-label):not(.btn):not(.ratings-and-reviews-btn):not(#buorgul):not(#buorgpermanent):not(#buorgig).disabled {
  color: #b8b7bb;
}

legend.disabled {
  color: #b8b7bb;
}

.code-input {
  display: inline-block;
}
.code-input .code-input-field {
  border-width: 2px;
  font-size: 1.5rem;
  width: 2.2rem;
  height: 2.75rem;
  text-align: center;
  padding: 0;
}
@media (min-width: 576px) {
  .code-input .code-input-field {
    width: 2.5rem;
    height: 3rem;
  }
}
.code-input .code-input-field.is-invalid, .code-input .code-input-field:focus, .code-input .code-input-field.is-invalid:focus {
  box-shadow: none;
}
.code-input .code-input-field:not(:first-child) {
  margin-left: 0.75rem;
}
@media (min-width: 576px) {
  .code-input .code-input-field:not(:first-child) {
    margin-left: 1rem;
  }
}
.code-input + .is-invalid.invalid-feedback {
  display: block !important;
}

.custom-control-input:not(:disabled) ~ .custom-control-label {
  cursor: pointer;
}
.custom-control-input:focus ~ .custom-control-label::before {
  border-color: #34303d;
}

.custom-checkbox .custom-control-input {
  opacity: 1;
}
.custom-checkbox .custom-control-label::after {
  background-size: 58%;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #34303d;
  background-color: #34303d !important;
}
.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #b8b7bb;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #b8b7bb !important;
  border-color: #b8b7bb;
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #b8b7bb !important;
  border-color: #b8b7bb;
}

.custom-checkbox-circle .custom-control-input:focus ~ .custom-control-label::before {
  border-radius: 50%;
  outline-style: solid;
  outline-color: -webkit-focus-ring-color;
  outline-width: 2px;
  outline-offset: 2px;
}
.custom-checkbox-circle .custom-control-label::before {
  border: 1px solid #b8b7bb;
}
.custom-checkbox-circle .custom-control-label::after {
  background-size: 58%;
}
.custom-checkbox-circle .custom-control-label::before,
.custom-checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.custom-checkbox-circle .custom-control-input:not(:disabled):not(:checked):active ~ .custom-control-label::before, .custom-checkbox-circle .custom-control-input:not(:disabled):not(:checked):focus ~ .custom-control-label::before {
  border: 1px solid #b8b7bb;
}
.custom-checkbox-circle .custom-control-input:checked ~ .custom-control-label::before {
  border: #00d1d2;
  color: #34303d;
  border-color: #34303d;
  background-color: #00d1d2;
}
.custom-checkbox-circle .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='17.55' height='13.225' viewBox='0 0 17.55 13.225'%3e%3cpath fill='%2334303d' d='m5.225 13.225c-.2 0-.4-.1-.5-.2l-4.5-4.7c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4 4.2 10.9-11.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-11.5 11.7c-.2.2-.4.2-.6.2z'/%3e%3c/svg%3e");
}
.custom-checkbox-circle .custom-control-input:checked:disabled ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='17.55' height='13.225' viewBox='0 0 17.55 13.225'%3e%3cpath fill='%23fffdfb' d='m5.225 13.225c-.2 0-.4-.1-.5-.2l-4.5-4.7c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4 4.2 10.9-11.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-11.5 11.7c-.2.2-.4.2-.6.2z'/%3e%3c/svg%3e");
}
.custom-checkbox-circle .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #b8b7bb;
}
.custom-checkbox-circle .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #b8b7bb;
  border-color: #b8b7bb;
}
.custom-checkbox-circle .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #b8b7bb;
  border-color: #b8b7bb;
}

.custom-radio {
  margin-bottom: 1.5rem;
}
.custom-radio .custom-control-input {
  opacity: 1;
}
.custom-radio .custom-control-label::after {
  opacity: 0;
  background-size: 50% 50%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%2334303d'/%3e%3c/svg%3e");
  transform: scale(2);
  transition: 0.3s opacity ease-in-out, 0.3s transform ease-in-out;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
  transform: scale(1);
}
.custom-radio .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #b8b7bb;
}
.custom-radio .custom-control-input:disabled ~ .custom-control-label .custom-radio-image {
  opacity: 0.5;
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23b8b7bb'/%3e%3c/svg%3e");
}
.custom-radio .custom-radio-image {
  max-height: 1.5rem;
  width: auto;
}
.custom-radio .custom-radio-bigger-image {
  max-height: 2.25rem;
  width: auto;
}
.custom-radio.custom-radio-center-bubble .custom-control-label::after {
  top: 50%;
  transform: scale(2) translate3d(0, -50%, 0);
}
.custom-radio.custom-radio-center-bubble .custom-control-input:checked ~ .custom-control-label::after {
  top: 50%;
  transform: scale(1) translate3d(0, -50%, 0);
}
.custom-radio.custom-radio-center-bubble .custom-control-label::before {
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.custom-select:disabled::placeholder, .custom-select[readonly]::placeholder {
  color: #b8b7bb;
}
.custom-select:disabled ~ label, .custom-select[readonly] ~ label {
  color: #b8b7bb;
}

@media (min-width: 992px) {
  .custom-dropdown {
    position: relative;
  }
}
.custom-dropdown input[type=radio] + label {
  border: 1px #e1e0e2 solid;
  border-radius: 3px;
  cursor: pointer;
}
.custom-dropdown input[type=radio]:checked + label {
  border: 1px #27242D solid;
}
.custom-dropdown input[type=radio]:disabled + label {
  border: none;
  cursor: not-allowed;
  background-color: #f6f4f0;
  color: #b8b7bb;
}
.custom-dropdown .custom-dropdown-button {
  padding: 0.75rem 1.5rem 0.75rem 0.5rem;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (min-width: 992px) {
  .custom-dropdown .custom-dropdown-button {
    padding: 0.75rem 1.25rem;
  }
}
.custom-dropdown .custom-dropdown-button::after {
  position: absolute;
  right: 0;
  margin-right: 0.5rem;
}
@media (min-width: 992px) {
  .custom-dropdown .custom-dropdown-button::after {
    margin-right: 1rem;
  }
}
.custom-dropdown .custom-dropdown-button.custom-dropdown-button-border {
  border: 1px #b8b7bb solid;
}
.custom-dropdown .custom-dropdown-button.dropdown-toggle::after {
  transform: scaleY(1) translate3d(0, -50%, 0);
  top: 50%;
}
@media (min-width: 992px) {
  .custom-dropdown .custom-dropdown-button.dropdown-toggle::after {
    transform: scaleY(1);
    top: initial;
  }
}
.custom-dropdown .custom-dropdown-button.active.dropdown-toggle::after {
  transform: scaleY(-1) translate3d(0, -50%, 0);
  top: 50%;
}
@media (min-width: 992px) {
  .custom-dropdown .custom-dropdown-button.active.dropdown-toggle::after {
    transform: scaleY(-1);
    top: initial;
  }
}
.custom-dropdown .custom-dropdown-menu {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  box-shadow: 0 0 20px -10px #34303d;
  border-color: transparent;
  z-index: 1000;
  border-radius: 3px;
  width: 100%;
  visibility: hidden;
}
@media (min-width: 992px) {
  .custom-dropdown .custom-dropdown-menu {
    height: auto;
    position: absolute;
    min-height: auto;
  }
}
.custom-dropdown .custom-dropdown-menu.active {
  visibility: visible;
}
.custom-dropdown .custom-dropdown-menu .dropdown-toggle::after {
  transform: scaleY(1);
}
.custom-dropdown .custom-dropdown-menu.active .dropdown-toggle::after {
  transform: scaleY(-1);
}
.custom-dropdown .custom-dropdown-menu-list {
  max-height: 66vh;
  overflow-y: auto;
}
.custom-dropdown .custom-dropdown-input:focus-visible + label,
.custom-dropdown .custom-dropdown-input:focus + label {
  outline: -webkit-focus-ring-color auto 1px;
}

.custom-dropdown-column {
  position: relative;
}

.hidden-column {
  display: none !important;
}

.select2-container--nbw {
  width: 100% !important;
}
.select2-container--nbw .select2-selection {
  border: 1px solid #99979e;
  border-radius: 3px;
  background-color: #fffdfb;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #34303d;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, color 0.15s ease-in-out;
}
.select2-container--nbw .select2-selection:focus {
  outline: 0;
}
.select2-container--nbw .select2-selection--single {
  height: 3rem;
}
.select2-container--nbw .select2-selection--single .select2-selection__placeholder {
  color: #7b7881;
}
.select2-container--nbw .select2-selection--single .select2-selection__rendered {
  padding: 0.6875rem 2.75rem 0.6875rem 1rem;
}
.select2-container--nbw .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 0.5625rem;
  right: 1rem;
}
.select2-container--nbw .select2-selection--single .select2-selection__arrow b {
  display: block;
  line-height: inherit;
  transform: scaleY(1);
  transition: transform 0.2s linear;
  font-size: 1.25rem;
}
.select2-container--nbw .select2-selection--single .select2-selection__arrow b:before {
  content: "\e908";
}
.select2-container--nbw.select2-container--focus .select2-selection, .select2-container--nbw.select2-container--open .select2-selection {
  color: #34303d;
  background-color: #fffdfb;
  border-color: #34303d;
  box-shadow: inset 0 0 0 1px #34303d;
}
.select2-container--nbw.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: scaleY(-1);
}
.select2-container--nbw.select2-container--disabled {
  pointer-events: none;
}
.select2-container--nbw.select2-container--disabled ~ label {
  color: #b8b7bb;
}
.select2-container--nbw.select2-container--disabled .select2-selection {
  background-color: #fffdfb;
  border-color: #b8b7bb;
  box-shadow: none;
}
.select2-container--nbw.select2-container--disabled .select2-search__field {
  background-color: transparent;
}
.select2-container--nbw.select2-container--disabled .select2-selection--single .select2-selection__rendered {
  color: #b8b7bb;
}
.select2-container--nbw.select2-container--disabled .select2-selection--single .select2-selection__placeholder {
  color: #b8b7bb;
}
.select2-container--nbw .select2-search {
  width: 100%;
}
.select2-container--nbw .select2-dropdown {
  z-index: 1000;
  border: 2px solid #34303d;
  border-radius: 3px;
  background-color: #fffdfb;
}
.select2-container--nbw .select2-dropdown.select2-dropdown--below {
  border-top-color: #fffdfb;
}
.select2-container--nbw .select2-dropdown.select2-dropdown--below .select2-search--dropdown {
  top: -3rem;
}
.select2-container--nbw .select2-dropdown.select2-dropdown--above {
  border-bottom-color: #fffdfb;
}
.select2-container--nbw .select2-dropdown.select2-dropdown--above .select2-search--dropdown {
  bottom: -3rem;
}
.select2-container--nbw .select2-dropdown .select2-search--dropdown {
  position: absolute;
  padding: 0.625rem 2.6875rem 0.625rem 0.9375rem;
}
.select2-container--nbw .select2-dropdown .select2-search__field {
  display: block;
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
  background-color: #fffdfb;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #34303d;
}
.select2-container--nbw .select2-dropdown .select2-search__field:focus {
  outline: 0;
}
.modal-open .select2-container--nbw .select2-dropdown {
  z-index: 1060;
}
.select2-container--nbw .select2-results__option--highlighted, .select2-container--nbw .select2-results__option--highlighted.select2-results__option[aria-selected=true] {
  background-color: #f6f4f0;
}
.select2-container--nbw .select2-results__option {
  padding: 0.6875rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #34303d;
}
.select2-container--nbw .select2-results > .select2-results__options {
  max-height: 15.8125rem;
  overflow-y: auto;
}

.form-floating {
  position: relative;
}
.form-floating > label {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0.75rem;
  transform: translate3d(0, 0.75rem, 0);
  display: block;
  margin-bottom: 0;
  border-radius: 3px;
  max-width: calc(100% - 1.5rem);
  background-color: #fffdfb;
  padding: 0 0.25rem;
  line-height: 1.5;
  font-size: 1rem;
  color: #7b7881;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  cursor: text;
  transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out, color 0.15s ease-in-out;
}
.form-floating > .form-control:not(.show-placeholder):not(.select2-hidden-accessible)::placeholder,
.form-floating > .select2-hidden-accessible:not(.show-placeholder) ~ .select2-container:not(.select2-container--selected) .select2-selection--single .select2-selection__rendered {
  opacity: 0;
  color: transparent;
}
.form-floating > .form-control:focus .select2-selection,
.form-floating > .form-control:not(:disabled):not([readonly]):not(:placeholder-shown):not(.select2-hidden-accessible) .select2-selection,
.form-floating > .select2-container--focus .select2-selection,
.form-floating > .select2-container--open .select2-selection,
.form-floating > .select2-container--selected .select2-selection {
  border-color: #34303d;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:disabled):not([readonly]):not(:placeholder-shown):not(.select2-hidden-accessible) ~ label,
.form-floating > .select2-container--focus ~ label,
.form-floating > .select2-container--open ~ label,
.form-floating > .select2-container--selected ~ label {
  color: #34303d;
}
.form-floating > .show-placeholder ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown):not(.select2-hidden-accessible) ~ label,
.form-floating > .select2-container--focus ~ label,
.form-floating > .select2-container--open ~ label,
.form-floating > .select2-container--selected ~ label {
  transform: translate3d(0, -0.75em, 0);
  font-size: 0.75rem;
}
.form-floating > .select2-container ~ label {
  max-width: calc(100% - 4rem);
}
.form-floating > label:has(~ .form-control),
.form-floating > label:has(~ .input-group),
.form-floating > label:has(~ .select2-container),
.form-floating > label:has(~ .select2-selection) {
  z-index: 4;
}
.form-floating > label:has(~ .form-control:focus),
.form-floating > label:has(~ .form-control:not(:disabled):not([readonly]):not(:placeholder-shown):not(.select2-hidden-accessible)),
.form-floating > label:has(~ .select2-container--focus),
.form-floating > label:has(~ .select2-container--open),
.form-floating > label:has(~ .select2-container--selected),
.form-floating > label:has(~ .select2-selection) {
  color: #34303d;
}
.form-floating > label:has(~ .show-placeholder),
.form-floating > label:has(~ .form-control:focus),
.form-floating > label:has(~ .form-control:not(:placeholder-shown):not(.select2-hidden-accessible)),
.form-floating > label:has(~ .select2-container--focus),
.form-floating > label:has(~ .select2-container--open),
.form-floating > label:has(~ .select2-container--selected) {
  transform: translate3d(0, -0.75em, 0);
  font-size: 0.75rem;
}
.form-floating > label:has(~ .select2-container) {
  max-width: calc(100% - 4rem);
}

@supports (-ms-ime-align: auto) {
  .form-floating > label {
    display: none;
  }
  .form-floating > input::-ms-input-placeholder {
    color: #7b7881;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-floating > label {
    display: none;
  }
  .form-floating > input:-ms-input-placeholder {
    opacity: 1;
    color: #7b7881;
  }
}
.floating-label {
  font-size: 0.75rem;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #c73636;
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

.form-control.is-invalid {
  border-color: #c73636;
  box-shadow: inset 0 0 0 1px #c73636;
}
.form-control.is-invalid:not(.code-input-field) {
  padding-right: 2.75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3e%3cpath d='m10 20c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm-1-16v8c0 .552.448 1 1 1s1-.448 1-1v-8c0-.552-.448-1-1-1s-1 .448-1 1zm1 11c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z' fill='%23c73636'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.25rem;
}
.form-control.is-invalid:not(.code-input-field):has(~ .form-control-append:not(.d-none)) {
  padding-right: 3.75rem;
  background-position: right 2rem center;
}
.form-control.is-invalid:not(.code-input-field):has(~ .form-control-append.form-control-icon:not(.d-none)) {
  padding-right: 4.5rem;
  background-position: right 2.75rem center;
}
.form-control.is-invalid:not(:placeholder-shown) {
  border-color: #c73636;
}
.form-control.is-invalid:focus {
  box-shadow: 0 0 0 1px #c73636, inset 0 0 0 1px #c73636;
}

textarea.form-control.is-invalid {
  background-position: top 0.71875rem right 0.71875rem;
}

.custom-select.is-invalid {
  border-color: #c73636;
  box-shadow: inset 0 0 0 1px #c73636;
  padding-right: 4.5rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath d='m7.914 10.929-4.721-4.721c-.257-.257-.257-.686 0-.944.258-.257.687-.257.944 0l3.863 3.863 3.863-3.863c.257-.257.686-.257.944 0 .257.258.257.687 0 .944z' fill='%2334303d'/%3e%3c/svg%3e") no-repeat right 1rem center/1.25rem, #fffdfb url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3e%3cpath d='m10 20c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm-1-16v8c0 .552.448 1 1 1s1-.448 1-1v-8c0-.552-.448-1-1-1s-1 .448-1 1zm1 11c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z' fill='%23c73636'/%3e%3c/svg%3e") center right 2.75rem/1.25rem no-repeat;
}
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 1px #c73636, inset 0 0 0 1px #c73636;
}

.custom-radio .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #c73636;
  background-color: #fffdfb;
}
.custom-control-input.is-invalid:not(:checked) ~ .custom-control-label::before {
  border-color: #c73636;
}

.select2-hidden-accessible.is-invalid ~ .select2-container--nbw .select2-selection {
  border-color: #c73636;
  box-shadow: inset 0 0 0 1px #c73636;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3e%3cpath d='m10 20c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm-1-16v8c0 .552.448 1 1 1s1-.448 1-1v-8c0-.552-.448-1-1-1s-1 .448-1 1zm1 11c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z' fill='%23c73636'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 2.75rem center;
  background-size: 1.25rem;
}
.select2-hidden-accessible.is-invalid ~ .select2-container--nbw .select2-selection .select2-selection__rendered {
  padding-right: 4.5rem;
}
.select2-hidden-accessible.is-invalid ~ .select2-container--nbw.select2-container--focus .select2-selection, .select2-hidden-accessible.is-invalid ~ .select2-container--nbw.select2-container--open .select2-selection {
  box-shadow: 0 0 0 1px #c73636, inset 0 0 0 1px #c73636;
}

.form-floating > .show-placeholder.is-invalid ~ label,
.form-floating > .form-control:focus.is-invalid ~ label,
.form-floating > .form-control:not(:disabled):not([readonly]):not(:placeholder-shown):not(.select2-hidden-accessible).is-invalid ~ label {
  color: #c73636;
}
.form-floating > .select2-hidden-accessible:not(:disabled):not([readonly]).is-invalid ~ .select2-container--focus ~ label,
.form-floating > .select2-hidden-accessible:not(:disabled):not([readonly]).is-invalid ~ .select2-container--open ~ label,
.form-floating > .select2-hidden-accessible:not(:disabled):not([readonly]).is-invalid ~ .select2-container--selected ~ label {
  color: #c73636;
}
.form-floating .select2-container--selected + .select-checkmark {
  opacity: 1;
}
.form-floating .select2-container--selected + .select-checkmark::before {
  transform: translate(-50%, -50%) skew(-30deg);
  background-color: #57760c;
}
.form-floating .select2-container--selected + .select-checkmark::after {
  clip-path: inset(0 0 0 0);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(-272 -20) translate(272 20)'%3e%3cg filter=''%3e%3cpath d='M0 0H24V24H0z'/%3e%3cg fill-rule='nonzero' stroke='%23FFFDFB' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3e%3cpath d='M0 4.223L2.708 7.058 9.578 0' transform='translate(7 8)'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
.form-floating .select2-container--selected + .select-clear {
  opacity: 1;
}

.select-clear {
  position: absolute;
  z-index: 1;
  top: 28%;
  right: 1rem;
  width: 1.25rem;
  font-size: 0.875rem;
  background-color: #fff;
  opacity: 0;
  cursor: pointer;
  padding-left: 0.2rem;
}

.select-checkmark {
  position: absolute;
  z-index: 1;
  top: 25%;
  right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: transparent;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}
.select-checkmark:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-150%, -50%) skew(-30deg);
  transition: transform 0.5s ease-out;
  height: 120%;
  width: 150%;
}
.select-checkmark:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  clip-path: inset(0 100% 0 0);
  background: transparent no-repeat center center;
  background-size: 100%;
  transition: clip-path 0.5s ease-in-out 0.2s;
}

.gm-style .gm-style-iw {
  font-size: 1rem;
  font-family: "IQOS", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}
.gm-style .gm-style-iw.gm-style-iw-c {
  box-shadow: none !important;
  max-height: none !important;
  max-width: none !important;
  border-radius: 0;
  background: none;
  pointer-events: none;
  margin-left: 12.5rem !important;
  padding: 0 12.5rem !important;
}
.gm-style .gm-style-iw.gm-style-iw-c > * {
  pointer-events: auto;
}
.gm-style .gm-style-iw .gm-style-iw-d {
  overflow: auto !important;
  max-height: calc(100vh - 4.75rem - 160px) !important;
  max-width: none !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2);
  padding: 1.25rem 1.25rem;
  background-color: #fffdfb;
}
.gm-style .gm-style-iw .gm-style-iw-d > div {
  width: 18.5rem;
}
.gm-style .gm-style-iw > button {
  opacity: 1 !important;
  top: 1.25rem !important;
  right: 13.75rem !important;
  width: 2rem !important;
  height: 2rem !important;
  padding: 0.25rem 0 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}
.gm-style .gm-style-iw > button::after {
  vertical-align: bottom;
  font-size: inherit;
  line-height: inherit;
  content: "\e902";
  transition: color 0.15s ease-out, transform 0.15s ease-in-out;
}
.gm-style .gm-style-iw > button > img {
  display: none !important;
}
.gm-style .gm-style-iw-tc {
  margin-left: 12.5rem !important;
}
.gm-style .gm-style-iw-chr {
  margin-bottom: -3rem;
}
.gm-style .gm-style-iw-t::after {
  content: none;
}

.navbar-wrapper {
  z-index: 1050;
  pointer-events: none;
}

.modal-with-navigation .navbar-wrapper {
  z-index: 1060;
}

.modal-with-navigation .navbar {
  margin-right: -1rem;
}
.modal-with-navigation .modal-dialog {
  margin-top: 5.5rem;
}
.modal-with-navigation .modal-dialog.modal-dialog-centered {
  min-height: calc(100% - 7.5rem);
}
.modal-with-navigation .modal-dialog.modal-dialog-centered::before {
  height: calc(100vh - 7.5rem);
}
@media (min-width: 1200px) {
  .modal-with-navigation .modal-dialog {
    margin-top: 6.75rem;
  }
  .modal-with-navigation .modal-dialog.modal-dialog-centered {
    min-height: calc(100% - 8.75rem);
  }
  .modal-with-navigation .modal-dialog.modal-dialog-centered::before {
    height: calc(100vh - 8.75rem);
  }
}
.modal-with-navigation.navbar-promo-active .modal-dialog {
  margin-top: 8.625rem;
}
.modal-with-navigation.navbar-promo-active .modal-dialog.modal-dialog-centered {
  min-height: calc(100% - 10.625rem);
}
.modal-with-navigation.navbar-promo-active .modal-dialog.modal-dialog-centered::before {
  height: calc(100vh - 10.625rem);
}
@media (min-width: 1200px) {
  .modal-with-navigation.navbar-promo-active .modal-dialog {
    margin-top: 8.625rem;
  }
  .modal-with-navigation.navbar-promo-active .modal-dialog.modal-dialog-centered {
    min-height: calc(100% - 10.625rem);
  }
  .modal-with-navigation.navbar-promo-active .modal-dialog.modal-dialog-centered::before {
    height: calc(100vh - 10.625rem);
  }
}
.modal-with-navigation .modal {
  padding-right: 0 !important;
}

.navbar {
  position: relative;
  background-color: #fffdfb;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  min-height: 3.5rem;
  font-size: 1rem;
  transition: transform 0.45s ease;
  pointer-events: auto;
}
@media (min-width: 1200px) {
  .navbar {
    min-height: 4.75rem;
  }
  .navbar-open .navbar {
    box-shadow: none;
  }
}
.scroll-down .navbar {
  transform: translate3d(0, -100%, 0);
  transition: transform 0.45s ease, opacity 0.15s 0.45s linear;
}
.navbar .navbar-container {
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}
@media (min-width: 1200px) {
  .navbar .navbar-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1374px;
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }
}

.navbar-container {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1200px) {
  .navbar-container {
    flex-flow: row nowrap;
  }
}
.navbar-container > .navbar-top-nav {
  flex-direction: row;
  font-size: 0.75rem;
}
.navbar-container > .navbar-top-nav > .nav-item > .nav-link {
  display: block;
  margin-bottom: 0;
  padding: 1.1875rem 0.625rem;
  letter-spacing: 0.03em;
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .navbar-container > .navbar-top-nav > .nav-item > .nav-link {
    padding: 1.8125rem 0.3125rem;
  }
}
.navbar-container > .navbar-top-nav > .nav-item > .nav-icon {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  line-height: 1;
}
@media (min-width: 1200px) {
  .navbar-container > .navbar-top-nav > .nav-item > .nav-icon {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
}
.navbar-container > .navbar-top-nav > .nav-item > .nav-icon .nbw-icon {
  display: block;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 1rem;
}
.navbar-nav .nav-link {
  display: flex;
  padding: 0;
  color: #34303d;
}
@media (min-width: 1200px) {
  .navbar-nav .nav-link {
    display: inline-flex;
  }
}
.navbar-nav .nav-link .spinner-border-sm {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.navbar-nav .nav-link-title-container {
  flex-grow: 1;
}
.navbar-nav .nav-link-badge {
  position: absolute;
  top: -0.875rem;
  right: 0;
  padding: 0.125rem 0.25rem;
}
.navbar-nav .nav-link-title {
  display: inline-block;
  position: relative;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 700;
}
@media (min-width: 1200px) {
  .navbar-nav .nav-link-title {
    display: inline;
    width: auto;
    font-size: 1.5rem;
  }
}
.navbar-nav .nav-link-title.nav-link-title-with-price {
  font-size: 1rem;
}
@media (min-width: 1200px) {
  .navbar-nav .nav-link-title.nav-link-title-with-price {
    font-size: 1rem;
  }
}
.navbar-nav .nav-link-extra {
  font-size: 0.875rem;
}
@media (min-width: 1200px) {
  .navbar-nav .nav-link-extra {
    font-size: 1rem;
  }
}
.navbar-nav .nav-link-image {
  align-self: flex-start;
  width: auto;
  max-height: 3.5rem;
}
@media (min-width: 1200px) {
  .navbar-nav .nav-link-image {
    max-height: 4rem;
  }
}
.navbar-nav .nav-link-image-first-level {
  align-self: flex-start;
  width: auto;
  max-height: 1rem;
}
.navbar-nav > .nav-item {
  display: inline;
  margin-bottom: 0;
}
.navbar-nav > .nav-item > .nav-link {
  margin-bottom: 1.5rem;
}
.navbar-nav > .nav-item > .nav-link.nav-link-with-image {
  margin-bottom: 0.5rem;
}
@media (min-width: 1200px) {
  .navbar-nav > .nav-item > .nav-link.nav-link-with-image {
    margin-bottom: 1rem;
  }
}
.navbar-nav > .nav-item > .nav-link.nav-link-small {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.navbar-nav > .nav-item:last-child > .nav-link {
  margin-bottom: 0;
}

.navbar-top-nav > .nav-item > .nav-link.dropdown-toggle::after {
  font-size: 1rem;
  line-height: 1;
  vertical-align: middle;
}
.navbar-top-nav > .nav-item.dropdown > .dropdown-menu {
  margin-top: -1rem;
}
@media (min-width: 1200px) {
  .navbar-top-nav {
    flex-direction: row;
    font-size: 0.75rem;
  }
  .navbar-top-nav > .nav-item > .nav-link {
    z-index: 1;
    position: relative;
    display: block;
    margin-bottom: 0;
    padding: 1.8125rem 0.3125rem;
    letter-spacing: 0.03em;
    white-space: nowrap;
    transition: opacity 0.1s linear;
  }
  .navbar-top-nav > .nav-item > .nav-link.nav-link-small {
    margin-top: 0;
    margin-bottom: 0;
  }
  .navbar-top-nav > .nav-item > .nav-link .nav-link-title {
    font-size: 0.75rem;
    font-weight: normal;
  }
  .navbar-top-nav > .nav-item > .nav-link .nav-link-title::before {
    content: none;
  }
  .navbar-top-nav > .nav-item > .nav-link .nav-link-extra {
    display: none;
  }
  .navbar-top-nav > .nav-item.inactive > .nav-link {
    opacity: 0.5;
  }
}
@media (min-width: 1440px) {
  .navbar-top-nav > .nav-item > .nav-link {
    padding: 1.71875rem 0.5rem;
    font-size: 0.875rem;
  }
  .navbar-top-nav > .nav-item > .nav-link .nav-link-title {
    font-size: 0.875rem;
  }
}

@media (min-width: 1200px) {
  .nav-link-underline .nav-link-title-small {
    position: relative;
    text-decoration: none;
  }
  .nav-link-underline .nav-link-title-small::before {
    position: absolute;
    bottom: -0.5px;
    left: 0;
    transform: scaleX(0);
    transform-origin: left center;
    width: 100%;
    height: 1px;
    background-color: #00d1d2;
    content: "";
    transition: all 0.25s ease-in-out, background-color 0.2s ease-in-out;
  }
  .nav-link-underline .nav-link-title {
    position: relative;
    text-decoration: none;
  }
  .nav-link-underline .nav-link-title::before {
    position: absolute;
    bottom: -2px;
    left: 0;
    transform: scaleX(0);
    transform-origin: left center;
    width: 100%;
    height: 4px;
    background-color: currentColor;
    content: "";
    transition: all 0.25s ease-in-out, background-color 0.2s ease-in-out;
  }
  .nav-link-underline:hover .nav-link-title::before,
  .nav-link-underline:hover .nav-link-title-small::before, .nav-link-underline:focus .nav-link-title::before,
  .nav-link-underline:focus .nav-link-title-small::before, .active > .nav-link-underline .nav-link-title::before,
  .active > .nav-link-underline .nav-link-title-small::before {
    transform: scaleX(1);
  }
  .active > .nav-link-underline .nav-link-title::before,
  .active > .nav-link-underline .nav-link-title-small::before {
    background-color: #00d1d2;
  }
}

.nav-toggle {
  position: relative;
  padding-right: 1em;
}
@media (min-width: 1200px) {
  .nav-toggle {
    padding-right: 0;
  }
}
.nav-toggle::after {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: inherit;
  line-height: inherit;
  content: "\e906";
}
@media (min-width: 1200px) {
  .nav-toggle::after {
    top: auto;
    right: -1.5em;
    margin-top: 0.07em;
    padding-left: 0.5em;
  }
}
@media (min-width: 1200px) {
  .navbar-top-nav > .nav-item > .nav-link .nav-toggle::after {
    position: static;
    top: auto;
    right: auto;
    transform: scaleY(1);
    margin-top: 0;
    margin-left: 0.25em;
    padding-left: 0;
    font-size: 1rem;
    line-height: 1;
    vertical-align: middle;
    content: "\e908";
    transition: transform 0.2s linear;
  }
}
@media (min-width: 1200px) {
  .navbar-top-nav > .active > .nav-link .nav-toggle::after {
    transform: scaleY(-1);
  }
}

.navbar .nav-icon {
  font-size: 1.25rem !important;
}

.navbar-collapse-header {
  display: flex;
  border-bottom: 1px solid #e1e0e2;
  min-height: calc(3.5rem + 1px);
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 1200px) {
  .navbar-collapse-header {
    display: none;
  }
}

.navbar-collapse-brand {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #34303d;
}

.navbar-collapse-body {
  padding: 2rem 2rem;
}
@media (min-width: 1200px) {
  .navbar-collapse-body {
    padding: 3.5rem 2rem 3.5rem;
  }
}

.navbar-collapse-footer {
  margin-top: auto;
  padding: 2rem 2rem;
  font-size: 1rem;
  color: #34303d;
}
@media (min-width: 1200px) {
  .navbar-collapse-footer {
    display: none !important;
  }
}
.navbar-collapse-footer .navbar-nav .nav-item + .nav-item {
  margin-top: 1rem;
}
.navbar-collapse-footer .navbar-nav .nav-link {
  display: inline;
  margin-bottom: 0;
}
.navbar-collapse-footer .dropup,
.navbar-collapse-footer .dropdown {
  display: inline-block;
}

@media (max-width: 1199.98px) {
  .navbar-collapse-first {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #fffdfb;
  }
  .navbar-promo-active .navbar-collapse-first {
    height: calc(100vh - 3.125rem);
    height: calc(var(--vh, 1vh) * 100 - 3.125rem);
  }
}
@media (max-width: 1199.98px) and (min-width: 1200px) {
  .navbar-collapse-first {
    height: auto !important;
  }
}
@media (max-width: 1199.98px) and (max-width: 1199.98px) {
  .navbar-collapse-first {
    z-index: 1;
  }
}
@media (max-width: 1199.98px) {
  .navbar-collapse-first.show {
    display: flex;
  }
  .navbar-collapse-first > .navbar-collapse-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) and (min-width: 1200px) {
  .navbar-collapse-first > .navbar-collapse-body {
    overflow-y: visible;
  }
}
@media (max-width: 1199.98px) {
  .navbar-collapse-first.show {
    display: flex;
  }
  .navbar-collapse-first > .navbar-collapse-body {
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-collapse-first {
    display: block !important;
    width: 100%;
  }
  .navbar-collapse-first > .navbar-collapse-body {
    padding: 0;
  }
}

.navbar-collapse-second,
.navbar-collapse-third {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #fffdfb;
}
.navbar-promo-active .navbar-collapse-second,
.navbar-promo-active .navbar-collapse-third {
  height: calc(100vh - 3.125rem);
  height: calc(var(--vh, 1vh) * 100 - 3.125rem);
}
@media (min-width: 1200px) {
  .navbar-collapse-second,
  .navbar-collapse-third {
    height: auto !important;
  }
}
@media (max-width: 1199.98px) {
  .navbar-collapse-second,
  .navbar-collapse-third {
    z-index: 1;
  }
}
.navbar-collapse-second.show,
.navbar-collapse-third.show {
  display: flex;
}
.navbar-collapse-second > .navbar-collapse-body,
.navbar-collapse-third > .navbar-collapse-body {
  overflow-y: auto;
}
@media (min-width: 1200px) {
  .navbar-collapse-second > .navbar-collapse-body,
  .navbar-collapse-third > .navbar-collapse-body {
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-collapse-second > .navbar-collapse-body,
  .navbar-collapse-third > .navbar-collapse-body {
    display: flex;
    width: 100%;
  }
  .navbar-collapse-second > .navbar-collapse-body > .navbar-nav,
  .navbar-collapse-third > .navbar-collapse-body > .navbar-nav {
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .navbar-collapse-second {
    overflow-y: auto;
    top: 4.75rem;
    min-height: 32rem;
    max-height: calc(100vh - 4.75rem);
    height: auto;
  }
  .navbar-promo-active .navbar-collapse-second {
    max-height: calc(100vh - 7.875rem);
  }
  .navbar-collapse-second > .navbar-collapse-body {
    margin-left: auto;
    margin-right: auto;
    max-width: 1374px;
    min-height: 32rem;
  }
  .navbar-collapse-second > .navbar-collapse-body > .navbar-nav {
    width: 23rem;
  }
  .navbar-collapse-second > .navbar-collapse-body > .navbar-nav .nav-item:last-child {
    padding-bottom: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .navbar-collapse-second > .navbar-collapse-body {
    min-height: 0 !important;
  }
}

@media (min-width: 1200px) {
  .navbar-collapse-third {
    z-index: 2;
    left: 26rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 59.875rem;
    min-height: 100%;
    height: auto;
  }
  .navbar-collapse-third > .navbar-collapse-body > .navbar-nav {
    flex-grow: 1;
    max-width: calc(100% - 32rem);
    padding-right: 3rem;
  }
  .navbar-collapse-third > .navbar-collapse-body > .navbar-nav > .nav-item.inactive > .nav-link {
    opacity: 0.5 !important;
  }
}

@media (max-width: 1199.98px) {
  .navbar-collapse-first {
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.3s ease;
  }
  .navbar-collapse-first > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-first > .navbar-collapse-footer {
    transform: translate3d(-2rem, 0, 0);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s linear;
    transition-delay: 0.3s;
  }
  .navbar-collapse-first.enter-to {
    transform: translate3d(0, 0, 0);
  }
  .navbar-collapse-first.enter-to > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-first.enter-to > .navbar-collapse-footer {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@media (max-width: 1199.98px) {
  .navbar-collapse-second,
  .navbar-collapse-third {
    opacity: 0;
    transition: opacity 0.3s linear;
  }
  .navbar-collapse-second > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-second > .navbar-collapse-body > .navbar-collapse-extra,
  .navbar-collapse-third > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-third > .navbar-collapse-body > .navbar-collapse-extra {
    transform: translate3d(-2rem, 0, 0);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s linear;
    transition-delay: 0.3s;
  }
  .navbar-collapse-second.enter-to,
  .navbar-collapse-third.enter-to {
    opacity: 1;
  }
  .navbar-collapse-second.enter-to > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-second.enter-to > .navbar-collapse-body > .navbar-collapse-extra,
  .navbar-collapse-third.enter-to > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-third.enter-to > .navbar-collapse-body > .navbar-collapse-extra {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  .navbar-collapse-second.leave-to,
  .navbar-collapse-third.leave-to {
    transition-delay: 0.3s;
  }
  .navbar-collapse-second.leave-to > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-second.leave-to > .navbar-collapse-body > .navbar-collapse-extra,
  .navbar-collapse-third.leave-to > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-third.leave-to > .navbar-collapse-body > .navbar-collapse-extra {
    transition-delay: 0s;
  }
  .navbar-collapse-second.enter-active > .navbar-collapse-body, .navbar-collapse-second.leave-active > .navbar-collapse-body,
  .navbar-collapse-third.enter-active > .navbar-collapse-body,
  .navbar-collapse-third.leave-active > .navbar-collapse-body {
    overflow-y: hidden;
  }
}
@media (min-width: 1200px) {
  .navbar-collapse-second > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-third > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link {
    opacity: 0;
    transform: translate3d(-2rem, 0, 0);
    transition: transform 0.3s ease, opacity 0.2s linear, background-position 0.5s ease-in-out;
  }
  .navbar-collapse-second > .navbar-collapse-body > .navbar-collapse-extra,
  .navbar-collapse-third > .navbar-collapse-body > .navbar-collapse-extra {
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  .navbar-collapse-second.enter-to > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-third.enter-to > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .navbar-collapse-second.enter-to > .navbar-collapse-body > .navbar-collapse-extra,
  .navbar-collapse-third.enter-to > .navbar-collapse-body > .navbar-collapse-extra {
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .navbar-collapse-second {
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s ease-in-out;
  }
  .navbar-collapse-second > .navbar-collapse-body {
    transition: min-height 0.3s ease-in-out;
  }
  .navbar-collapse-second > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link {
    transition-delay: 0.3s;
  }
  .navbar-collapse-second > .navbar-collapse-body > .navbar-collapse-extra {
    transition-delay: 0.3s;
  }
  .navbar-collapse-second.enter-to {
    transform: translate3d(0, 0, 0);
  }
  .navbar-collapse-second.leave-to {
    transition-delay: 0.3s;
  }
  .navbar-collapse-second.leave-to > .navbar-collapse-body > .navbar-nav > .nav-item > .nav-link,
  .navbar-collapse-second.leave-to > .navbar-collapse-body > .navbar-collapse-extra {
    transition-delay: 0s;
  }
  .navbar-collapse-second.enter-active {
    z-index: -2;
  }
  .navbar-collapse-second.leave-active {
    z-index: -1;
  }
}

.navbar-toggler {
  position: absolute;
  top: 0;
  left: 1rem;
  padding: 1rem 1rem;
  font-size: 1.5rem;
  line-height: 1;
}
@media (min-width: 1200px) {
  .navbar-toggler {
    display: none;
  }
}

.navbar-toggler-icon {
  display: block;
  width: 1em;
  height: 1em;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath stroke='%2334303d' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M0 7h24M0 17h26'/%3e%3c/svg%3e") no-repeat;
  vertical-align: middle;
  content: "";
}

.navbar-toggler,
.navbar-btn {
  background-color: transparent;
  border: 0;
}
.navbar-toggler:hover, .navbar-toggler:focus,
.navbar-btn:hover,
.navbar-btn:focus {
  text-decoration: none;
}

.navbar-btn {
  padding: 1.125rem 1rem;
  line-height: 1;
  font-size: 1.25rem;
  color: inherit;
}

.navbar-collapse-extra {
  position: relative;
  margin-top: 2rem;
}
@media (min-width: 1200px) {
  .navbar-collapse-extra {
    width: 32rem;
    margin-top: 0;
  }
}
.navbar-collapse-extra .nav-link {
  margin-top: 1rem;
}
@media (min-width: 1200px) {
  .navbar-collapse-extra .nav-link {
    margin-top: 1.25rem;
  }
}

.navbar-brand {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fffdfb;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media (min-width: 1200px) {
  .navbar-brand {
    z-index: 2;
    padding-top: 1.1875rem;
    padding-bottom: 1.1875rem;
  }
}
.navbar-brand .brand-image {
  display: block;
  width: auto;
  height: 1.875rem;
  max-width: none;
}
@media (min-width: 1200px) {
  .navbar-brand .brand-image {
    height: 2.375rem;
  }
}
.navbar-brand .brand-image-small {
  transition: opacity 0.55s linear;
  opacity: 0;
}
.scroll-down .navbar-brand .brand-image-small {
  transition-delay: 0.45s;
}
.scroll-up .navbar-brand .brand-image-small, .scroll-down .navbar-brand .brand-image-small {
  opacity: 1;
}
.navbar-brand .brand-image-letter {
  transition: opacity 0.33s linear, transform 0.45s ease-out;
  transform: translate3d(0, 0, 0);
}
.scroll-down .navbar-brand .brand-image-letter {
  transition-delay: 0.45s;
}
.scroll-up .navbar-brand .brand-image-letter, .scroll-down .navbar-brand .brand-image-letter {
  opacity: 0;
}
.scroll-up .navbar-brand .brand-image-letter.brand-image-letter-i, .scroll-down .navbar-brand .brand-image-letter.brand-image-letter-i {
  transform: translate3d(35%, 0, 0);
}
.scroll-up .navbar-brand .brand-image-letter.brand-image-letter-q, .scroll-down .navbar-brand .brand-image-letter.brand-image-letter-q {
  transform: translate3d(14%, 0, 0);
}
.scroll-up .navbar-brand .brand-image-letter.brand-image-letter-o, .scroll-down .navbar-brand .brand-image-letter.brand-image-letter-o {
  transform: translate3d(14%, 0, 0);
}
.scroll-up .navbar-brand .brand-image-letter.brand-image-letter-s, .scroll-down .navbar-brand .brand-image-letter.brand-image-letter-s {
  transform: translate3d(-25%, 0, 0);
}

.navbar-backdrop {
  z-index: 1045;
}

.navbar-open {
  overflow: hidden;
}
@media (min-width: 992px) {
  .navbar-open {
    overflow: auto;
  }
}

.navbar-promo {
  position: relative;
  z-index: 1;
  background-color: #34303d;
  padding-top: 0.90625rem;
  padding-bottom: 0.90625rem;
  color: #fffdfb;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.03em;
  pointer-events: auto;
}
@media (min-width: 1200px) {
  .navbar-promo {
    padding-top: 0.90625rem;
    padding-bottom: 0.90625rem;
    font-size: 0.875rem;
  }
}

.navbar-promo-container {
  position: relative;
}

.navbar-promo-close {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
}
.navbar-promo-close:focus {
  outline: auto !important;
  outline-color: -webkit-focus-ring-color !important;
}

@media (max-width: 991.98px) {
  .swiper-container.swiper-container-next-slide-visible-on-mobile .swiper-slide {
    width: calc(100% - 4rem) !important;
  }
}

@media (max-width: 991.98px) {
  .swiper-container-next-slide-visible-on-mobile-with-2-slides .swiper-slide {
    max-width: calc(50% - 1rem) !important;
  }
}

.link-underlined, .qure-content-block a, .wysiwyg a {
  text-decoration: none;
  padding-bottom: 1px;
  background-color: transparent;
  background-image: linear-gradient(to right, #00d1d2 50%, currentColor 50%);
  background-size: 200% 1px;
  background-position: 100% bottom;
  transition: background-position 0.5s ease-in-out;
  background-repeat: no-repeat;
}
.link-underlined.link-underlined-only-on-hover, .qure-content-block a.link-underlined-only-on-hover, .wysiwyg a.link-underlined-only-on-hover {
  background-image: linear-gradient(to right, #00d1d2 50%, transparent 50%);
}
.link-underlined:hover, .qure-content-block a:hover, .wysiwyg a:hover {
  background-position: 0 bottom;
  text-decoration: none;
  transition-delay: 0s !important;
}
.link-underlined.link-underlined-large, .qure-content-block a.link-underlined-large, .wysiwyg a.link-underlined-large {
  padding-bottom: 2px;
  background-size: 200% 2px;
}
.link-underlined.loading, .qure-content-block a.loading, .wysiwyg a.loading {
  background-image: none;
}

.link-arrowed-left,
.link-arrowed-right {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 0.03125em;
}
.link-arrowed-left:hover,
.link-arrowed-right:hover {
  text-decoration: none;
}

.link-arrowed-left:not(.loading)::before,
.link-arrowed-right:not(.loading)::after {
  display: inline-block;
  margin-top: -0.25em;
  vertical-align: middle;
  font-size: inherit;
  transition: transform 0.2s ease;
}

.link-arrowed-left:not(.loading) {
  padding-left: 1.5em;
}
.link-arrowed-left:not(.loading):hover::before, .link-arrowed-left:not(.loading):focus::before {
  transform: translateX(-0.5em);
}
.link-arrowed-left:not(.loading)::before {
  margin-left: -1.5em;
  padding-right: 0.5em;
  content: "\e909";
}

.link-arrowed-right:not(.loading) {
  padding-right: 1.5em;
}
.link-arrowed-right:not(.loading):hover::after, .link-arrowed-right:not(.loading):focus::after {
  transform: translateX(0.5em);
}
.link-arrowed-right:not(.loading)::after {
  margin-right: -1.5em;
  padding-left: 0.5em;
  content: "\e90a";
}

button.link-underlined,
button.link-arrowed-left,
button.link-arrowed-right {
  border: none;
  padding: 0;
  color: inherit;
}

button.link-arrowed-left,
button.link-arrowed-right {
  background: none;
}

.no-link {
  text-decoration: none !important;
  cursor: default !important;
}

.list-group-item {
  border-left-width: 0;
  border-right-width: 0;
}
.list-group-item:active {
  background-color: #fffdfb;
  color: #34303d;
}
.list-group-item.active {
  background-color: #f6f4f0;
  color: #34303d;
}

.list-group-item-action {
  transition: color 0.15s ease-out;
}

.list-group-item-arrowed-right {
  padding-right: 2.5rem;
}
.list-group-item-arrowed-right::before {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translate3d(0, -50%, 0);
  content: "\e906";
  font-size: 1.25rem;
  transition: color 0.15s ease-out, transform 0.15s ease-in-out;
}
.list-group-item-arrowed-right.active::before {
  color: #00d1d2;
}
.list-group-item-arrowed-right.list-group-item-action:hover::before, .list-group-item-arrowed-right.list-group-item-action:focus::before {
  transform: translate3d(3px, -50%, 0);
  color: #00d1d2;
}
.list-group-item-arrowed-right.list-group-item-action.active::before {
  color: #00d1d2;
}

.list-group-padless .list-group-item {
  padding-left: 0;
  padding-right: 1.25rem;
}
.list-group-padless .list-group-item::before {
  right: 0;
}

.list-group-padded .list-group-item {
  padding-left: 1.25rem;
  padding-right: 2.5rem;
}
.list-group-padded .list-group-item::before {
  right: 1.25rem;
}

@media (min-width: 576px) {
  .list-group-padded-sm .list-group-item {
    padding-left: 1.25rem;
    padding-right: 2.5rem;
  }
  .list-group-padded-sm .list-group-item::before {
    right: 1.25rem;
  }
}
@media (min-width: 768px) {
  .list-group-padded-md .list-group-item {
    padding-left: 1.25rem;
    padding-right: 2.5rem;
  }
  .list-group-padded-md .list-group-item::before {
    right: 1.25rem;
  }
}
@media (min-width: 992px) {
  .list-group-padded-lg .list-group-item {
    padding-left: 1.25rem;
    padding-right: 2.5rem;
  }
  .list-group-padded-lg .list-group-item::before {
    right: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .list-group-padded-xl .list-group-item {
    padding-left: 1.25rem;
    padding-right: 2.5rem;
  }
  .list-group-padded-xl .list-group-item::before {
    right: 1.25rem;
  }
}
ul,
ol {
  padding-left: 1.25rem;
  margin-bottom: 1.75rem;
}
ul > li,
ol > li {
  margin-bottom: 0.5rem;
}
ul > li:last-child:not(:first-child),
ol > li:last-child:not(:first-child) {
  margin-bottom: 0;
}
ul > li > ul,
ul > li > ol,
ol > li > ul,
ol > li > ol {
  margin-top: 0.5rem;
}
ul.list-small > li,
ol.list-small > li {
  margin-bottom: 0.25rem;
}
ul.list-small > li:last-child:not(:first-child),
ol.list-small > li:last-child:not(:first-child) {
  margin-bottom: 0;
}
ul.list-small > li > ul,
ul.list-small > li > ol,
ol.list-small > li > ul,
ol.list-small > li > ol {
  margin-top: 0.25rem;
}

.map-container {
  position: relative;
  height: calc(100vh - 6.0625rem);
}
@media (min-width: 1200px) {
  .map-container {
    height: calc(100vh - 4.75rem);
  }
}
.navbar-promo-active .map-container {
  height: calc(100vh - 9.1875rem);
}
@media (min-width: 1200px) {
  .navbar-promo-active .map-container {
    height: calc(100vh - 7.875rem);
  }
}

.map-top-left,
.map-bottom-left {
  z-index: 1;
  position: absolute;
  left: 2rem;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 768px) {
  .map-top-left,
  .map-bottom-left {
    left: 26.25rem;
  }
}

.map-top-left {
  top: 2rem;
}
@media (min-width: 768px) {
  .map-top-left {
    top: 1.25rem;
  }
}

.map-bottom-left {
  bottom: 2rem;
}
@media (min-width: 768px) {
  .map-bottom-left {
    bottom: 1.25rem;
  }
}

.map-content {
  z-index: 1;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem 2rem 0;
  background: #fffdfb;
}
@media (min-width: 768px) {
  .map-content {
    top: 1.25rem;
    bottom: auto;
    left: 4rem;
    width: 21rem;
    min-height: 13.5rem;
    max-height: calc(100vh - 6rem);
    padding: 1.25rem 1.25rem 0;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 1200px) {
  .map-content {
    max-height: calc(100vh - 7.25rem);
  }
}
.navbar-promo-active .map-content {
  max-height: calc(100vh - 9.125rem);
}
@media (min-width: 1200px) {
  .navbar-promo-active .map-content {
    max-height: calc(100vh - 10.375rem);
  }
}

@media (min-width: 768px) {
  .map-content-inner-no-gutters {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
}

.map-floating-marker {
  position: absolute;
  margin: 1.375rem 1.375rem;
  width: 2.75rem;
  height: 2.75rem;
  background-image: url("../../img/store-locator/icon-marker-circle.svg");
  padding-top: 1.375rem;
  font-size: 0.625rem;
  text-align: center;
  color: #34303d;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.region-paths {
  cursor: pointer;
}
.region-paths:hover > path, .region-paths.active > path {
  fill: #00d1d2;
}
.region-paths > path {
  transition: fill 0.15s ease-in-out;
}

.product-box.card {
  border: none;
}
.product-box.card .card-img {
  background-color: #f6f4f0;
  border-radius: 0;
}

.product-labels {
  z-index: 1;
}
.product-labels.product-labels-right {
  right: 0;
}
.product-labels .badge {
  margin-right: 0.5rem;
}
.product-labels .text-right .badge {
  margin-right: 0;
  margin-left: 0.5rem;
}

.product-image {
  margin-left: 10%;
  margin-right: 10%;
}
.product-image .second-image {
  display: none;
}
@media (min-width: 992px) {
  .product-image:hover .first-image:not(.second-image-disabled) {
    display: none;
  }
  .product-image:hover .first-image:last-child, .product-image:hover .second-image:last-child:not(.second-image-disabled) {
    display: block;
  }
}

.product-image-label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 1rem;
  margin-left: 1rem;
  background-position: top left;
  background-repeat: no-repeat;
  pointer-events: none;
}
.product-image-label.product-image-label-mobile-smaller {
  background-size: 2rem;
}
@media (min-width: 992px) {
  .product-image-label.product-image-label-mobile-smaller {
    background-size: auto;
  }
}

@media (min-width: 992px) {
  .bundle-customize-box .bundle-customize-image {
    height: 10rem;
    width: 10rem;
  }
}
.bundle-customize-box .color-select {
  max-width: 15rem;
}
.bundle-customize-box .product-image {
  margin: 0;
}

.product-image-expand-button {
  position: absolute;
  right: 0;
  bottom: 0;
}
.product-image-expand-button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

.product-fullscreen-popup {
  z-index: 1060;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.45s ease, padding-top 0.45s ease, opacity 0.15s 0.45s linear;
}
.product-fullscreen-popup.active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.45s ease, padding-top 0.45s ease;
}
.product-fullscreen-popup .product-fullscreen-close-button {
  position: absolute;
  top: 0;
  right: 0;
}
.product-fullscreen-popup .swiper-wrapper {
  height: 100vw;
  max-height: 80vh;
  width: 100vw;
}
.product-fullscreen-popup .product-fullscreen-image {
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
.product-fullscreen-popup .swiper-slide {
  max-width: 80vh;
  overflow: hidden;
}

.pagination .page-item {
  margin-left: 0.475rem;
  margin-right: 0.475rem;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .pagination .page-item {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
}
.pagination .page-item .page-link {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 1.5rem;
  padding: 0.125rem 0.5rem;
  border-radius: 1rem;
}
.pagination .page-item .page-link .pagination-icon {
  position: relative;
  left: -50%;
}
.pagination .page-item.active .page-link {
  margin-top: 0;
  margin-bottom: 0;
  width: 2rem;
  padding: 0.375rem 0.75rem;
  border-radius: 1rem;
}
.pagination .page-item:not(.active) .page-link {
  line-height: 1.35rem;
}
.pagination .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.pagination .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.taxon-description {
  color: #7b7881;
}

.vertical-menu-mobile-display {
  position: absolute;
  z-index: 2;
  width: 100%;
  background: #fffdfb;
  font-size: 1rem;
  border-bottom: 1px solid #e1e0e2;
}
.vertical-menu-mobile-display .taxon-title-wrapper {
  margin-right: 1rem;
  max-width: calc(100% - 50px);
}
.vertical-menu-mobile-display .vertical-menu-wrapper {
  width: 100%;
  margin-top: 2rem;
}
.vertical-menu-mobile-display .toggle-vertical-menu {
  cursor: pointer;
}
.vertical-menu-mobile-display .vertical-menu-mobile-icon {
  transition: transform 0.3s;
}
.vertical-menu-mobile-display.expanded {
  box-shadow: 0 0.625rem 0.625rem 0 rgba(0, 0, 0, 0.12);
}
.vertical-menu-mobile-display.expanded .vertical-menu-mobile-icon {
  transform: scaleY(-1);
}

.vertical-menu-wrapper {
  display: none;
}
.vertical-menu-wrapper.gift-menu-wrapper {
  display: block;
}
@media (min-width: 992px) {
  .vertical-menu-wrapper {
    display: block;
  }
}
.vertical-menu-wrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sticky-vertical-menu-wrapper {
  position: sticky;
  top: 6rem;
}

.sticky-vertical-menu-wrapper-with-subnav {
  position: sticky;
  top: 12rem;
}

.timer-container {
  display: flex;
}

.timer {
  border: #db3826 solid 1px;
  background-color: #fffdfb;
  color: #db3826;
}
@media (min-width: 576px) {
  .timer {
    display: flex;
  }
}

.timer-label {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 576px) {
  .timer-label {
    flex-shrink: 1;
    align-self: center;
    font-size: 1rem;
  }
}
@media (max-width: 575.98px) {
  .timer-label > br {
    display: none;
  }
}

.timer-value {
  display: flex;
  background-color: #db3826;
  padding: 1rem 0.5rem;
  color: #fffdfb;
}

.timer-value-block {
  text-align: center;
}

.timer-value-item {
  min-width: 2.5em;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.timer-value-item-in-square {
  min-width: 3.75rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1;
}
@media (min-width: 992px) {
  .timer-value-item-in-square {
    min-width: 5.5rem;
    font-size: 2.5rem;
  }
}

.timer-value-label {
  font-size: 0.75rem;
  line-height: 1.2;
}

.timer-value-colon {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.quantity-input {
  margin: 0;
  border: 0;
  background: none;
  padding: 0 0.5rem;
  width: 3rem;
  line-height: 1.5;
  font-size: 1rem;
  font-family: "IQOS", "Helvetica Neue", Arial, sans-serif;
  text-align: center;
  -moz-appearance: textfield;
}
.quantity-input::-webkit-outer-spin-button, .quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.quantity-input:focus {
  outline: 0;
}

.cart-popup {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  width: 100%;
  max-height: 100%;
  box-shadow: -0.125rem -0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  transform: translate3d(0, 100%, 0);
  transition: transform 0.45s ease, padding-top 0.45s ease, opacity 0.15s 0.45s linear;
}
@media (min-width: 992px) {
  .cart-popup {
    top: 0;
    right: 0;
    left: auto;
    display: flex;
    flex-direction: column;
    width: 25rem;
    max-height: none;
    padding-top: 3.5rem;
    transform: translate3d(100%, 0, 0);
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .cart-popup {
    padding-top: 4.75rem;
  }
}
@media (min-width: 992px) {
  .scroll-down .cart-popup {
    padding-top: 0;
  }
  .navbar-promo-active .cart-popup {
    top: 3.125rem;
  }
}
.cart-popup.cart-popup-visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.45s ease, padding-top 0.45s ease;
}

.cart-popup-body {
  padding: 2rem 2rem;
}
@media (min-width: 992px) {
  .cart-popup-body {
    padding: 3rem 3rem;
  }
}

.cart-popup-footer {
  padding: 0 0 0;
}
@media (min-width: 992px) {
  .cart-popup-footer {
    margin-top: auto;
    padding: 0 1.25rem 1.25rem;
  }
}

.cart-popup-product-image {
  width: 5.75rem;
  height: auto;
}

.modal {
  -webkit-overflow-scrolling: touch;
}
.modal .modal-body {
  padding: 2.5rem 1.5rem !important;
}
@media (min-width: 992px) {
  .modal .modal-body {
    padding: 2.5rem !important;
  }
}

.modal-title:first-child {
  margin-top: -0.5rem;
}

.modal-close + .modal-body .modal-title {
  margin-right: 2rem;
}

.modal-close {
  z-index: 1;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
}
@media (min-width: 992px) {
  .modal-close {
    top: 2.25rem;
    right: 2rem;
  }
}

.modal-dialog-scrollable .modal-body {
  display: flex;
  flex-direction: column;
}
.modal-dialog-scrollable .modal-scrollable-content {
  overflow-x: hidden;
  overflow-y: auto;
}

.faded-scroll-wrapper {
  position: relative;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
.faded-scroll-wrapper::before, .faded-scroll-wrapper::after {
  opacity: 0;
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  pointer-events: none;
  width: calc(100% - 20px);
  height: 4em;
  transition: opacity 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .faded-scroll-wrapper::before, .faded-scroll-wrapper::after {
    transition: none;
  }
}
.faded-scroll-wrapper::before {
  top: 0;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #fff 90%);
}
.faded-scroll-wrapper::after {
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 90%);
}
.faded-scroll-wrapper.faded-top::before, .faded-scroll-wrapper.faded-bottom::after {
  opacity: 1;
}

@media (max-width: 575.98px) {
  .modal-full-screen {
    padding: 0 !important;
  }
  .modal-full-screen .modal-dialog {
    max-width: none !important;
    max-height: none;
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .modal-full-screen .modal-dialog .modal-content {
    border-radius: 0;
    max-height: none;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .modal-full-screen-sm {
    padding: 0 !important;
  }
  .modal-full-screen-sm .modal-dialog {
    max-width: none !important;
    max-height: none;
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .modal-full-screen-sm .modal-dialog .modal-content {
    border-radius: 0;
    max-height: none;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .modal-full-screen-md {
    padding: 0 !important;
  }
  .modal-full-screen-md .modal-dialog {
    max-width: none !important;
    max-height: none;
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .modal-full-screen-md .modal-dialog .modal-content {
    border-radius: 0;
    max-height: none;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .modal-full-screen-lg {
    padding: 0 !important;
  }
  .modal-full-screen-lg .modal-dialog {
    max-width: none !important;
    max-height: none;
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .modal-full-screen-lg .modal-dialog .modal-content {
    border-radius: 0;
    max-height: none;
    height: 100%;
    width: 100%;
  }
}
.modal-full-screen-xl {
  padding: 0 !important;
}
.modal-full-screen-xl .modal-dialog {
  max-width: none !important;
  max-height: none;
  height: 100%;
  width: 100%;
  margin: 0;
}
.modal-full-screen-xl .modal-dialog .modal-content {
  border-radius: 0;
  max-height: none;
  height: 100%;
  width: 100%;
}

.modal-open {
  padding-right: 0 !important;
}

@media (min-width: 992px) {
  .modal-xxl {
    max-width: 972px;
  }
}
@media (min-width: 992px) {
  .modal-xxl {
    max-width: 972px;
  }
}
.age-gate-modal-backdrop {
  background-color: #fff !important;
  opacity: 1 !important;
}
@media (min-width: 992px) {
  .age-gate-modal-backdrop {
    background-color: rgba(255, 255, 255, 0.97) !important;
  }
}

.white-overlay {
  position: absolute;
  background-color: #fff;
  min-height: 120%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1051;
}
.white-overlay .white-overlay-inner-content {
  position: absolute;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
}

.container-narrow {
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
  .container-narrow {
    max-width: 916px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.container-no-gutters {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

.container {
  padding-right: 2rem;
  padding-left: 2rem;
}

@media (min-width: 576px) {
  .container-sm {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media (min-width: 768px) {
  .container-md {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media (min-width: 992px) {
  .container-lg {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media (min-width: 1200px) {
  .container-xl {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
.toast-title {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.toast-title:before {
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  color: #fff;
  margin-right: 1.25rem;
  font-weight: bold;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
}
.toast-success .toast-title:before {
  content: "\e913";
  background-color: #57760c;
}
.toast-error .toast-title:before {
  content: "\e91a";
  background-color: #c73636;
}

button.toast-close-button {
  padding: 0.25rem 0;
  text-shadow: none;
}

.toast-close-button:hover, .toast-close-button:focus {
  opacity: 1;
}

#toast-container {
  pointer-events: none;
}
#toast-container > div {
  opacity: 1;
  margin: 0 0 0.5rem;
  padding: 1.7rem 2rem;
  border: 1px solid #f6f4f0;
  width: 18.75rem;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  background-color: #fffdfb;
  color: #34303d;
  pointer-events: auto;
}
#toast-container > div:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
}
#toast-container > div > .toast-info,
#toast-container > div > .toast-error,
#toast-container > div > .toast-success,
#toast-container > div > .toast-warning {
  background-image: none !important;
}
#toast-container.toast-bottom-center > div {
  max-width: 40rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.dropdown-menu {
  box-shadow: 0 0 3.125rem 0 rgba(0, 0, 0, 0.1);
}

.dropdown-toggle::after {
  transition: transform 0.2s linear;
}
.dropdown-toggle::after, .dropup .dropdown-toggle::after {
  transform: scaleY(1);
  border: none;
  vertical-align: bottom;
  font-size: inherit;
  line-height: inherit;
  content: "\e908";
}
.dropdown.show .dropdown-toggle::after, .dropup.show .dropdown-toggle::after {
  transform: scaleY(-1);
}

.spinner-border {
  border: 0.25em solid rgba(52, 48, 61, 0.07);
  border-right-color: currentColor;
}

.spinner-border-sm {
  border-width: 0.2em;
}

.spinner-border-lg {
  width: 4rem;
  height: 4rem;
  border-width: 0.25em;
}

.btn .spinner-border, .ratings-and-reviews-btn .spinner-border, .bv_modal_outer_content .bv_button_buttonFull .spinner-border,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit .spinner-border, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn .spinner-border, #buorgul .spinner-border,
#buorgpermanent .spinner-border, #buorgig .spinner-border, .gm-style .gm-style-iw > button .spinner-border {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -0.75rem;
  margin-left: -0.75rem;
  border-width: 0.2em;
  width: 1.5rem;
  height: 1.5rem;
}

.link-arrowed-left .spinner-border,
.link-arrowed-right .spinner-border {
  vertical-align: middle;
}

.content-spinner-overlay {
  display: flex;
  background-color: rgba(184, 183, 187, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  transition: color 0.2s, opacity 0.3s;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 2.5rem;
}
.swiper-button-prev.btn, .swiper-button-prev.ratings-and-reviews-btn, .bv_modal_outer_content .swiper-button-prev.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.swiper-button-prev.bv-submission-button-submit, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .swiper-button-prev.bv-content-btn, .swiper-button-prev#buorgul,
.swiper-button-prev#buorgpermanent, .swiper-button-prev#buorgig, .gm-style .gm-style-iw > button.swiper-button-prev,
.swiper-button-next.btn,
.swiper-button-next.ratings-and-reviews-btn,
.bv_modal_outer_content .swiper-button-next.bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.swiper-button-next.bv-submission-button-submit,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .swiper-button-next.bv-content-btn,
.swiper-button-next#buorgul,
.swiper-button-next#buorgpermanent,
.swiper-button-next#buorgig,
.gm-style .gm-style-iw > button.swiper-button-next {
  z-index: 10;
}
.swiper-button-prev.btn::after, .swiper-button-prev.ratings-and-reviews-btn::after, .bv_modal_outer_content .swiper-button-prev.bv_button_buttonFull::after,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.swiper-button-prev.bv-submission-button-submit::after, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .swiper-button-prev.bv-content-btn::after, .swiper-button-prev#buorgul::after,
.swiper-button-prev#buorgpermanent::after, .swiper-button-prev#buorgig::after, .gm-style .gm-style-iw > button.swiper-button-prev::after,
.swiper-button-next.btn::after,
.swiper-button-next.ratings-and-reviews-btn::after,
.bv_modal_outer_content .swiper-button-next.bv_button_buttonFull::after,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.swiper-button-next.bv-submission-button-submit::after,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .swiper-button-next.bv-content-btn::after,
.swiper-button-next#buorgul::after,
.swiper-button-next#buorgpermanent::after,
.swiper-button-next#buorgig::after,
.gm-style .gm-style-iw > button.swiper-button-next::after {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.swiper-button-prev {
  left: 0;
}
.swiper-button-prev::after {
  content: "\e905";
}

.swiper-button-next {
  right: 0;
}
.swiper-button-next::after {
  content: "\e906";
}

.hero-slider-navigation button.swiper-button-prev, .hero-slider-navigation button.swiper-button-next,
.banner-slider-container button.swiper-button-prev,
.banner-slider-container button.swiper-button-next {
  border: none;
  background: none;
}

.swiper-button-light {
  color: #fffdfb;
}
.swiper-button-light:hover {
  color: #fffdfb;
}

.swiper-button-dark {
  color: #34303d;
}
.swiper-button-dark:hover {
  color: #34303d;
}

.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet {
  opacity: 1;
  width: 0.5rem;
  height: 0.5rem;
  transition: background-color 0.3s;
}
.swiper-pagination-bullet, .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.375rem;
}
.swiper-pagination-dark .swiper-pagination-bullet {
  background-color: rgba(153, 151, 158, 0.4);
}
.swiper-pagination-dark .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #34303d;
}
.swiper-pagination-light .swiper-pagination-bullet {
  background-color: rgba(255, 253, 251, 0.3);
}
.swiper-pagination-light .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fffdfb;
}

.swiper-pagination-no-transition .swiper-pagination-bullet {
  transition: none;
}

.swiper-pagination-stretched .swiper-pagination-bullet {
  border-radius: 0;
  width: 1rem;
  height: 0.125rem;
}
@media (min-width: 992px) {
  .swiper-pagination-stretched .swiper-pagination-bullet {
    width: 1.375rem;
    height: 0.125rem;
  }
}
.swiper-pagination-stretched .swiper-pagination-bullet, .swiper-container-horizontal > .swiper-pagination-stretched .swiper-pagination-bullet {
  margin: 0 0.375rem;
}
@media (min-width: 992px) {
  .swiper-pagination-stretched .swiper-pagination-bullet, .swiper-container-horizontal > .swiper-pagination-stretched .swiper-pagination-bullet {
    margin: 0 0.4375rem;
  }
}

.swiper-pagination-lock {
  display: none;
}

.swiper-buttons-navigation-faded:hover .swiper-button-next,
.swiper-buttons-navigation-faded:hover .swiper-button-prev {
  opacity: 1;
}
.swiper-buttons-navigation-faded:hover .swiper-button-next.swiper-button-disabled,
.swiper-buttons-navigation-faded:hover .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}
.swiper-buttons-navigation-faded .swiper-button-next,
.swiper-buttons-navigation-faded .swiper-button-next .swiper-button-disabled,
.swiper-buttons-navigation-faded .swiper-button-prev,
.swiper-buttons-navigation-faded .swiper-button-prev .swiper-button-disabled {
  opacity: 0;
}

.swiper-buttons-navigation-slide-in:hover .swiper-button-prev,
.swiper-buttons-navigation-slide-in:hover .swiper-button-next {
  transform: translate3d(0, 0, 0);
}
.swiper-buttons-navigation-slide-in .swiper-button-prev,
.swiper-buttons-navigation-slide-in .swiper-button-next {
  transition: color 0.2s, opacity 0.3s, transform 0.3s;
}
.swiper-buttons-navigation-slide-in .swiper-button-prev:hover, .swiper-buttons-navigation-slide-in .swiper-button-prev:focus,
.swiper-buttons-navigation-slide-in .swiper-button-next:hover,
.swiper-buttons-navigation-slide-in .swiper-button-next:focus {
  transition: background 0.2s 0.25s, box-shadow 0.2s 0.25s ease-out, opacity 0.3s, transform 0.3s;
}
.swiper-buttons-navigation-slide-in .swiper-button-prev {
  transform: translate3d(0.5rem, 0, 0);
}
.swiper-buttons-navigation-slide-in .swiper-button-next {
  transform: translate3d(-0.5rem, 0, 0);
}

.swiper-buttons-container {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 10;
  margin: 0 auto;
}

.swiper-slide-fade-sideways > * {
  transition: transform 0.3s;
}
.swiper-slide-fade-sideways.swiper-slide-active > * {
  transform: translate3d(0, 0, 0);
}
.swiper-slide-fade-sideways.swiper-slide-prev > * {
  transform: translate3d(-50px, 0, 0);
}
.swiper-slide-fade-sideways.swiper-slide-duplicate-prev > * {
  transform: translate3d(50px, 0, 0);
}
.swiper-slide-fade-sideways.swiper-slide-next > * {
  transform: translate3d(50px, 0, 0);
}
.swiper-slide-fade-sideways.swiper-slide-duplicate-next > * {
  transform: translate3d(-50px, 0, 0);
}

.collapse-item-arrow {
  display: block;
  transition: transform 0.2s linear;
}
.collapsed .collapse-item-arrow {
  transform: scaleY(-1);
}

.collapse-item-with-circle {
  border: 2px solid #34303d;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: relative;
  transition: transform 0.2s linear;
  transform: rotate(0deg);
}
.collapse-item-with-circle .collapse-item-arrow {
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.collapsed .collapse-item-with-circle {
  transform: rotate(-180deg);
}

.collapse-item:not(:last-child) {
  border-bottom: 1px solid #b8b7bb;
}
.collapse-item.bordered {
  border-bottom: 1px solid #b8b7bb;
}

.collapse-item-heading {
  cursor: pointer;
  padding: 1.5rem 0 1.5rem;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  outline: inherit;
  width: 100%;
  text-align: start;
}
@media (min-width: 992px) {
  .collapse-item-heading:not(.collapse-item-small) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.collapse-item-heading:focus {
  border: 0;
}
.collapse-item-heading:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

.collapse-item-body {
  padding-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .collapse-item-body {
    padding-bottom: 2rem;
  }
}

.collapse-arrow {
  transition: transform 0.2s linear;
}

.visible-when-collapsed {
  opacity: 0;
  transition: opacity 0.2s linear;
}

.collapsed .collapse-arrow {
  transform: scaleY(-1);
}
.collapsed .visible-when-collapsed {
  opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #b8b7bb #fffdfb;
  }
  *::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #b8b7bb;
    border-radius: 6px;
    border: 3px solid #fffdfb;
  }
  *::-webkit-scrollbar-track {
    background: #fffdfb;
  }
}
@media (hover: hover) and (pointer: fine) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #b8b7bb #fffdfb;
  }
  *::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #b8b7bb;
    border-radius: 6px;
    border: 3px solid #fffdfb;
  }
  *::-webkit-scrollbar-track {
    background: #fffdfb;
  }
}
.radio-checker {
  display: flex;
  align-items: center;
}
.radio-checker .radio-checker-button {
  background-color: #f6f4f0;
  color: #b8b7bb;
  margin-right: 0.625rem;
}
.radio-checker .radio-checker-button.active {
  color: #34303d;
  background-color: #00d1d2;
  border-width: 0;
}
.radio-checker .radio-checker-label {
  font-size: 1rem;
  color: #34303d;
}

.radio-checker-list {
  list-style: none;
  padding: 0;
  margin: 0 0 0 1.5rem;
}
.radio-checker-list > li {
  display: flex;
  align-items: center;
  padding: 0.3rem;
  color: #b8b7bb;
}
.radio-checker-list > li p {
  margin: 0;
}
.radio-checker-list > li i {
  margin-right: 0.625rem;
  font-size: 0.7rem;
}
.radio-checker-list > li.active {
  color: #34303d;
}
.radio-checker-list > li.active i {
  color: #00d1d2;
}
.radio-checker-list > li.active i::before {
  content: "\e913";
}

.inline-video {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
}
.inline-video.playing, .inline-video.paused {
  opacity: 1;
}

.inline-video-toggle {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none !important;
}
.inline-video.playing + .inline-video-toggle {
  opacity: 0;
}

.link-block-with-image {
  height: calc(66.7vw - 2.5rem);
}
@media (min-width: 576px) {
  .link-block-with-image {
    height: calc(49.7vw - 2.5rem);
  }
}
@media (min-width: 768px) {
  .link-block-with-image {
    height: calc(38vw - 2.375rem);
  }
}
@media (min-width: 992px) {
  .link-block-with-image {
    height: 14.5rem;
  }
}
.link-block-with-image img {
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.paragraph-block {
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem 1.075rem;
  background: rgba(0, 0, 0, 0.1);
}

.paragraph-block-with-bg {
  padding: 1rem 1.075rem;
  border-radius: 4px;
}

.paragraph-block-with-bg-red {
  background-color: rgba(219, 56, 38, 0.1);
}

.paragraph-block-with-bg-info {
  background-color: rgba(95, 206, 208, 0.1);
}

.paragraph-block-with-bg-gray {
  background-color: rgba(184, 183, 187, 0.2);
}

.ribbon {
  position: relative;
}
.ribbon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-top: 0 solid transparent;
  border-bottom: 7rem solid transparent;
  border-left: 4.62rem solid #00d1d2;
}
@media (min-width: 768px) {
  .ribbon::before {
    border-bottom: 10.625rem solid transparent;
    border-left: 6.90625rem solid #00d1d2;
  }
}

.pills-tabs {
  margin-bottom: 1rem;
}
.pills-tabs .nav-item {
  padding-right: 0.5rem;
}
.pills-tabs .nav-link {
  color: #34303d;
  padding: 0;
}
.pills-tabs .nav-link-text {
  padding-bottom: 0.25rem;
  font-weight: 700;
}
.pills-tabs .nav-link.active .nav-link-text {
  border-bottom: 2px solid #34303d;
}
.pills-tabs .tab-description {
  color: #7b7881;
  font-size: 0.875rem;
  padding-top: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .pills-tabs .tab-description {
    transition: none;
  }
}
.pills-tabs .nav-link.active + .tab-description {
  opacity: 1;
}

.text-slider {
  position: relative;
  overflow: hidden;
}

.text-slider-scene {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.text-slider-scene:first-child {
  position: relative;
  opacity: 1;
}

.fade-in-left, .hero-slider .swiper-slide.swiper-slide-active .hero-slider-slide-in-content-first,
.hero-slider .swiper-slide.swiper-slide-active .hero-slider-slide-in-content-second {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-1.5rem, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes skewedSlideInFirstLayer {
  0% {
    transform: translate3d(-60%, 0, 0) skew(-21deg);
  }
  100% {
    transform: translate3d(110%, 0, 0) skew(-21deg);
  }
}
@keyframes skewedSlideInSecondLayer {
  0% {
    transform: translate3d(-50%, 0, 0) skew(-21deg);
  }
  100% {
    transform: translate3d(1000%, 0, 0) skew(-21deg);
  }
}
.slide-in-left, .hero-slider .swiper-slide img {
  animation-name: slideInLeft;
  animation-duration: 1s;
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-15%, 0, 0) scale3d(1.2, 1.2, 1);
  }
  100% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}
.fade-in, .hero-slider-trio-blocks {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.hero-slider {
  min-height: calc(100vh - 3.5rem);
}
@media (min-width: 992px) {
  .hero-slider {
    height: calc(100vh - 3.5rem);
    min-height: 42.5rem;
    max-height: 46.875rem;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .hero-slider {
    height: calc(100vh - 4.75rem);
  }
}
.hero-slider.end-of-year-hero-slider {
  min-height: 100vw;
}
.hero-slider.end-of-year-hero-slider .hero-slider-navigation {
  top: 50%;
}
.hero-slider.end-of-year-hero-slider .hero-slider-image-container img {
  object-position: center -20vw;
}
@media (min-width: 992px) {
  .hero-slider.end-of-year-hero-slider {
    min-height: 42.5rem;
  }
  .hero-slider.end-of-year-hero-slider .hero-slider-image-container img {
    object-position: center;
  }
}
.hero-slider .swiper-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero-slider video.slide-video {
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: -10;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}
.hero-slider .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  overflow: hidden;
}
.hero-slider .cover::before {
  content: "";
  position: absolute;
  top: 0;
  left: -20%;
  transform-origin: top left;
  z-index: 4;
  animation-name: skewedSlideInSecondLayer, fadeOut;
  animation-duration: 2.5s, 1.25s;
  animation-fill-mode: both, both;
  animation-delay: 600ms, 750ms;
  width: 500%;
  height: 100%;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .hero-slider .cover::before {
    width: 40%;
  }
}
.hero-slider .cover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: top left;
  z-index: 3;
  animation-name: skewedSlideInFirstLayer;
  animation-duration: 2.5s;
  animation-fill-mode: both;
  animation-delay: 350ms;
  width: 500%;
  height: 100%;
  background: #fffdfb;
}
@media (min-width: 992px) {
  .hero-slider .cover::after {
    width: 180%;
  }
}
.hero-slider .swiper-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #00d1d2;
  width: 130%;
  height: 120%;
  transform: translate3d(-120%, 0, 0) skew(-21deg);
  transform-origin: top left;
  z-index: 3;
}
.hero-slider .swiper-slide.swiper-slide-active::before {
  transition: transform 1.5s;
  transform: translate3d(190%, 0, 0) skew(-21deg);
}
@media (min-width: 992px) {
  .hero-slider .swiper-slide.swiper-slide-active::before {
    transform: translate3d(120%, 0, 0) skew(-21deg);
  }
}
.hero-slider .swiper-slide.swiper-slide-active .hero-slider-slide-in-content-first,
.hero-slider .swiper-slide.swiper-slide-active .hero-slider-slide-in-content-second {
  opacity: 1;
}
.hero-slider .swiper-slide.swiper-slide-active .hero-slider-slide-in-content-first {
  animation-delay: 0.8s;
}
.hero-slider .swiper-slide.swiper-slide-active .hero-slider-slide-in-content-second {
  animation-delay: 1.3s;
}
.hero-slider .swiper-slide.swiper-slide-active .video-btn {
  pointer-events: auto;
}
.hero-slider .swiper-slide.video-slide video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.hero-slider .swiper-slide .video-btn {
  pointer-events: none;
}
.hero-slider .swiper-slide img {
  animation-delay: 0.3s;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.hero-slider .slider-overlay {
  position: relative;
  z-index: 2;
}
.hero-slider .slider-overlay img {
  max-width: 100%;
  height: auto !important;
}
.hero-slider .slider-overlay p {
  margin-bottom: 0;
}
.hero-slider .slider-overlay .video-button-slider-wrapper {
  position: relative;
  width: 100%;
  height: 100px;
  margin-top: 3%;
  margin-bottom: 9%;
}
.hero-slider .slider-overlay .video-button-slider-wrapper .video-btn-wrapper.video-btn-custom {
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 992px) {
  .hero-slider .slider-overlay .video-button-slider-wrapper .video-btn-wrapper.video-btn-custom {
    left: 55px;
  }
}

.hero-slider-slide-in-content-container {
  position: absolute;
  top: 6%;
  left: 0;
  right: 0;
}
@media (min-width: 992px) {
  .hero-slider-slide-in-content-container {
    top: 19%;
    width: 50%;
  }
}

.hero-slider-slide-in-content-first,
.hero-slider-slide-in-content-second {
  opacity: 0;
  transition: opacity 0.8s;
}

.hero-slider-navigation {
  z-index: 1;
  position: absolute;
  top: calc(105vw - 2.75rem);
  right: 4.5%;
  left: 4.5%;
}
@media (min-width: 992px) {
  .hero-slider-navigation {
    top: 50%;
  }
}
.hero-slider-navigation .swiper-button-prev,
.hero-slider-navigation .swiper-button-next {
  cursor: pointer;
}
.hero-slider-navigation .swiper-button-light.swiper-button-disabled {
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
}

.hero-slider-trio-blocks {
  animation-delay: 2s;
  z-index: 10;
  position: relative;
  margin-top: 105vw;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .hero-slider-trio-blocks {
    position: absolute;
    bottom: 3rem;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero-slider-trio-blocks-container {
  background-color: rgba(52, 48, 61, 0.3);
  border-radius: 0.25rem;
}

@media (min-width: 992px) {
  .hero-slider-trio-blocks-5-column {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}

.hero-slider-trio-blocks-block-content-background {
  background-color: #fffdfb;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-slider-trio-blocks-block-content-container {
  width: 100%;
  height: 100%;
  min-height: 6.25rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  transition: color 0.4s;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 5.25rem;
  background-origin: content-box;
}
@media (min-width: 992px) {
  .hero-slider-trio-blocks-block-content-container {
    min-height: 8.75rem;
    background-size: 7.125rem;
  }
}
.hero-slider-trio-blocks-block-content-container > * {
  z-index: 2;
}

.hero-slider-trio-blocks-block-content-image {
  position: absolute;
  right: 0;
  transform: translate3d(0, -50%, 0);
  top: 50%;
}
.hero-slider-trio-blocks-block-content-image > svg {
  width: 5.25rem;
  height: auto;
}
@media (min-width: 992px) {
  .hero-slider-trio-blocks-block-content-image > svg {
    width: 7.125rem;
  }
}
.hero-slider-trio-blocks-block-content-image > svg .hover-stroke {
  transition: stroke 0.4s;
}
.hero-slider-trio-blocks-block-content-image > svg .hover-fill {
  transition: fill 0.4s;
}

.hero-slider-trio-blocks-block-background {
  position: absolute;
  top: 0;
  left: 0;
  background: #34303d;
  width: 180%;
  height: 120%;
  transition: transform 0.4s ease-in-out;
  transform: translate3d(-125%, 0, 0) skew(-21deg);
  z-index: 1;
}

.hero-slider-trio-blocks-block {
  overflow: hidden;
  position: relative;
  display: block;
  border-radius: 0.25rem;
  height: 100%;
}
.hero-slider-trio-blocks-block:hover {
  text-decoration: none;
}
.hero-slider-trio-blocks-block:hover .hero-slider-trio-blocks-block-content-container {
  color: #fffdfb;
}
.hero-slider-trio-blocks-block:hover .hero-slider-trio-blocks-block-content-image .hover-stroke {
  stroke: #fffdfb;
}
.hero-slider-trio-blocks-block:hover .hero-slider-trio-blocks-block-content-image .hover-fill {
  fill: #fffdfb;
}
.hero-slider-trio-blocks-block:hover .hero-slider-trio-blocks-block-background {
  transform: translate3d(-25%, 0, 0) skew(-21deg);
}

.hero-slider-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-slider-slide-title {
  font-weight: 700;
  line-height: 1.2;
}

.separator-block {
  width: 3rem;
  height: 0.375rem;
  background-color: #00d1d2;
}

.sticky-bar {
  z-index: 1030;
  position: fixed;
  left: 0;
  right: 0;
  opacity: 0;
  transition: transform 0.45s ease, opacity 0.3s ease, opacity 0.15s 0.45s linear;
}
.sticky-bar.sticky-bar-visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.45s ease, opacity 0.3s ease;
}
.sticky-bar .sticky-bar-image {
  width: 2.75rem;
  height: auto;
}
@media (min-width: 992px) {
  .sticky-bar .sticky-bar-image {
    width: 3.75rem;
  }
}

.sticky-bar-top {
  top: 3.5rem;
  bottom: auto;
  transform: translate3d(0, -100%, 0);
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05);
}
@media (min-width: 1200px) {
  .sticky-bar-top {
    top: 4.75rem;
  }
}
.scroll-down .sticky-bar-top {
  transform: translate3d(0, -3.5rem, 0) translate3d(0, -100%, 0);
}
@media (min-width: 1200px) {
  .scroll-down .sticky-bar-top {
    transform: translate3d(0, -4.75rem, 0) translate3d(0, -100%, 0);
  }
}
.scroll-down .sticky-bar-top.sticky-bar-visible {
  transform: translate3d(0, -3.5rem, 0);
}
@media (min-width: 1200px) {
  .scroll-down .sticky-bar-top.sticky-bar-visible {
    transform: translate3d(0, -4.75rem, 0);
  }
}
.navbar-promo-active .sticky-bar-top {
  top: 6.625rem;
}
@media (min-width: 1200px) {
  .navbar-promo-active .sticky-bar-top {
    top: 7.875rem;
  }
}

.sticky-bar-bottom {
  top: auto;
  bottom: 0;
  transform: translate3d(0, 100%, 0);
  box-shadow: 0 -0.125rem 0.375rem rgba(0, 0, 0, 0.05);
}

@media (min-width: 576px) {
  .sticky-bar-sm-top {
    top: 3.5rem;
    bottom: auto;
    transform: translate3d(0, -100%, 0);
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 576px) and (min-width: 1200px) {
  .sticky-bar-sm-top {
    top: 4.75rem;
  }
}
@media (min-width: 576px) {
  .scroll-down .sticky-bar-sm-top {
    transform: translate3d(0, -3.5rem, 0) translate3d(0, -100%, 0);
  }
}
@media (min-width: 576px) and (min-width: 1200px) {
  .scroll-down .sticky-bar-sm-top {
    transform: translate3d(0, -4.75rem, 0) translate3d(0, -100%, 0);
  }
}
@media (min-width: 576px) {
  .scroll-down .sticky-bar-sm-top.sticky-bar-visible {
    transform: translate3d(0, -3.5rem, 0);
  }
}
@media (min-width: 576px) and (min-width: 1200px) {
  .scroll-down .sticky-bar-sm-top.sticky-bar-visible {
    transform: translate3d(0, -4.75rem, 0);
  }
}
@media (min-width: 576px) {
  .navbar-promo-active .sticky-bar-sm-top {
    top: 6.625rem;
  }
}
@media (min-width: 576px) and (min-width: 1200px) {
  .navbar-promo-active .sticky-bar-sm-top {
    top: 7.875rem;
  }
}
@media (min-width: 576px) {
  .sticky-bar-sm-bottom {
    top: auto;
    bottom: 0;
    transform: translate3d(0, 100%, 0);
    box-shadow: 0 -0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 768px) {
  .sticky-bar-md-top {
    top: 3.5rem;
    bottom: auto;
    transform: translate3d(0, -100%, 0);
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .sticky-bar-md-top {
    top: 4.75rem;
  }
}
@media (min-width: 768px) {
  .scroll-down .sticky-bar-md-top {
    transform: translate3d(0, -3.5rem, 0) translate3d(0, -100%, 0);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .scroll-down .sticky-bar-md-top {
    transform: translate3d(0, -4.75rem, 0) translate3d(0, -100%, 0);
  }
}
@media (min-width: 768px) {
  .scroll-down .sticky-bar-md-top.sticky-bar-visible {
    transform: translate3d(0, -3.5rem, 0);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .scroll-down .sticky-bar-md-top.sticky-bar-visible {
    transform: translate3d(0, -4.75rem, 0);
  }
}
@media (min-width: 768px) {
  .navbar-promo-active .sticky-bar-md-top {
    top: 6.625rem;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .navbar-promo-active .sticky-bar-md-top {
    top: 7.875rem;
  }
}
@media (min-width: 768px) {
  .sticky-bar-md-bottom {
    top: auto;
    bottom: 0;
    transform: translate3d(0, 100%, 0);
    box-shadow: 0 -0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 992px) {
  .sticky-bar-lg-top {
    top: 3.5rem;
    bottom: auto;
    transform: translate3d(0, -100%, 0);
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .sticky-bar-lg-top {
    top: 4.75rem;
  }
}
@media (min-width: 992px) {
  .scroll-down .sticky-bar-lg-top {
    transform: translate3d(0, -3.5rem, 0) translate3d(0, -100%, 0);
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .scroll-down .sticky-bar-lg-top {
    transform: translate3d(0, -4.75rem, 0) translate3d(0, -100%, 0);
  }
}
@media (min-width: 992px) {
  .scroll-down .sticky-bar-lg-top.sticky-bar-visible {
    transform: translate3d(0, -3.5rem, 0);
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .scroll-down .sticky-bar-lg-top.sticky-bar-visible {
    transform: translate3d(0, -4.75rem, 0);
  }
}
@media (min-width: 992px) {
  .navbar-promo-active .sticky-bar-lg-top {
    top: 6.625rem;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .navbar-promo-active .sticky-bar-lg-top {
    top: 7.875rem;
  }
}
@media (min-width: 992px) {
  .sticky-bar-lg-bottom {
    top: auto;
    bottom: 0;
    transform: translate3d(0, 100%, 0);
    box-shadow: 0 -0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 1200px) {
  .sticky-bar-xl-top {
    top: 3.5rem;
    bottom: auto;
    transform: translate3d(0, -100%, 0);
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .sticky-bar-xl-top {
    top: 4.75rem;
  }
}
@media (min-width: 1200px) {
  .scroll-down .sticky-bar-xl-top {
    transform: translate3d(0, -3.5rem, 0) translate3d(0, -100%, 0);
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .scroll-down .sticky-bar-xl-top {
    transform: translate3d(0, -4.75rem, 0) translate3d(0, -100%, 0);
  }
}
@media (min-width: 1200px) {
  .scroll-down .sticky-bar-xl-top.sticky-bar-visible {
    transform: translate3d(0, -3.5rem, 0);
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .scroll-down .sticky-bar-xl-top.sticky-bar-visible {
    transform: translate3d(0, -4.75rem, 0);
  }
}
@media (min-width: 1200px) {
  .navbar-promo-active .sticky-bar-xl-top {
    top: 6.625rem;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .navbar-promo-active .sticky-bar-xl-top {
    top: 7.875rem;
  }
}
@media (min-width: 1200px) {
  .sticky-bar-xl-bottom {
    top: auto;
    bottom: 0;
    transform: translate3d(0, 100%, 0);
    box-shadow: 0 -0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  }
}
.sub-navbar-shadow {
  box-shadow: inset 0 9px 34px -40px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.navbar-promo-active .sub-navbar {
  top: 6.625rem;
}
@media (min-width: 1200px) {
  .navbar-promo-active .sub-navbar {
    top: 7.875rem;
  }
}
.sub-navbar .logo {
  width: 6.25rem;
  max-height: 2.375rem;
}
@media (min-width: 992px) {
  .sub-navbar .logo {
    width: auto;
  }
}
.sub-navbar .logo-image {
  width: 1.5rem;
  min-width: 1.5rem;
}
@media (min-width: 992px) {
  .sub-navbar .logo-image {
    width: 2.25rem;
    min-width: 2.25rem;
  }
}
.sub-navbar .sub-navbar-header a {
  display: none;
  position: relative;
  pointer-events: none;
  padding: 1.09375rem 1.25rem 1.09375rem 0;
  letter-spacing: 0.03em;
}
@media (min-width: 1200px) {
  .sub-navbar .sub-navbar-header a {
    display: flex;
    pointer-events: visible;
    padding: 1.59375rem 1.5rem 1.59375rem 0;
  }
  .sub-navbar .sub-navbar-header a:after {
    content: " ";
    display: block;
    width: calc(100% - 1.5rem);
    bottom: 0;
    left: 0;
    right: 0;
    background: #34303d;
    height: 3px;
    position: absolute;
    transform: scale3d(0, 1, 1);
    transform-origin: center center;
    transition: transform 0.4s ease-out;
  }
}
.sub-navbar .sub-navbar-header a.active {
  display: block;
  text-overflow: ellipsis;
  max-width: 50vw;
  white-space: nowrap;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .sub-navbar .sub-navbar-header a.active {
    display: flex;
    font-weight: bold;
  }
  .sub-navbar .sub-navbar-header a.active:after {
    transform: scale3d(1, 1, 1);
  }
}
.sub-navbar .sub-navbar-toggle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  margin-bottom: 0;
}
.sub-navbar .sub-navbar-toggle button {
  display: flex;
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
}
.sub-navbar .sub-navbar-toggle button.active > .nbw-icon-chevron-down {
  transform: scaleY(-1);
}
.sub-navbar .sub-navbar-toggle button .nbw-icon-chevron-down {
  transition: transform 0.2s linear;
}

.sub-navbar-collapse {
  display: none;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fffdfb;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .sub-navbar-collapse.show {
    display: flex;
  }
}
.sub-navbar-collapse > .sub-navbar-collapse-body {
  max-height: calc(var(--vh, 1vh) * 100 - 3.5rem - 3.5rem);
  overflow-y: auto;
}
.navbar-promo-active .sub-navbar-collapse > .sub-navbar-collapse-body {
  max-height: calc(var(--vh, 1vh) * 100 - 3.5rem - 6.625rem);
}

@media (max-width: 1199.98px) {
  .sub-navbar-collapse {
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s ease;
  }
  .sub-navbar-collapse .nav-link {
    opacity: 0;
    transform: translate3d(-2rem, 0, 0);
    transition: transform 0.3s ease, opacity 0.3s linear;
    transition-delay: 0.3s;
  }
  .sub-navbar-collapse.enter-to {
    transform: translate3d(0, 0, 0);
  }
  .sub-navbar-collapse.enter-to .nav-link {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .sub-navbar-collapse.leave-to {
    transition-delay: 0.3s;
  }
  .sub-navbar-collapse.leave-to .nav-link {
    transition-delay: 0s;
  }
}

.sub-navbar-nav {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  list-style: none;
  font-size: 1rem;
  padding: 1.5rem 2rem 2rem;
}
.sub-navbar-nav .nav-link {
  display: flex;
  padding: 0;
  color: #34303d;
}
.sub-navbar-nav .nav-link-title-container {
  flex-grow: 1;
}
.sub-navbar-nav .nav-link-title {
  display: inline-block;
  position: relative;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 700;
}
.sub-navbar-nav .nav-link-extra {
  font-size: 0.875rem;
}
.sub-navbar-nav > .nav-item {
  display: inline;
}
.sub-navbar-nav > .nav-item > .nav-link {
  margin-bottom: 1.5rem;
}
.sub-navbar-nav > .nav-item:last-child > .nav-link {
  margin-bottom: 0;
}

.sub-navbar-header {
  position: relative;
  z-index: 2;
}

.sub-navbar-backdrop {
  z-index: 1020;
}

.sub-navbar-open {
  overflow: hidden;
}

.banner-slider-container {
  margin: 0 -2rem;
  min-height: 13.125rem;
}
@media (min-width: 576px) {
  .banner-slider-container {
    min-height: 6.25rem;
  }
}
@media (min-width: 992px) {
  .banner-slider-container {
    margin: 0;
  }
}
.banner-slider-container .swiper-wrapper .timer-wrapper {
  opacity: 0;
  transition: opacity 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .banner-slider-container .swiper-wrapper .timer-wrapper {
    transition: none;
  }
}
.banner-slider-container .swiper-wrapper .timer-wrapper.timer-loaded {
  opacity: 1;
}

.large-nav-block {
  margin: 0 auto;
  max-width: 28.75rem;
}
.large-nav-block a {
  color: #524f5a;
  padding: 0.438rem 0.315rem;
}
.large-nav-block .active {
  color: #34303d;
  position: relative;
}
.large-nav-block .active::before {
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #00d1d2;
  height: 2px;
}

.code-block {
  padding: 0.313rem 0.625rem;
  background: rgba(255, 253, 251, 0.25);
  width: 50%;
}
@media (min-width: 576px) {
  .code-block {
    width: auto;
    padding: 0.75rem 1rem;
  }
}

.how-product-works-scene.active .pointer::before {
  background-color: #00d1d2;
  transform: scale3d(1, 1, 1);
}
@media (min-width: 992px) {
  .how-product-works-scene.active .pointer::after {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }
}
@media (min-width: 992px) {
  .how-product-works-scene.active .pointer-line {
    transform: scale3d(1, 1, 1);
  }
}

@media (min-width: 992px) {
  .pointer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.pointer::before {
  display: block;
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #524f5a;
  padding: 0.375rem;
  text-align: center;
  content: "\e90e";
  transform: scale3d(0.8, 0.8, 1);
  transition-property: background-color, transform;
  transition-duration: 0.75s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
@media (min-width: 992px) {
  .pointer::before {
    background-color: #00d1d2;
    transform: scale3d(0, 0, 1);
  }
}
@media (min-width: 992px) {
  .pointer::after {
    display: block;
    position: absolute;
    top: -1rem;
    left: -1rem;
    opacity: 1;
    border: 1px solid #fffdfb;
    border-radius: 50%;
    width: 3.75rem;
    height: 3.75rem;
    content: "";
    transform: scale3d(0, 0, 1);
    transition-property: opacity, transform;
    transition-duration: 0.75s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }
}

@media (min-width: 992px) {
  .pointer-line {
    z-index: -1;
    display: block;
    position: absolute;
    top: 50%;
    border: none;
    height: 1px;
    background-color: #524f5a;
    transform: scale3d(0, 1, 1);
    transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

@media (min-width: 992px) {
  .pointer-line-left {
    right: 50%;
    left: -20rem;
    transform-origin: right center;
  }
}

@media (min-width: 992px) {
  .pointer-line-right {
    right: -20rem;
    left: 50%;
    transform-origin: left center;
  }
}

.product-usps {
  position: relative;
}
@media (max-width: 1199.98px) {
  .product-usps {
    background-color: transparent !important;
    color: inherit !important;
  }
}

.product-usps-inner-wrapper {
  width: 100% !important;
  height: 100vh;
}

.product-usps-scene {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  height: 100vh;
  width: 100%;
  transition: opacity 0.75s cubic-bezier(0, 0, 0, 1);
}
.product-usps-scene.active {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
}

.product-usps-container {
  z-index: 1;
  position: relative;
}
@media (min-width: 992px) {
  .product-usps-container {
    top: 50%;
    transform: translateY(-50%);
  }
}

.product-usps-text {
  opacity: 0;
  position: relative;
  padding-top: 3.5rem;
  padding-bottom: 1rem;
  transform: translate3d(0, -0.5rem, 0);
  transition-property: opacity, transform;
  transition-duration: 0.75s;
  transition-timing-function: cubic-bezier(0.3, 0, 0, 1);
}
@media (min-width: 992px) {
  .product-usps-text {
    padding-top: 0;
    padding-bottom: 0;
    transform: translate3d(0, -50%, 0);
  }
}
.product-usps-scene.active .product-usps-text {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.product-usps-text-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}
@media (min-width: 992px) {
  .product-usps-text-title {
    margin-top: 0;
    margin-bottom: 2.5rem;
    font-size: 6rem;
    line-height: 1.08;
    letter-spacing: -1.28px;
  }
}

.product-usps-text-description {
  transform: translate3d(0, 1.5rem, 0);
  transition: transform 0.75s cubic-bezier(0.3, 0, 0, 1);
}
.product-usps-scene.active .product-usps-text-description {
  transform: translate3d(0, 0, 0);
}

.product-usps-video-wrapper {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
}
@media (min-width: 992px) {
  .product-usps-video-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.product-usps-video {
  width: 100%;
  height: auto;
  min-height: 100%;
  object-fit: cover;
}

.product-usps-progress {
  position: absolute;
  bottom: 7rem;
  left: 2rem;
}
@media (min-width: 992px) {
  .product-usps-progress {
    top: 50%;
    right: 2rem;
    bottom: auto;
    left: auto;
    transform: translateY(-50%);
  }
}

.how-product-works {
  position: relative;
  background-color: #34303d;
  overflow: hidden;
}

@media (min-width: 992px) {
  .how-product-works-features {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

.how-product-works-inner-wrapper {
  width: 100% !important;
  height: 100vh;
}

.how-product-works-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-height: 55%;
  padding-top: 5.125rem;
  padding-bottom: 2rem;
}
@media (min-width: 992px) {
  .how-product-works-container {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transform: translateY(-50%);
  }
}
@media (min-width: 992px) {
  .how-product-works-container.how-product-works-container-with-features {
    padding-bottom: 15vh;
  }
}

.how-product-works-scene-image-container {
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 3rem;
}
@media (min-width: 576px) {
  .how-product-works-scene-image-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media (min-width: 992px) {
  .how-product-works-scene-image-container {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-bottom: 0;
    transform: translate3d(0, 0, 0);
    transition-property: margin, transform;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }
  .how-product-works.active .how-product-works-scene-image-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .how-product-works-scene.active .how-product-works-scene-image-container {
    transform: translate3d(0, -3rem, 0);
  }
  .how-product-works-scene:first-child .how-product-works-scene-image-container {
    margin-left: 0;
  }
  .how-product-works-scene:last-child .how-product-works-scene-image-container {
    margin-right: 0;
  }
}
.how-product-works-scene-image-container > picture {
  display: block;
  height: 100%;
  margin-bottom: 1.25rem;
}
@media (min-width: 992px) {
  .how-product-works-scene-image-container > picture {
    margin-bottom: 0;
  }
}

.how-product-works-scene-image {
  opacity: 1;
  display: block;
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.how-product-works.active .how-product-works-scene:not(.active) .how-product-works-scene-image {
  opacity: 0.2;
}

.how-product-works-scene-text-container {
  position: absolute;
  right: 0;
  left: 0;
}
@media (max-width: 991.98px) {
  .how-product-works-scene-text-container {
    top: 55% !important;
  }
}
@media (min-width: 992px) {
  .how-product-works-scene-text-container {
    margin-top: -0.875rem;
    transform: translate3d(0, 0, 0);
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .how-product-works-scene.active .how-product-works-scene-text-container {
    transform: translate3d(0, -3rem, 0);
  }
}

.how-product-works-scene-text {
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-delay: 0s, 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate3d(0, 1.25rem, 0);
  color: #fffdfb;
}
.how-product-works-scene.active .how-product-works-scene-text {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

@media (min-width: 992px) {
  .how-product-works-scene-text-title {
    display: inline-block;
    position: relative;
  }
  .how-product-works-scene-text-title::before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: -1rem;
    right: -1rem;
    bottom: 0;
    background-color: #34303d;
    content: "";
  }
  .how-product-works-scene-text-left .how-product-works-scene-text-title::before {
    left: -100vw;
  }
  .how-product-works-scene-text-right .how-product-works-scene-text-title::before {
    right: -100vw;
  }
}

.how-product-works-scene-text-description {
  line-height: 3em;
  transition: line-height 0.5s 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.how-product-works-scene.active .how-product-works-scene-text-description {
  line-height: 1.5em;
  transition-delay: 0s;
}

.how-product-works-progress {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}

.scene-progress-bar {
  overflow: hidden;
  position: relative;
  display: block;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  height: 1rem;
  width: 0.125rem;
}
@media (min-width: 768px) {
  .scene-progress-bar {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
    height: 1.375rem;
  }
}
.scene-progress-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.scene-progress-bar.active::before {
  transform: translate3d(0, 0, 0);
}
.scene-progress-dark .scene-progress-bar {
  background-color: rgba(52, 48, 61, 0.3);
}
.scene-progress-dark .scene-progress-bar::before {
  background-color: #34303d;
}
.scene-progress-light .scene-progress-bar {
  background-color: rgba(255, 253, 251, 0.2);
}
.scene-progress-light .scene-progress-bar::before {
  background-color: #fffdfb;
}

.ordered-list-large {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: counter;
}
.ordered-list-large li {
  position: relative;
  margin-bottom: 1.5rem;
  padding-left: 3.125rem;
}
@media (min-width: 992px) {
  .ordered-list-large li {
    padding-left: 3.625rem;
  }
}
.ordered-list-large li::before {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  background-color: #34303d;
  padding: 0.3125rem 0;
  text-align: center;
  font-weight: 700;
  color: #fff;
  counter-increment: counter;
  content: counter(counter);
}
.ordered-list-large li picture {
  margin-left: -3.125rem;
}
@media (min-width: 992px) {
  .ordered-list-large li picture {
    margin-left: 0;
  }
}

.modal-video {
  background-color: rgba(82, 79, 90, 0.85);
}
.modal-video .modal-video-close-btn {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  right: 0;
}
.modal-video .modal-video-close-btn::before, .modal-video .modal-video-close-btn::after {
  height: 1px;
  margin-top: 0;
}
@media (max-width: 1199.98px) {
  .modal-video .modal-video-close-btn {
    top: calc(50% + 2rem - 50vh);
  }
}

.modal-video-body {
  max-width: 1086px;
  padding: 0 2rem;
}
@media (min-width: 1200px) {
  .modal-video-body {
    padding: 0;
  }
}

#onetrust-banner-sdk {
  animation: none !important;
}
#onetrust-banner-sdk.ot-wo-title.bottom {
  bottom: 0 !important;
}
#onetrust-banner-sdk #onetrust-pc-btn-handler:focus:after,
#onetrust-banner-sdk #onetrust-reject-all-handler:focus:after,
#onetrust-banner-sdk #onetrust-accept-btn-handler:focus:after {
  transform: translate(-10%, -50%) skew(-30deg);
}
#onetrust-banner-sdk a:focus {
  outline-color: #2980b5 !important;
}

.ratings-and-reviews {
  max-width: 1374px;
  margin: 0 auto;
}

.rating-summary {
  min-height: 17px;
}

.inline-rating {
  min-height: 1.5rem;
}

.bv-cv2-cleanslate .bv-content-list-container .bv-header .bv-action-bar .bv-action-bar-header {
  font-size: 1.375rem !important;
}
@media (min-width: 992px) {
  .bv-cv2-cleanslate .bv-content-list-container .bv-header .bv-action-bar .bv-action-bar-header {
    font-size: 2rem !important;
  }
}
.bv-cv2-cleanslate .bv-shared .bv-header .bv-section-summary {
  margin-bottom: 2.5rem !important;
}
.bv-cv2-cleanslate .bv-shared .bv-header .bv-section-summary .bv-section-summary-inline .bv-inline-histogram-ratings {
  margin-bottom: 2.375rem !important;
}
@media (min-width: 992px) {
  .bv-cv2-cleanslate .bv-shared .bv-header .bv-section-summary .bv-section-summary-inline .bv-inline-histogram-ratings {
    margin-bottom: 3.125rem !important;
  }
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search {
  margin-bottom: 4.375rem !important;
  background: transparent !important;
  box-shadow: none !important;
  border-bottom: 0 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search .bv-masthead {
  background: transparent !important;
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search .bv-masthead .bv-masthead-product {
  padding-left: 0 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search .bv-masthead .bv-masthead-product .bv-stars-container {
  background: transparent !important;
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search .bv-masthead .bv-masthead-product .bv-stars-container .bv-rating-stars-on {
  color: #e5b052 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search .bv-masthead .bv-masthead-product .bv-secondary-summary .bv-percent-recommend-container {
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  color: #34303d !important;
  font-weight: normal !important;
  padding: 0 !important;
  margin: 0 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search .bv-masthead-search .bv-fieldsets {
  padding-left: 0 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search .bv-masthead-search .bv-fieldsets input.bv-text {
  padding: 0.5rem 1.125rem !important;
  border: 1px solid #e1e0e2 !important;
  border-right: 0 !important;
  box-shadow: none !important;
  font-size: 0.8rem !important;
  color: #34303d !important;
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search .bv-masthead-search .bv-content-search-btn {
  float: none !important;
  position: relative !important;
  width: auto !important;
  height: 43px !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  border: 1px solid #e1e0e2 !important;
  border-left: 0 !important;
  background: #fff !important;
  padding: 0 1.25rem !important;
  font-weight: 100 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-search .bv-generic-submission .bv-fieldsets {
  padding-left: 0 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-inline-histogram-ratings-bar .bv-content-secondary-ratings-container {
  background-color: #e1e0e2 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  background-image: none !important;
  box-shadow: none !important;
}
.bv-cv2-cleanslate .bv-shared .bv-inline-histogram-ratings-bar .bv-content-secondary-ratings-container .bv-content-secondary-ratings-value {
  background-color: #34303d !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  background-image: none !important;
  box-shadow: none !important;
}
.bv-cv2-cleanslate .bv-shared .bv-content-secondary-ratings-container .bv-content-secondary-ratings-value {
  background-color: #34303d !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  background-image: none !important;
  box-shadow: none !important;
}
.bv-cv2-cleanslate .bv-shared .bv-secondary-rating-summary-bars .bv-secondary-rating-summary-value,
.bv-cv2-cleanslate .bv-shared .bv-secondary-rating-summary-bars .bv-secondary-slider-summary-value,
.bv-cv2-cleanslate .bv-shared .bv-secondary-rating-summary-bars .bv-content-slider-value {
  background-color: #34303d !important;
  background-image: none !important;
  box-shadow: none !important;
}
.bv-cv2-cleanslate .bv-shared .bv-content-list-container .bv-header {
  padding: 1.875rem 2rem 1.875rem !important;
}
@media (min-width: 992px) {
  .bv-cv2-cleanslate .bv-shared .bv-content-list-container .bv-header {
    padding: 3.125rem 2rem 1.875rem !important;
  }
}
.bv-cv2-cleanslate .bv-shared .bv-inline-histogram-ratings .bv-flex-container-column {
  margin: 0 !important;
  margin-top: 1.75rem !important;
  width: 100% !important;
}
@media (min-width: 992px) {
  .bv-cv2-cleanslate .bv-shared .bv-inline-histogram-ratings .bv-flex-container-column {
    width: 85% !important;
  }
}
.bv-cv2-cleanslate .bv-shared .bv-glyph {
  color: #e5b052 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-rating-stars-container .bv-rating-stars {
  letter-spacing: 0.375rem !important;
}
.bv-cv2-cleanslate .bv-shared .bv-content-list .bv-content-item {
  padding: 0 2rem !important;
  margin-bottom: 1rem !important;
}
@media (min-width: 715px) {
  .bv-cv2-cleanslate .bv-shared .bv-content-list .bv-content-item {
    padding: 2.5rem !important;
    margin: 0 2rem 1rem !important;
  }
}
.bv-cv2-cleanslate .bv-shared .bv-content-item.bv-content-review .bv-author-profile {
  padding: 0 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-content-item.bv-content-review .bv-author-profile .bv-inline-profile .bv-author-avatar {
  margin-bottom: 2.875rem !important;
}
@media (max-width: 1199.98px) {
  .bv-cv2-cleanslate .bv-shared .bv-content-item-author-profile-offset-on .bv-content-header .bv-content-rating {
    margin-bottom: 1rem !important;
  }
  .bv-cv2-cleanslate .bv-shared .bv-content-item-author-profile-offset-on .bv-content-header .bv-content-meta-wrapper {
    margin-bottom: 1.25rem !important;
  }
}
.bv-cv2-cleanslate .bv-shared .bv-content-core .bv-content-summary .bv-content-summary-body-text {
  margin-bottom: 2rem !important;
}
.bv-cv2-cleanslate .bv-shared .bv-content-data-label-container .bv-content-data-icon {
  width: 1.5rem !important;
  height: 1.5rem !important;
  position: relative !important;
  flex-shrink: 0 !important;
  margin-right: 0.8rem !important;
  background-color: #00d1d2 !important;
  transform: rotate(45deg) !important;
  border-radius: 50% !important;
  display: inline-block !important;
  vertical-align: middle !important;
  text-indent: -9999px !important;
  line-height: 0 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-content-data-label-container .bv-content-data-icon:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: auto;
  left: auto;
  width: 0.3rem;
  height: 0.1rem;
  background: #34303d;
  transform: translate(1px, 2px);
}
.bv-cv2-cleanslate .bv-shared .bv-content-data-label-container .bv-content-data-icon:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: auto;
  left: auto;
  width: 0.1rem;
  height: 0.6rem;
  background: #34303d;
  transform: translate(2px, -6px);
}
.bv-cv2-cleanslate .bv-shared .bv-content-title {
  font-weight: 700 !important;
  margin-bottom: 0.625rem !important;
}
.bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary .bv-secondary-rating-summary-bars-container .bv-secondary-rating-summary-container .bv-secondary-rating-summary-bars {
  background-color: #e1e0e2 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  background-image: none !important;
  box-shadow: none !important;
}
.bv-cv2-cleanslate .bv-content-item .bv-secondary-ratings .bv-content-secondary-ratings .bv-popup-histogram-ratings-bar .bv-content-secondary-ratings-container {
  background-color: #e1e0e2 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  background-image: none !important;
  box-shadow: none !important;
}
.bv-cv2-cleanslate .bv-content-item .bv-content-item-author-profile-offset-on {
  margin-top: 0 !important;
}
.bv-cv2-cleanslate .bv-content-filter.bv-filter-controls-visible .bv-filter-controls .bv-filters {
  padding: 15px 2rem !important;
}
.bv-cv2-cleanslate .bv-content-filter.bv-filter-controls-visible .bv-active-filters {
  padding: 10px 2rem !important;
}
.bv-cv2-cleanslate .bv-section-summary .bv-secondary-rating-summary-header .bv-content-title {
  margin-bottom: 0.75rem !important;
}
@media (min-width: 992px) {
  .bv-cv2-cleanslate .bv-section-summary .bv-secondary-rating-summary-header .bv-content-title {
    margin-bottom: 2.375rem !important;
  }
}
.bv-cv2-cleanslate .bv-mbox-inner .bv-mbox-wide .bv-mbox {
  background-color: #34303d !important;
}
.bv-cv2-cleanslate .bv-profull-sidebar .bv-author-profile-userinfo .bv-author-userstats-list .bv-author-userstats-data,
.bv-cv2-cleanslate .bv-profull-sidebar .bv-author-profile-userinfo .bv-author-userstats-list .bv-author-userstats-value,
.bv-cv2-cleanslate .bv-mbox-fullprofile .bv-author-profile-userinfo .bv-author-userstats-list .bv-author-userstats-data,
.bv-cv2-cleanslate .bv-mbox-fullprofile .bv-author-profile-userinfo .bv-author-userstats-list .bv-author-userstats-value {
  color: #fff !important;
}
.bv-cv2-cleanslate .bv-section-summary .bv-section-summary-inline .bv-section-summary-block .bv-inline-histogram-ratings .bv-histogram-filter-helper {
  margin-left: 0 !important;
  margin-bottom: 2.5rem !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}
@media (min-width: 992px) {
  .bv-cv2-cleanslate .bv-section-summary .bv-section-summary-inline .bv-section-summary-block .bv-inline-histogram-ratings .bv-histogram-filter-helper {
    margin-bottom: 0.25rem !important;
  }
}
.bv-cv2-cleanslate .bv-content-data-summary .bv-content-header-meta {
  margin-bottom: 0.375rem !important;
}
.bv-cv2-cleanslate .bv-header .bv-action-bar {
  padding-top: 0.125rem !important;
  padding-bottom: 1rem !important;
}
@media (min-width: 992px) {
  .bv-cv2-cleanslate .bv-header .bv-action-bar {
    padding-top: 1.25rem !important;
    padding-bottom: 1.75rem !important;
  }
}
.bv-cv2-cleanslate .bv-header .bv-control-bar {
  border-bottom: 1px solid #e1e0e2 !important;
}
.bv-cv2-cleanslate .bv-header .bv-section-summary .bv-inline-histogram-ratings-star-container.bv-flex-container {
  margin-bottom: 0.75rem !important;
}
@media (min-width: 992px) {
  .bv-cv2-cleanslate .bv-header .bv-section-summary .bv-inline-histogram-ratings-star-container.bv-flex-container {
    margin-bottom: 3px !important;
  }
}
.bv-cv2-cleanslate .bv-content-list-container .bv-content-placeholder {
  padding: 3.125rem 2rem 1.875rem !important;
}
.bv-cv2-cleanslate .bv-content-list-container .bv-content-placeholder .bv-rating-stars-container.bv-rating-none {
  margin-bottom: 0.375rem !important;
  margin-top: 0 !important;
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn {
  height: 2.75rem !important;
  width: 2.75rem !important;
  border: 0 !important;
  border-radius: 50% !important;
  padding: 0 !important;
  background-color: #fff !important;
  z-index: 1 !important;
  overflow: hidden !important;
  position: relative !important;
  transition: color 0.2s, opacity 0.3s !important;
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-active::before {
  background-color: #00d1d2;
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-active:hover, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-active:focus, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-active.focus .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-active:not(:disabled):not(.disabled):active, .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-active:not(:disabled):not(.disabled).active {
  outline: 0 !important;
  color: #34303d !important;
  opacity: 1 !important;
  box-shadow: none !important;
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-first {
  margin-right: 1.25rem !important;
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-inactive {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-inactive .bv-content-btn-pages-prev::before,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn.bv-content-btn-pages-inactive .bv-content-btn-pages-next::before {
  color: #7b7881;
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn-pages-prev,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn-pages-next {
  color: transparent !important;
  border: 0;
  background-color: #fff;
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn-pages-prev:before,
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn-pages-next:before {
  position: absolute;
  color: #34303d;
  font-size: 1.25rem;
  content: "\e906";
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn-pages-prev::before {
  content: "\e905";
}
.bv-cv2-cleanslate .bv-content-pagination-container .bv-content-pagination-buttons .bv-content-pagination-buttons-item .bv-content-btn-pages-next::before {
  content: "\e906";
}
.bv-cv2-cleanslate .bv-content-pagination .bv-content-pagination-container {
  margin: 0 2rem 1rem !important;
}
.bv-cv2-cleanslate .bv-inline-histogram-ratings-explanation {
  margin-top: 0.75rem !important;
  cursor: pointer !important;
}
.bv-cv2-cleanslate .bv-inline-histogram-ratings-explanation .bv-inline-histogram-ratings-explanation-icon {
  box-sizing: border-box !important;
  display: inline-block !important;
  margin-right: 0.5rem !important;
  border-radius: 50% !important;
  min-width: 1.5rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  background-color: #00d1d2 !important;
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
  speak: never !important;
  font-family: "nbw-icons" !important;
  line-height: 1 !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  text-align: center !important;
  color: #34303d !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
.bv-cv2-cleanslate .bv-inline-histogram-ratings-explanation .bv-inline-histogram-ratings-explanation-icon::before {
  content: "\e91a" !important;
}
.bv-cv2-cleanslate .bv-inline-histogram-ratings-explanation .bv-inline-histogram-ratings-explanation-label {
  font-weight: 700 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: #34303d !important;
}

span.bv-author-userstats-data, span.bv-author-userstats-value {
  color: #7b7881 !important;
}

.bv_main_container .bv_stars_svg_no_wrap svg,
.bv_main_container .bv_stars_svg_no_wrap polygon {
  padding: 0 0.25rem !important;
}

.ratings-and-reviews-btn, .bv_modal_outer_content .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit {
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 1.375rem !important;
  z-index: 1 !important;
  overflow: hidden !important;
  position: relative !important;
  border: 0 !important;
  transition: color 0.2s, opacity 0.3s !important;
  padding: 0.625rem 2rem !important;
  box-shadow: inset 0 0 0 2px #34303d !important;
}
.ratings-and-reviews-btn:hover, .bv_modal_outer_content .bv_button_buttonFull:hover,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:hover, .ratings-and-reviews-btn:focus, .bv_modal_outer_content .bv_button_buttonFull:focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:focus, .ratings-and-reviews-btn.focus .ratings-and-reviews-btn:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.bv_button_buttonFull .ratings-and-reviews-btn:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .ratings-and-reviews-btn:not(:disabled):not(.disabled):active, .ratings-and-reviews-btn.focus .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .ratings-and-reviews-btn.focus .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .focus.bv_button_buttonFull .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit .bv_button_buttonFull:not(:disabled):not(.disabled):active,
.ratings-and-reviews-btn.focus .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .ratings-and-reviews-btn.focus button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .focus.bv_button_buttonFull .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions .focus.bv_button_buttonFull button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.focus.bv-submission-button-submit button.bv-submission-button-submit:not(:disabled):not(.disabled):active, .ratings-and-reviews-btn:not(:disabled):not(.disabled).active, .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled).active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled).active {
  outline: 0 !important;
  color: #34303d !important;
  opacity: 1 !important;
}

.bv_modal_outer_content .bv_histogram_row_bar_filled {
  background-color: #34303d !important;
}
.bv_modal_outer_content .bv_button_buttonFull,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit {
  background-color: #34303d;
  color: #fffdfb;
}
.bv_modal_outer_content .bv_button_buttonFull::before,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit::before {
  background-color: #00d1d2;
}
.bv_modal_outer_content .bv_button_buttonFull:hover, .bv_modal_outer_content .bv_button_buttonFull:focus, .bv_modal_outer_content .bv_button_buttonFull.focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:hover,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit.focus {
  background-color: transparent;
  color: #34303d;
}
.bv_modal_outer_content .bv_button_buttonFull:focus, .bv_modal_outer_content .bv_button_buttonFull.focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit.focus {
  box-shadow: 0 0 0 0 rgba(0, 209, 210, 0.5);
}
.bv_modal_outer_content .bv_button_buttonFull.disabled, .bv_modal_outer_content .bv_button_buttonFull:disabled,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit.disabled,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:disabled {
  background-color: #b8b7bb;
  color: #7b7881;
}
.bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active, .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled).active, .show > .bv_modal_outer_content .bv_button_buttonFull.dropdown-toggle,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled).active,
.show > .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit.dropdown-toggle {
  background-color: transparent;
  color: #34303d;
}
.bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled):active:focus, .bv_modal_outer_content .bv_button_buttonFull:not(:disabled):not(.disabled).active:focus, .show > .bv_modal_outer_content .bv_button_buttonFull.dropdown-toggle:focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled):active:focus,
.bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit:not(:disabled):not(.disabled).active:focus,
.show > .bv_modal_outer_content .bv-cv2-cleanslate .bv-mbox .bv-submission .bv-fieldsets .bv-form-actions button.bv-submission-button-submit.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 209, 210, 0.5);
}
.bv_modal_outer_content .bv_button_component_container {
  margin-top: 1.25rem;
}

[data-bv-rating] .bv_main_container .bv_text,
[data-bv-show=inline_rating] .bv_main_container .bv_text {
  color: #7b7881 !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  padding-top: 0 !important;
}

#ratings-summary.bv_main_container_row_flex {
  outline: none;
}

.bubbles-slider .swiper-slide {
  overflow: hidden;
  transform: scale3d(0.9, 0.9, 1);
  border-bottom-right-radius: 8.1875rem;
  border-bottom-left-radius: 8.1875rem;
  width: 16.375rem;
  padding-top: 3.275rem;
  transition: transform 0.9s cubic-bezier(0.2, 0, 0.1, 1);
}
@media (min-width: 992px) {
  .bubbles-slider .swiper-slide {
    border-bottom-right-radius: 9.375rem;
    border-bottom-left-radius: 9.375rem;
    width: 18.75rem;
    padding-top: 3.75rem;
  }
}
.bubbles-slider .swiper-slide-active {
  transform: scale3d(1, 1, 1);
}

.bubbles-slider-slide-bg-container {
  position: relative;
}

.bubble-slider-slide-item {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 25%, 0);
  transition: transform 0.9s cubic-bezier(0.2, 0, 0.1, 1);
}
.swiper-slide-active .bubble-slider-slide-item {
  display: block;
  transform: translate3d(0, 0, 0);
}

.bubble-slider-slide-active-bg,
.bubble-slider-slide-active-item-top {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bubble-slider-slide-active-bg {
  opacity: 0;
  transition: opacity 0.9s cubic-bezier(0.2, 0, 0.1, 1);
}
.swiper-slide-active .bubble-slider-slide-active-bg {
  opacity: 1;
}

.bubble-slider-slide-active-item-top {
  z-index: 1;
  opacity: 0;
  transform: translate3d(2%, 3%, 0) scale3d(0.9, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0.2, 0, 0.1, 1), opacity 0.9s cubic-bezier(0.2, 0, 0.1, 1);
}
.swiper-slide-active .bubble-slider-slide-active-item-top {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

.steps .step-item img {
  height: 3rem;
  width: 3rem;
}
@media (min-width: 992px) {
  .steps .step-item img {
    height: 4rem;
    width: 4rem;
  }
}
@media (min-width: 992px) {
  .steps .step-item.step-item-lg-small img {
    height: 3rem;
    width: 3rem;
  }
}

.horizontal-divider {
  color: #7b7881;
  display: table;
  white-space: nowrap;
  height: auto;
  line-height: 1;
  text-align: center;
}
.horizontal-divider::after, .horizontal-divider::before {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQoAAAACCAYAAACg75IQAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAiSURBVHgB7cABDQAgCATAt38g3QwHCRgF7hKAxXn3VwBm1St7BCqA7w3mAAAAAElFTkSuQmCC");
}
.horizontal-divider::before {
  background-position: right 1em top 50%;
}
.horizontal-divider::after {
  background-position: left 1em top 50%;
}

.table {
  border-collapse: collapse;
  border-style: hidden;
}
.table .table-row:first-child .cell {
  border-top: none;
}
.table .title-icon {
  min-width: 34px;
}
.table .title-icon img {
  height: 34px;
  width: auto;
}
.table .cell {
  border-collapse: collapse;
  border: 1px solid #34303d;
  padding: 1rem;
  border-top: 0;
  border-bottom: 0;
}
@media (min-width: 992px) {
  .table .cell {
    border-top: 1px solid #34303d;
    border-bottom: 1px solid #34303d;
  }
}
.table .cell .cell-icon {
  max-height: 40px;
  width: auto;
}
.table .table-head > .table-row {
  border-collapse: collapse;
}
.table .table-head > .table-row .cell {
  border: none;
}
.table .table-header-logo {
  max-height: 19px;
  width: auto;
}
@media (min-width: 992px) {
  .table .table-header-logo {
    max-height: 26px;
  }
}
.table .table-body .value-cell {
  vertical-align: middle;
}
.table .table-tag-wrapper {
  width: 36px;
}
.table .table-tag-wrapper .table-tag {
  bottom: -0.5rem;
  right: 0;
}
@media (min-width: 992px) {
  .table .table-tag-wrapper {
    width: 0;
  }
}

.range-linear {
  display: flex;
}
.range-linear .range-linear-block {
  background-color: #f6f4f0;
  height: 10px;
  width: 25px;
  margin-right: 2px;
}
.range-linear .range-linear-block:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.range-linear .range-linear-block:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.range-circular {
  width: 44px;
  height: 41px;
  overflow: hidden;
  position: relative;
}
.range-circular.range-circular-small {
  width: 36px;
  height: 30px;
}
.range-circular.range-circular-small .range-circular-part {
  width: 36px;
  height: 34px;
  border: 2px solid #00d1d2;
  border-radius: 30px;
  position: absolute;
  clip: rect(30px, 24px, 35px, 15px);
  z-index: 2;
}
.range-circular.range-circular-small .range-circular-part:nth-child(1) {
  transform: rotate(31deg);
}
.range-circular.range-circular-small .range-circular-part:nth-child(2) {
  transform: rotate(62deg);
}
.range-circular.range-circular-small .range-circular-part:nth-child(3) {
  transform: rotate(93deg);
}
.range-circular.range-circular-small .range-circular-part:nth-child(4) {
  transform: rotate(124deg);
}
.range-circular.range-circular-small .range-circular-part:nth-child(5) {
  transform: rotate(155deg);
}
.range-circular.range-circular-small .range-circular-part:nth-child(6) {
  transform: rotate(186deg);
}
.range-circular.range-circular-small .range-circular-part:nth-child(7) {
  transform: rotate(217deg);
}
.range-circular.range-circular-small .range-circular-part:nth-child(8) {
  transform: rotate(248deg);
}
.range-circular.range-circular-small .range-circular-part:nth-child(9) {
  transform: rotate(279deg);
}
.range-circular.range-circular-small .range-circular-part:nth-child(10) {
  transform: rotate(310deg);
}
.range-circular.range-circular-small .range-circular-bg {
  width: 36px;
  height: 36px;
  border-radius: 30px;
  border: 2px solid #f6f4f0;
  clip: rect(auto, auto, auto, auto);
  z-index: 1;
}
.range-circular .range-circular-part {
  width: 44px;
  height: 44px;
  border: 2px solid #00d1d2;
  border-radius: 22px;
  position: absolute;
  clip: rect(38px, 22px, 44px, 9px);
  z-index: 2;
}
.range-circular .range-circular-part:nth-child(1) {
  transform: rotate(29deg);
}
.range-circular .range-circular-part:nth-child(2) {
  transform: rotate(58deg);
}
.range-circular .range-circular-part:nth-child(3) {
  transform: rotate(87deg);
}
.range-circular .range-circular-part:nth-child(4) {
  transform: rotate(116deg);
}
.range-circular .range-circular-part:nth-child(5) {
  transform: rotate(145deg);
}
.range-circular .range-circular-part:nth-child(6) {
  transform: rotate(174deg);
}
.range-circular .range-circular-part:nth-child(7) {
  transform: rotate(203deg);
}
.range-circular .range-circular-part:nth-child(8) {
  transform: rotate(232deg);
}
.range-circular .range-circular-part:nth-child(9) {
  transform: rotate(261deg);
}
.range-circular .range-circular-part:nth-child(10) {
  transform: rotate(290deg);
}
.range-circular .range-circular-bg {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  border: 2px solid #f6f4f0;
  clip: rect(auto, auto, auto, auto);
  z-index: 1;
}
.range-circular .range-circular-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.dot {
  display: block;
  border-radius: 100%;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #fffdfb;
}
.dot.dot.dot-lg {
  width: 1.125rem;
  height: 1.125rem;
}

.dots-group {
  display: inline-flex;
  flex-wrap: nowrap;
}
.dots-group .dot + .dot {
  margin-left: 0.625rem;
}
.dots-group.dots-group-narrow .dot + .dot {
  margin-left: 0.375rem;
}

.dots-pill {
  display: inline-flex;
  border-radius: 0.625rem;
  background-color: #fff;
  padding: 0.4375rem 0.4375rem;
  color: #34303d;
}
.dots-pill .dot {
  opacity: 0.4;
  background-color: currentColor;
  width: 0.375rem;
  height: 0.375rem;
}
.dots-pill .dot + .dot {
  margin-left: 0.25rem;
}
.dots-pill .dot-active {
  opacity: 1;
}

.dots-pill-opaque {
  background-color: rgba(255, 255, 255, 0.2);
}
.dots-pill-opaque .dot {
  background-color: #fff;
}

.floating-message {
  z-index: 1040;
  position: fixed;
  left: 1.25rem;
  right: 1.25rem;
  bottom: 1.25rem;
  overflow-y: auto;
  border-radius: 4px;
  padding: 1rem 1.25rem;
}
@media (min-width: 992px) {
  .floating-message {
    left: auto;
    right: 2.5rem;
    bottom: 2.5rem;
    box-shadow: 0 0.5rem 0.875rem rgba(0, 0, 0, 0.05);
    width: 25rem;
    padding: 1.5rem 2rem;
  }
}

.circled-background-slider .circled-background-slide {
  border-bottom-right-radius: 50% 45%;
  border-bottom-left-radius: 50% 45%;
}
.circled-background-slider .swiper-slide,
.circled-background-slider .circled-background-slide {
  width: 15.5rem;
  padding-top: 2rem;
  transition: transform 0.9s cubic-bezier(0.2, 0, 0.1, 1), opacity 0.9s cubic-bezier(0.2, 0, 0.1, 1), padding-top 0.2s cubic-bezier(0.2, 0, 0.1, 1);
  transform: scale(0.9);
}
@media (min-width: 992px) {
  .circled-background-slider .swiper-slide,
  .circled-background-slider .circled-background-slide {
    width: 19.375rem;
  }
}
.circled-background-slider .circled-background-slide {
  overflow: hidden;
}
.circled-background-slider .swiper-slide-active {
  opacity: 1;
  transform: scale(1);
}
.circled-background-slider .swiper-slide-active .sensory {
  opacity: 0;
}
.circled-background-slider .swiper-slide-active .circled-background-slide-badge {
  transform: translate3d(-50%, 1%, 0);
}
.circled-background-slider .swiper-slide-active .swiper-slide,
.circled-background-slider .swiper-slide-active .circled-background-slide {
  padding-top: 2rem;
}
.circled-background-slider .circled-background-slide-badge {
  height: 100%;
  transform: translate3d(-50%, 24%, 0);
  transition: transform 0.9s cubic-bezier(0.2, 0, 0.1, 1), opacity 0.9s;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 4;
}
.circled-background-slider picture:not(.heet-badge) {
  border-radius: 100%;
}
.circled-background-slider .sensory {
  width: 100%;
  transition: opacity 0.9s cubic-bezier(0.2, 0, 0.1, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}
.circled-background-slider .slide-title {
  transition: opacity 0.2s cubic-bezier(0.2, 0, 0.1, 1);
  opacity: 0;
}
@media (min-width: 768px) {
  .circled-background-slider .slide-title {
    min-height: 90px;
  }
}
.circled-background-slider .swiper-slide-active .slide-title {
  opacity: 1;
}
.circled-background-slider .circled-background-slide-buttons-container {
  position: absolute;
  bottom: 18%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .circled-background-slider .circled-background-slide-buttons-container {
    width: 45%;
  }
}
@media (min-width: 1200px) {
  .circled-background-slider .circled-background-slide-buttons-container {
    width: 35%;
  }
}
.circled-background-slider .circle-cropped-image {
  border-radius: 50%;
}

.alert-danger {
  color: #541814;
  background-color: #ecd5d4;
  border-color: #e5c5c2;
}
.alert-danger hr {
  border-top-color: #ddb4b0;
}
.alert-danger .alert-link {
  color: #2b0c0a;
}

.sulu-content-message {
  position: fixed;
  bottom: 0;
  z-index: 1060;
  max-width: none;
}

.compare-specification .compare-device-row {
  min-height: 8rem;
}

.scan-it-block {
  font-family: "IQOS", "Helvetica Neue", Arial, sans-serif;
  flex-direction: column;
}
.scan-it-block header {
  padding: 32px 0;
}
.scan-it-block .line {
  border: solid 1px #e8e8e8;
  width: 100%;
  height: 1px;
}
.scan-it-block .separator {
  margin-bottom: 24px;
}
.scan-it-block .welcome-row p {
  font-size: 11px;
  text-align: center;
  color: #a9a092;
  padding-top: 30px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 600;
}
.scan-it-block .welcome-row p.slogan {
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: -0.06px;
  text-align: center;
  color: #272a33;
  text-transform: none;
  margin-bottom: 8px;
  padding-top: 0;
}
.scan-it-block .img-wrapper.ico-app {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scan-it-block .img-wrapper.ico-app img {
  width: 33px;
  height: 36px;
}
.scan-it-block .img-wrapper.flavors-dce {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scan-it-block .img-wrapper.flavors-dce img {
  width: 54px;
  height: 36px;
}
.scan-it-block .img-wrapper img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.scan-it-block .btn-row {
  max-width: 425px;
  margin: 0 auto 0;
}
.scan-it-block .btn-row > div {
  margin-bottom: 15px;
}
.scan-it-block .btn-row a {
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  color: #272a33;
}
.scan-it-block footer {
  padding: 72px 0;
  text-align: center;
  font-size: 12px;
  color: #4f4f4f;
  background: #f6f6f6;
}

@media (min-width: 992px) {
  .block-with-offset-image-wrapper::before {
    background-color: inherit;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -50%;
    width: 50vw;
  }
}
@media (min-width: 992px) {
  .block-with-offset-image-wrapper.block-with-offset-inverse::after {
    background-color: #fff;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 75%;
    width: 25vw;
  }
}

.order-lg-1 .block-with-offset-image-image-container {
  margin-left: -2rem;
}

@media (min-width: 992px) {
  .block-with-offset-image-image-container {
    top: 4rem;
  }
}
@media (min-width: 1200px) {
  .block-with-offset-image-image-container {
    top: 6rem;
  }
}
@media (min-width: 992px) {
  .block-with-offset-image-image-container.block-with-offset-top {
    top: -4rem;
  }
}
@media (min-width: 1200px) {
  .block-with-offset-image-image-container.block-with-offset-top {
    top: -6rem;
  }
}

.block-with-offset-image-button {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.block-with-two-images-second-image {
  top: 2rem;
  margin-bottom: -3.5rem;
}
@media (min-width: 1200px) {
  .block-with-two-images-second-image {
    margin-bottom: -1.5rem;
    top: 5rem;
  }
}

@media (min-width: 992px) {
  .order-lg-1 .block-with-two-images-main-image {
    margin-left: -2rem;
    margin-right: 3rem;
  }
  .order-lg-2 .block-with-two-images-main-image {
    margin-left: 3rem;
    margin-right: -2rem;
  }
}

@media (max-width: 991.98px) {
  .block-with-full-background .block-with-full-background-text-area-container {
    background-color: #fffdfb !important;
    color: #34303d !important;
  }
}

.hero-block-text-area {
  max-width: 31.25rem;
}

.hero-block-text-image {
  max-height: 43.75rem;
}

.block-text-with-image-container .container-fluid-image {
  max-height: 40.25rem;
}

.block-text-with-image-container-text-with-background {
  background-size: cover;
}

@media (min-width: 1200px) {
  .block-image-with-3-text-block-image-container {
    min-height: 35rem;
  }
}

.block-image-with-3-text-block-logo {
  max-width: 11.125rem;
}

.block-image-with-3-text-block-image-block {
  max-width: 35rem;
}

@media (min-width: 992px) {
  .block-image-with-3-text-block-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.block-image-with-3-text-block-bg-image::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
@media (min-width: 992px) {
  .block-image-with-3-text-block-bg-image::before {
    background: rgba(0, 0, 0, 0.2);
  }
}
.block-image-with-3-text-block-bg-image img {
  min-height: 19.5rem;
}

.block-text-on-image-with-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.2);
}

.block-text-on-image-container {
  background-position: center;
  background-size: cover;
}

.block-text-on-image-xs {
  min-height: 19.625rem;
}
@media (min-width: 992px) {
  .block-text-on-image-xs {
    min-height: 12.5rem;
  }
}

.block-text-on-image-small {
  min-height: 18.75rem;
}
.block-text-on-image-small.hidden-mobile-image {
  min-height: auto;
}
@media (min-width: 992px) {
  .block-text-on-image-small.hidden-mobile-image {
    min-height: 25rem;
  }
}
@media (min-width: 992px) {
  .block-text-on-image-small {
    min-height: 25rem;
  }
}

.block-text-on-image-large {
  min-height: 38.125rem;
}

.block-image-with-content-slider-swiper-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.block-image-with-content-slider-swiper-container .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.block-image-with-content-slider-swiper-navigation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.block-image-with-content-slider-slide-text-block {
  max-width: 31.25rem;
}

.block-with-content-overflow-container {
  margin-top: -20vw;
}
@media (min-width: 1200px) {
  .block-with-content-overflow-container {
    margin-top: -15rem;
  }
}
@media (min-width: 992px) {
  .block-with-content-overflow-container.block-with-content-overflow-container-smaller {
    margin-top: -10rem;
  }
}

.block-with-content-overflow-container-mobile-2 {
  margin-top: -26vw;
}
@media (min-width: 1200px) {
  .block-with-content-overflow-container-mobile-2 {
    margin-top: -15rem;
  }
}
@media (min-width: 992px) {
  .block-with-content-overflow-container-mobile-2.block-with-content-overflow-container-smaller-mobile-2 {
    margin-top: -10rem;
  }
}

.block-with-uneven-images-bottom-upper-image {
  position: absolute;
  top: 0;
  left: 6rem;
  right: 0;
  z-index: 1;
  transform: translate3d(0, -8rem, 0);
}
@media (min-width: 992px) {
  .block-with-uneven-images-bottom-upper-image {
    left: 5rem;
    transform: translate3d(0, -50%, 0);
  }
}
@media (min-width: 1200px) {
  .block-with-uneven-images-bottom-upper-image {
    left: 7rem;
  }
}

.block-with-uneven-images-bottom-lower-image {
  margin-right: 6rem;
}
@media (min-width: 992px) {
  .block-with-uneven-images-bottom-lower-image {
    margin-right: 4rem;
  }
}
@media (min-width: 1200px) {
  .block-with-uneven-images-bottom-lower-image {
    margin-right: 2rem;
  }
}

.block-blocks-slider-slide {
  width: 100%;
  max-width: 13.75rem;
}
@media (min-width: 576px) {
  .block-blocks-slider-slide {
    max-width: 22.5rem;
  }
}
@media (min-width: 992px) {
  .block-blocks-slider-slide {
    max-width: 32.5rem;
  }
}
.block-blocks-slider-slide:not(:last-child) {
  margin-right: 1rem;
}
@media (min-width: 768px) {
  .block-blocks-slider-slide:not(:last-child) {
    margin-right: 2rem;
  }
}
.block-blocks-slider-slide figure {
  position: relative;
}
.block-blocks-slider-slide figure::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.6;
}
.block-blocks-slider-slide h3 {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 1;
}
@media (min-width: 768px) {
  .block-blocks-slider-slide h3 {
    line-height: 1.5;
    font-weight: 700;
    bottom: 2.5rem;
    left: 2.5rem;
    right: 2.5rem;
  }
}

@media (max-width: 1199.98px) {
  .block-milestone-slider-milestone-line {
    padding: 0 2rem;
  }
}
.block-milestone-slider-milestone-line .swiper-slide {
  width: 2.5rem;
  text-align: center;
}
@media (min-width: 992px) {
  .block-milestone-slider-milestone-line .swiper-slide {
    width: 9.375rem;
  }
}
.block-milestone-slider-milestone-line .swiper-slide::before {
  content: "";
  position: absolute;
  top: 1.25rem;
  left: 50%;
  width: 0.313rem;
  height: 0.313rem;
  border-radius: 100%;
  background-color: #00d1d2;
  transform: translate3d(-50%, 0, 0);
  transition: transform 0.3s, background-color 0.3s;
}
@media (min-width: 768px) {
  .block-milestone-slider-milestone-line .swiper-slide::before {
    top: 0.313rem;
  }
}
.block-milestone-slider-milestone-line .swiper-slide:hover::before {
  transform: translate3d(-50%, 0, 0) scale3d(2.6, 2.6, 1);
}
.block-milestone-slider-milestone-line .swiper-slide p {
  visibility: hidden;
}
@media (min-width: 768px) {
  .block-milestone-slider-milestone-line .swiper-slide p {
    margin-bottom: 0;
    margin-top: 2.5rem;
    visibility: visible;
  }
}
.block-milestone-slider-milestone-line .swiper-slide-thumb-active::before {
  transform: translate3d(-50%, 0, 0) scale3d(2.6, 2.6, 1);
  background-color: #fffdfb;
}

.block-milestone-slider-milestone {
  z-index: 4;
}
@media (max-width: 1199.98px) {
  .block-milestone-slider-milestone {
    padding: 0 2rem;
  }
}
@media (min-width: 768px) {
  .block-milestone-slider-milestone {
    max-width: 43.5rem;
  }
}

.block-milestone-slider-title {
  max-width: 17rem;
}
@media (max-width: 1199.98px) {
  .block-milestone-slider-title {
    padding: 0 2rem;
  }
}
@media (min-width: 768px) {
  .block-milestone-slider-title {
    max-width: 25rem;
  }
}

.block-milestone-slider-container {
  max-width: 85.875rem;
  min-height: 41rem;
}
@media (min-width: 768px) {
  .block-milestone-slider-container {
    min-height: 48rem;
  }
}

.block-milestone-slider-images {
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 23rem;
  width: 15.625rem;
  height: 15.625rem;
}
@media (min-width: 768px) {
  .block-milestone-slider-images {
    top: -3rem;
    right: -2rem;
    width: 45.5rem;
    height: 45.5rem;
    opacity: 0.6;
  }
}
@media (min-width: 1200px) {
  .block-milestone-slider-images {
    opacity: 1;
  }
}
.block-milestone-slider-images .shape {
  position: absolute;
  transform: translate3d(-26%, -32%, 0) scale3d(0.45, 0.45, 1);
}
@media (min-width: 768px) {
  .block-milestone-slider-images .shape {
    bottom: 19rem;
    transform: translate3d(0, 42%, 0) scale3d(2, 2, 1);
    width: 46.875rem;
    height: 23.375rem;
  }
}
.block-milestone-slider-images .milestone-img img {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(-100%, 65%, 0) scale3d(1, 1, 1);
  transition: transform 0.4s, opacity 0.4s;
  height: 5.375rem;
  width: 5.375rem;
  border-radius: 10.75rem;
  opacity: 0;
}
@media (min-width: 768px) {
  .block-milestone-slider-images .milestone-img img {
    transform: translate3d(-50%, 25%, 0) scale3d(0.75, 0.75, 1);
    width: 19rem;
    height: 19rem;
  }
}
.block-milestone-slider-images .milestone-img.visible img {
  transform: translate3d(-30%, 30%, 0) scale3d(1.9, 1.9, 1);
  z-index: 2;
  opacity: 1;
}
@media (min-width: 768px) {
  .block-milestone-slider-images .milestone-img.visible img {
    transform: translate3d(-5%, 10%, 0) scale3d(1, 1, 1);
  }
}
.block-milestone-slider-images .milestone-img.visible ~ picture img {
  transform: scale(3.1) translate(95%, -35%);
}
@media (min-width: 768px) {
  .block-milestone-slider-images .milestone-img.visible ~ picture img {
    transform: scale(2.1) translate(85%, -35%);
  }
}
.block-milestone-slider-images .milestone-img.visible + picture img {
  transform: scale(2.9) translate(54%, -22%);
  z-index: 1;
  opacity: 0.6;
}
@media (min-width: 768px) {
  .block-milestone-slider-images .milestone-img.visible + picture img {
    transform: scale(1.9) translate(55.5%, -21%);
  }
}

@media (min-width: 992px) {
  .block-text-with-full-image .embed-responsive {
    max-height: 40.875rem;
  }
}
.block-text-with-full-image .embed-responsive::before {
  padding-top: 133.3333333333%;
}
@media (min-width: 992px) {
  .block-text-with-full-image .embed-responsive::before {
    padding-top: 56.25%;
  }
}

.block-with-big-centered-image-wrapper::before {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
  background-color: inherit;
  content: "";
}

.block-with-big-centered-image-button {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (min-width: 992px) {
  .block-with-big-centered-image-button {
    top: auto;
    right: 3rem;
    bottom: 3rem;
    left: auto;
  }
}

@media (min-width: 992px) {
  .block-with-offset-image-top-image-container {
    top: -6rem;
  }
}

@media (min-width: 992px) {
  .block-text-with-background-and-image-content {
    min-height: 46.25rem;
  }
}

@media (min-width: 992px) {
  .block-with-huge-image-text-container {
    height: 34.75rem;
  }
}

.block-with-huge-image-text-content {
  max-width: 24.375rem;
}

.device-nav-item {
  opacity: 0.4;
  min-width: 5rem;
  transition: opacity 0.15s ease-in-out;
}
.device-nav-item.active {
  opacity: 1;
}
.device-nav-item:hover {
  opacity: 1;
}
@media (min-width: 992px) {
  .device-nav-item .device-nav-item-text {
    white-space: nowrap;
  }
}
@media (min-width: 992px) {
  .device-nav-item .device-nav-small-image {
    max-height: 2.75rem;
  }
}

.block-video-blocks-slide {
  width: 100%;
  max-width: 15.5rem;
}
@media (min-width: 992px) {
  .block-video-blocks-slide {
    max-width: 16rem;
  }
}
.block-video-blocks-slide:not(:last-child) {
  margin-right: 1rem;
}
@media (min-width: 992px) {
  .block-video-blocks-slide:not(:last-child) {
    margin-right: 4rem;
  }
}
.block-video-blocks-slide h3 {
  position: absolute;
  bottom: 1.5rem;
  left: 1.25rem;
  z-index: 1;
}
.block-video-blocks-slide .block-video-blocks-slide-button {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 992px) {
  .block-full-video .embed-responsive {
    max-height: 40.875rem;
  }
}
.block-full-video .embed-responsive::before {
  padding-top: 133.3333333333%;
}
@media (min-width: 992px) {
  .block-full-video .embed-responsive::before {
    padding-top: 56.25%;
  }
}

.block-text-and-image-cards .block-text-and-image-card {
  min-height: 566px;
}
@media (min-width: 992px) {
  .block-text-and-image-cards .block-text-and-image-card {
    min-height: 525px;
  }
}

.checkout-order-overview {
  height: auto;
  overflow-x: hidden;
}
@media (min-width: 992px) {
  .checkout-order-overview {
    max-height: 320px;
  }
}
.checkout-order-overview .checkout-order-overview-item-image {
  width: auto;
  min-width: 108px;
  max-width: 116px;
}
@media (min-width: 768px) {
  .checkout-order-overview .checkout-order-overview-item-image {
    min-width: 90px;
    max-width: 130px;
  }
}
.checkout-order-overview .checkout-order-overview-item-image img {
  width: 92%;
  height: auto;
}

.checkout-courier-logo {
  height: 1.5625rem;
  width: auto;
}

.checkout-iqos-logo {
  height: 1rem;
  width: auto;
}

.checkout-coupon {
  min-height: 5rem;
}
.checkout-coupon .nbw-icon-discount-coupon.circle-icon {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
}

.cart-item-row .cart-item-image {
  width: auto;
  min-width: 108px;
  max-width: 116px;
}
@media (min-width: 768px) {
  .cart-item-row .cart-item-image {
    min-width: 108px;
    max-width: 150px;
  }
}
.cart-item-row .cart-item-image img {
  width: 92%;
  height: auto;
}
.cart-item-row .cart-item-image.cart-item-image-small img {
  width: 81%;
}

.checkout-payments-images-list img {
  max-height: 89px;
}

.stepper {
  height: 76px;
  max-width: 720px;
  counter-reset: section;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (min-width: 992px) {
  .stepper {
    height: 184px;
  }
}
.stepper .step-number {
  line-height: 2.25rem;
}
.stepper .stepper-item.disabled a {
  pointer-events: none;
  cursor: default;
}
.stepper .stepper-item .circle {
  display: inline-block;
  color: #7b7881;
  border-radius: 50%;
  background: transparent;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 600;
  text-align: center;
  line-height: 2.7rem;
  position: relative;
  border: 2px solid #7b7881;
}
.stepper .stepper-item.active .circle {
  background: #34303d;
  color: #fff;
  border: 2px solid #34303d;
}
.stepper .stepper-item.active a .label {
  color: rgba(0, 0, 0, 0.87);
}
.stepper .stepper-item.completed .circle {
  border: none;
}
.stepper .line {
  position: absolute;
  width: calc(100% - 150px);
  height: 2px;
  background: #7b7881;
  top: 40px;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 992px) {
  .stepper .line {
    top: 67px;
  }
}
.stepper .line.line-of-2-items {
  width: calc(100% - 300px);
}

.small-stepper {
  height: 76px;
  max-width: 720px;
  counter-reset: section;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (min-width: 992px) {
  .small-stepper {
    height: 146px;
  }
}
.small-stepper .small-stepper-item.disabled a {
  pointer-events: none;
  cursor: default;
}
.small-stepper .small-stepper-item .circle {
  display: inline-block;
  border-radius: 50%;
  background: #b8b7bb;
  width: 1rem;
  height: 1rem;
  z-index: 1;
}
.small-stepper .small-stepper-item.active .circle {
  background: #00d1d2;
}
.small-stepper .small-stepper-item.active a .label {
  color: rgba(0, 0, 0, 0.87);
}
.small-stepper .small-stepper-item.completed .circle {
  border: none;
}
.small-stepper .lines-wrapper {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: calc(100% - 100px);
  height: 2px;
  top: 30px;
}
@media (min-width: 992px) {
  .small-stepper .lines-wrapper {
    width: calc(100% - 160px);
    top: 58px;
  }
}
.small-stepper .line {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #b8b7bb;
}
.small-stepper .label {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.small-stepper .line-cut {
  position: absolute;
  background-color: red;
  height: 20px;
  width: 200px;
  top: 0;
}
.small-stepper .line-cut-start {
  right: 50%;
}
.small-stepper .line-cut-end {
  left: 50%;
}
.small-stepper .active-line-1-1 {
  position: absolute;
  width: calc(100% * (1 - 1) / (1 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-1-1 {
    width: calc(100% * (1 - 1) / (1 - 1));
  }
}
.small-stepper .active-line-1-2 {
  position: absolute;
  width: calc(100% * (1 - 1) / (2 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-1-2 {
    width: calc(100% * (1 - 1) / (2 - 1));
  }
}
.small-stepper .active-line-1-3 {
  position: absolute;
  width: calc(100% * (1 - 1) / (3 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-1-3 {
    width: calc(100% * (1 - 1) / (3 - 1));
  }
}
.small-stepper .active-line-1-4 {
  position: absolute;
  width: calc(100% * (1 - 1) / (4 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-1-4 {
    width: calc(100% * (1 - 1) / (4 - 1));
  }
}
.small-stepper .active-line-2-1 {
  position: absolute;
  width: calc(100% * (2 - 1) / (1 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-2-1 {
    width: calc(100% * (2 - 1) / (1 - 1));
  }
}
.small-stepper .active-line-2-2 {
  position: absolute;
  width: calc(100% * (2 - 1) / (2 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-2-2 {
    width: calc(100% * (2 - 1) / (2 - 1));
  }
}
.small-stepper .active-line-2-3 {
  position: absolute;
  width: calc(100% * (2 - 1) / (3 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-2-3 {
    width: calc(100% * (2 - 1) / (3 - 1));
  }
}
.small-stepper .active-line-2-4 {
  position: absolute;
  width: calc(100% * (2 - 1) / (4 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-2-4 {
    width: calc(100% * (2 - 1) / (4 - 1));
  }
}
.small-stepper .active-line-3-1 {
  position: absolute;
  width: calc(100% * (3 - 1) / (1 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-3-1 {
    width: calc(100% * (3 - 1) / (1 - 1));
  }
}
.small-stepper .active-line-3-2 {
  position: absolute;
  width: calc(100% * (3 - 1) / (2 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-3-2 {
    width: calc(100% * (3 - 1) / (2 - 1));
  }
}
.small-stepper .active-line-3-3 {
  position: absolute;
  width: calc(100% * (3 - 1) / (3 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-3-3 {
    width: calc(100% * (3 - 1) / (3 - 1));
  }
}
.small-stepper .active-line-3-4 {
  position: absolute;
  width: calc(100% * (3 - 1) / (4 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-3-4 {
    width: calc(100% * (3 - 1) / (4 - 1));
  }
}
.small-stepper .active-line-4-1 {
  position: absolute;
  width: calc(100% * (4 - 1) / (1 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-4-1 {
    width: calc(100% * (4 - 1) / (1 - 1));
  }
}
.small-stepper .active-line-4-2 {
  position: absolute;
  width: calc(100% * (4 - 1) / (2 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-4-2 {
    width: calc(100% * (4 - 1) / (2 - 1));
  }
}
.small-stepper .active-line-4-3 {
  position: absolute;
  width: calc(100% * (4 - 1) / (3 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-4-3 {
    width: calc(100% * (4 - 1) / (3 - 1));
  }
}
.small-stepper .active-line-4-4 {
  position: absolute;
  width: calc(100% * (4 - 1) / (4 - 1));
  height: 2px;
  background: #00d1d2;
}
@media (min-width: 992px) {
  .small-stepper .active-line-4-4 {
    width: calc(100% * (4 - 1) / (4 - 1));
  }
}

@media (min-width: 992px) {
  .col-with-arrow:not(:last-child) {
    padding-right: 2rem;
  }
  .col-with-arrow:not(:first-child) {
    padding-left: 2rem;
  }
}
.col-with-arrow:not(:last-child)::after {
  display: inline-block;
  position: absolute;
  bottom: -30px;
  font-size: inherit;
  line-height: inherit;
  content: "\e91d";
}
@media (min-width: 992px) {
  .col-with-arrow:not(:last-child)::after {
    bottom: auto;
    top: 50%;
    right: 0;
    content: "\e90a";
    transform: translate3d(50%, -50%, 0);
  }
}

.order-history-item.disabled {
  color: #b8b7bb;
}
.order-history-item.disabled .text-red,
.order-history-item.disabled .text-dark,
.order-history-item.disabled .text-blue {
  color: inherit !important;
}

.delete-account-text ul {
  padding-left: 1.2rem;
}
.delete-account-text ul li {
  margin-top: 0.8rem;
}

.profile-page-container .dropdown-menu {
  position: static !important;
  transform: initial !important;
}

legend.close-account-reason-legend {
  margin-bottom: 2rem;
  font-weight: 700;
}

.separator {
  max-width: 100%;
  height: 1px;
  border-bottom: solid 1px #979797;
  margin: 0 20px;
}
@media (min-width: 992px) {
  .separator {
    margin: 0 42px;
  }
}

.qure-top-bar {
  min-height: 58px;
}
@media (min-width: 992px) {
  .qure-top-bar {
    height: 76px;
  }
}

@media (min-width: 992px) {
  .qure-top-bar-title {
    font-size: 1.5rem;
  }
}

.qure-section ul li {
  color: #34303d;
}

.qure-content-block {
  padding: 2.7rem 3rem;
}
@media (min-width: 992px) {
  .qure-content-block {
    padding: 2rem 3.7rem;
  }
}
.solution-buttons-block {
  background-color: #524f5a;
  padding: 1.6rem 3rem;
}
@media (min-width: 992px) {
  .solution-buttons-block {
    padding: 2rem 3.7rem;
  }
}
.solution-buttons-block p:last-child {
  margin: 0;
}

.qure-solution-image img {
  max-height: 350px;
  height: auto;
  margin-left: auto;
}

.styleguide-sidebar {
  transition: transform 0.3s;
  transform: translateX(0);
}
.styleguide-sidebar.toggled {
  transform: translateX(-100%);
}
.styleguide-sidebar.toggled .btn-circular {
  right: -3rem;
}
.styleguide-sidebar.toggled .nbw-icon-arrow-left::before {
  display: block;
  transform: rotate(180deg);
}
.styleguide-sidebar .btn-circular {
  position: absolute;
  top: 0.5rem;
  right: 0;
  transition: right 0.3s;
}

.trade-in-device-select-count {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid #7b7881;
  color: #7b7881;
  background-color: #fff;
  font-weight: 700;
}
.trade-in-device-select-count.active {
  background-color: #34303d;
  border-color: #34303d;
  color: #fff;
}
.trade-in-device-select-count.selected {
  background-color: #00d1d2 !important;
  border-color: #00d1d2;
  color: #34303d;
}
.trade-in-device-select-count.selected .trade-in-device-select-count-text {
  display: none;
}
.trade-in-device-select-count.selected:before {
  content: "\e913";
}

.trade-in-my-select-image {
  height: 5.25rem;
  width: 5.25rem;
}

.disabled-device {
  opacity: 0.5;
  pointer-events: none;
}

.trade-in-user-device-box {
  border-radius: 4px;
}

:not(.modal-body) .login-step-two {
  max-width: 500px;
  margin: auto;
}

:not(.modal-body) .login-step-two-back-button {
  max-width: 916px;
}

.login-step-two .custom-radio {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .age-gate-modal .form-floating > label {
    overflow: visible;
  }
}
.age-gate-modal .form-floating > label {
  color: #524f5a !important;
}

.pdp-trade-in-block {
  min-height: 11rem;
}
.pdp-trade-in-block .pdp-trade-in-block-column {
  min-height: 11rem;
}

.special-offer-banner {
  background-color: rgba(0, 209, 210, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 0.5rem;
  max-width: 430px;
  padding: 1.25rem;
  border: 2px solid #00d1d2;
  filter: drop-shadow(0 0 25px rgba(0, 209, 210, 0.2));
}
@media (min-width: 992px) {
  .special-offer-banner {
    padding: 2.5rem;
  }
}

.gift-slider-arrows {
  position: relative;
  height: 60px;
}
@media (min-width: 992px) {
  .gift-slider-arrows {
    position: absolute;
    left: 50%;
    top: 29%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 2;
    width: calc(100% + 44px);
  }
}
.gift-slider-arrows .gift-slider-arrow {
  border: 1px solid #f6f4f0;
  background-color: #f6f4f0;
}
@media (min-width: 992px) {
  .gift-slider-arrows .gift-slider-arrow {
    background-color: #fffdfb;
    box-shadow: 4px 4px 35px -12px rgb(0, 0, 0);
  }
}

.recommended-devices-label-image {
  max-width: 3rem;
}
@media (min-width: 992px) {
  .recommended-devices-label-image {
    max-width: 5rem;
  }
}

.whats-in-the-box-image-wrapper img {
  height: 200px;
}
@media (min-width: 768px) {
  .whats-in-the-box-image-wrapper img {
    height: auto;
  }
}

.single-product-slider {
  max-height: 230px;
}
.single-product-slider img {
  max-height: 206px;
}
@media (min-width: 992px) {
  .single-product-slider {
    max-height: none;
  }
  .single-product-slider img {
    max-height: none;
  }
}

.pdp-reseller-logo {
  height: auto;
  width: 100%;
  max-width: 4rem;
}

.structure-image {
  transition: opacity ease 0.3s;
  pointer-events: none;
}

.structure-part-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.structure-button {
  position: absolute;
}
.structure-button .icon {
  pointer-events: none;
  transition: transform ease-in-out 0.2s;
}

@media (min-width: 992px) {
  .limited-desktop-height {
    max-height: 25rem;
  }
}

@media (min-width: 992px) {
  .flavour-variant-body {
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .flavour-header-image {
    max-height: 25rem;
  }
}

.flavour-variants-slider-arrows {
  width: 8rem;
}

.whats-in-the-box-image-wrapper img {
  height: 200px;
}
@media (min-width: 768px) {
  .whats-in-the-box-image-wrapper img {
    height: auto;
  }
}

.single-product-slider {
  max-height: 230px;
}
.single-product-slider img {
  max-height: 206px;
}
@media (min-width: 992px) {
  .single-product-slider {
    max-height: none;
  }
  .single-product-slider img {
    max-height: none;
  }
}

.pdp-reseller-logo {
  height: auto;
  width: 100%;
  max-width: 4rem;
}