@each $color, $value in $main-colors {
  .form-bg-#{$color} {
    .form-control,
    .select-clear,
    .form-floating > label,
    .select2-container--nbw .select2-selection,
    .select2-container--nbw .select2-dropdown .select2-search__field,
    .custom-control-label::before {
      background-color: $value !important;
    }
  }
}

.form-control {
  height: auto;

  // Filled state
  &:not(:placeholder-shown) {
    color: $input-active-color;
    border-color: $input-active-border-color;
  }

  &:disabled,
  &[readonly] {
    border-color: $input-disabled-border-color;
    color: $input-disabled-color;

    &::placeholder {
      color: $input-disabled-placeholder-color;
    }

    ~ label {
      color: $input-disabled-color;
    }
  }
}
.is-empty {
  color: $input-placeholder-color !important;

  &:not(.is-invalid) {
    border-color: $input-border-color;

    ~ label {
      color: $input-placeholder-color !important;
    }
  }
}

[class*='form-control-prepend'],
.form-control-append {
  position: absolute;
  top: $input-padding-y + px-to-rem($input-border-width);
  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;

  &.form-control-icon {
    top: $input-padding-y + px-to-rem($input-border-width) - (($input-icon-font-size - $input-font-size) / 2);
    font-size: $input-icon-font-size;
  }
}

.form-control-prepend {
  left: $input-padding-x;

  ~ .form-control {
    padding-left: $input-padding-x + ($form-control-prepend-width * 4) + $form-control-prepend-padding;
  }

  &.form-control-icon ~ .form-control {
    padding-left: $input-padding-x + $input-icon-font-size + $input-icon-padding;
  }

  @each $size in $form-control-prepend-sizes {
    &.form-control-prepend-#{$size} {
      ~ .form-control {
        padding-left: $input-padding-x + ($form-control-prepend-width * $size) + $form-control-prepend-padding;
      }
    }
  }
}

.form-control-append {
  right: $input-padding-x;

  ~ .form-control {
    padding-right: $input-padding-x + $form-control-append-width + $form-control-append-padding;
  }

  &.form-control-icon ~ .form-control {
    padding-right: $input-padding-x + $input-icon-font-size + $input-icon-padding;
  }
}

label {
  &:not(.custom-control-label):not(.btn) {
    &.required::after {
      content: '*';
    }

    &.disabled {
      color: $label-disabled-color;
    }
  }
}

legend {
  &.disabled {
    color: $label-disabled-color;
  }
}

.code-input {
  display: inline-block;

  .code-input-field {
    border-width: $input-btn-border-width;
    font-size: $code-input-font-size;
    width: $code-input-font-mobile-width;
    height: $code-input-font-mobile-height;

    @include media-breakpoint-up(sm) {
      width: $code-input-font-width;
      height: $code-input-font-height;
    }
    text-align: center;
    padding: 0;

    &.is-invalid,
    &:focus,
    &.is-invalid:focus {
      box-shadow: none;
    }

    &:not(:first-child) {
      margin-left: $code-input-font-mobile-spacing;

      @include media-breakpoint-up(sm) {
        margin-left: $code-input-font-spacing;
      }
    }
  }

  & + .is-invalid.invalid-feedback {
    display: block !important;
  }
}
