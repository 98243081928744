.background-size-cover {
  background-size: cover;
}

.background-position-center {
  background-position: center;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-fluid {
  flex-shrink: 0;
}

.lazy {
  opacity: 0;
  transition: opacity 0.3s;

  &.loaded {
    opacity: 1;
  }
}
