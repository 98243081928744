.custom-control-input {
  &:not(:disabled) ~ .custom-control-label {
    cursor: pointer;
  }

  &:focus ~ .custom-control-label::before {
    border-color: $custom-control-indicator-focus-border-color;
  }
}

.custom-checkbox {
  .custom-control-input {
    opacity: 1;
  }

  .custom-control-label::after {
    background-size: $custom-checkbox-indicator-bg-size;
  }

  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      color: $custom-checkbox-indicator-checked-color;
      border-color: $custom-checkbox-indicator-checked-border-color;
      background-color: $custom-checkbox-indicator-checked-bg !important;
    }

    &:disabled {
      ~ .custom-control-label::before {
        border-color: $custom-control-indicator-disabled-border-color;
      }

      &:checked ~ .custom-control-label::before {
        background-color: $custom-checkbox-indicator-checked-disabled-bg !important;
        border-color: $custom-control-indicator-disabled-border-color;
      }

      &:indeterminate ~ .custom-control-label::before {
        background-color: $custom-checkbox-indicator-checked-disabled-bg !important;
        border-color: $custom-control-indicator-disabled-border-color;
      }
    }
  }
}

.custom-checkbox-circle {
  .custom-control-input:focus ~ .custom-control-label::before {
    border-radius: $custom-checkbox-circle-indicator-border-radius;
    outline-style: solid;
    outline-color: $outline-color;
    outline-width: $custom-checkbox-circle-outline-width;
    outline-offset: $custom-checkbox-circle-outline-offset;
  }

  .custom-control-label::before {
    border: $custom-checkbox-circle-indicator-border;
  }

  .custom-control-label::after {
    background-size: $custom-checkbox-circle-indicator-bg-size;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    border-radius: $custom-checkbox-circle-indicator-border-radius;
  }

  .custom-control-input {
    &:not(:disabled):not(:checked):active ~ .custom-control-label::before,
    &:not(:disabled):not(:checked):focus ~ .custom-control-label::before {
      border: $custom-checkbox-circle-indicator-border;
    }

    &:checked ~ .custom-control-label::before {
      border: $custom-checkbox-circle-indicator-checked-border;
      color: $custom-checkbox-circle-indicator-checked-color;
      border-color: $custom-checkbox-circle-indicator-checked-border-color;
      background-color: $custom-checkbox-circle-indicator-checked-bg;
    }

    &:checked ~ .custom-control-label::after {
      background-image: escape-svg($custom-checkbox-circle-indicator-icon-checked);
    }

    &:checked:disabled ~ .custom-control-label::after {
      background-image: escape-svg($custom-checkbox-circle-indicator-icon-disabled);
    }

    &:disabled {
      ~ .custom-control-label::before {
        border-color: $custom-control-indicator-disabled-border-color;
      }

      &:checked ~ .custom-control-label::before {
        background-color: $custom-checkbox-circle-indicator-checked-disabled-bg;
        border-color: $custom-control-indicator-disabled-border-color;
      }

      &:indeterminate ~ .custom-control-label::before {
        background-color: $custom-checkbox-circle-indicator-checked-disabled-bg;
        border-color: $custom-control-indicator-disabled-border-color;
      }
    }
  }
}

.custom-radio {
  margin-bottom: 1.5rem;

  .custom-control-input {
    opacity: 1;
  }

  .custom-control-label::after {
    opacity: 0;
    background-size: $custom-radio-indicator-bg-size;
    background-image: escape-svg($custom-radio-indicator-icon-checked);
    transform: scale(2);
    transition: 0.3s opacity ease-in-out, 0.3s transform ease-in-out;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    opacity: 1;
    transform: scale(1);
  }

  .custom-control-input:disabled {
    ~ .custom-control-label {
      &::before {
        border-color: $custom-control-indicator-disabled-border-color;
      }

      .custom-radio-image {
        opacity: 0.5;
      }
    }

    &:checked ~ .custom-control-label::after {
      background-image: escape-svg($custom-radio-indicator-disabled-icon-checked);
    }
  }

  .custom-radio-image {
    max-height: 1.5rem;
    width: auto;
  }

  .custom-radio-bigger-image {
    max-height: 2.25rem;
    width: auto;
  }

  &.custom-radio-center-bubble {
    .custom-control-label::after {
      top: 50%;
      transform: scale(2) translate3d(0, -50%, 0);
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      top: 50%;
      transform: scale(1) translate3d(0, -50%, 0);
    }

    .custom-control-label::before {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }
}

.custom-select {
  &:disabled,
  &[readonly] {
    &::placeholder {
      color: $input-disabled-placeholder-color;
    }

    ~ label {
      color: $input-disabled-color;
    }
  }
}
